import './AutocompleteAddress.scss';
import {
  Grid,
  TextField,
  FormControl,
} from "@mui/material";
import { getAddressFromOpenStreetMap, getRequest } from "../../Helpers/Axios/Services";
import { useState, useEffect } from "react";
import { API_ROUTES } from "../../Helpers/Axios/ApiRoutes";

const AutocompleteAddress = (props) => {

  let [addresses, setAddresses] = useState([])
  const [MapValue, setMapValue] = useState("");
  const [isDivVisibleAutoComplete, setIsDivVisibleAutoComplete] = useState(false);
  const [apiCall, setApiCall] = useState(true);
  let [isAutoDisabled, setIsAutoDisabled] = useState(false);

  const fetchData = async () => {
    if (MapValue !== ""){
      setApiCall(true);
      const response = await getRequest(API_ROUTES.GOOGLE_MAPS_API.ADDRESS_AUTOCOMPLETE(MapValue));
      addresses = [];
      if (response.ack === 1) {
        // addresses = response.data.predictions.map((address) => {
        addresses = response.data.map((address) => {
          return {
            // display_name: address.description,
            display_name: address.title,
            place_id: address.place_id
          }
        });
      }
      setAddresses(addresses);
      setApiCall(false);
    }
    // try {
    //   addresses = [];
    //   setAddresses(addresses);
    //   if (MapValue.length < 3) return;
    //   setApiCall(true);
    //   const response = await getAddressFromOpenStreetMap(MapValue)
    //   addresses = response.data
    //   setAddresses(addresses);
    //   setApiCall(false);
    // } catch (error) {
    //   setApiCall(false);
    // }
  };

  const getAddressFronPlaceId = async (address) => {
    setIsDivVisibleAutoComplete(false);
    setMapValue(address.display_name);
    const response = await getRequest(API_ROUTES.GOOGLE_MAPS_API.ADDRESS_FROM_PALCE_ID(address.place_id));
    if (response.ack === 1) {
      // props.setAddress(response.data.result, address.place_id);
      props.setAddress(response.data, address.place_id);
    }          
  };

  useEffect(() => {
    const getData = setTimeout(() => {
      if (!isAutoDisabled) {
        fetchData();
      }
    }, 500);
    return () => clearTimeout(getData);
  }, [MapValue])


  return (
    <Grid>
      <FormControl fullWidth>
        <TextField
          label="Search Address" id="storeSearch"
          placeholder="Search Address"
          onClick={() => setIsDivVisibleAutoComplete(!isDivVisibleAutoComplete)}
          value={MapValue}
          type='search'
          autoComplete='off'
          onChange={(e) => {
            isAutoDisabled = false;
            setIsAutoDisabled(isAutoDisabled);
            setIsDivVisibleAutoComplete(true);
            setMapValue(e.target.value);
          }}
        />
        {isDivVisibleAutoComplete && (
          <ul className="show-hide-div">
            {!apiCall && addresses.length > 0 ? addresses.map((address, index) =>
              <li
                key={index}
                onClick={() => {
                  isAutoDisabled = true;
                  setIsAutoDisabled(isAutoDisabled);
                  getAddressFronPlaceId(address);
                }}
                className={""}>{address.display_name}</li>
            ) : <li className="text-center">Loading...</li>}
          </ul>)}
      </FormControl>
    </Grid>
  );

};

export default AutocompleteAddress;