import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate, useSearchParams } from "react-router-dom";
import { getSessionStorage, SESSION_STORAGE_KEYS } from "../Helpers/crypto/SessionStorage";
import { setLocalStorage, LOCAL_STORAGE_KEYS } from "../Helpers/crypto/LocalStorage";
import { postRequest, getRequest } from "../Helpers/Axios/Services";
import { API_ROUTES } from "../Helpers/Axios/ApiRoutes";


const PaymentRedirect = () => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const handleCheckPayment = async () => {
    const response = await getRequest(API_ROUTES.AUTHENTICATION.REFRESH_TOKEN)
    if (response.ack === 1) {
      setLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN, response.data);
    }
    //  searchParams get all the query params from the url
    let data = {};
    const queryParams = Object.fromEntries(searchParams.entries());
    for (const [key, value] of Object.entries(queryParams)) {
      data[key] = value;
    }
    const { ack, msg, order_id } = await postRequest(API_ROUTES.CART.CONFIRM_PAYMENT, data)
    let orderId = order_id ? order_id : searchParams.get('trctn');
    if (ack === 1) {
      navigate(`/thankyou/${orderId}`);
    } else {
      navigate(`/failed/${orderId}`);
    }
  };

  useEffect(() => {
    handleCheckPayment();
  }, []);

  return (
    <div className="thankyou-content">
      <CircularProgress size={100} />
      <h3 className="h3">{t('cart_checkPaymentConnection')}</h3>
    </div>
  );
};

export default PaymentRedirect;
