import "./Wallet.scss";
import { useState, useEffect, useRef } from 'react';
import { Grid, Divider, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { getRequest } from '../../Helpers/Axios/Services';
import { API_ROUTES } from '../../Helpers/Axios/ApiRoutes';
import {
  getTransactionsGroupedByDate, displayPrice, getTimeFromDate, getLastFourDigitsOfCardNumber
} from '../../Helpers/HelperFunctions';
import { useTranslation } from "react-i18next";
import { getLocalStorage, LOCAL_STORAGE_KEYS } from "../../Helpers/crypto/LocalStorage";
import useIntersection from '../../Helpers/useIntersection'
import Sidebar from '../layout/sidebar/Sidebar';
import walletIcon from "../../assets/images/structure/wallet-icon.svg";
import moneyAdded from "../../assets/images/structure/money-added.svg";
import moneyPaid from "../../assets/images/structure/money-paid.svg";
import NoDataFound from '../../global-modules/EmptyPages/NoDataFound';
import ClipLoader from "react-spinners/ClipLoader";
import jwt_decode from "jwt-decode";
import WalletStatus from './WalletStatus';

const Wallet = () => {

  const ref = useRef(null);
  const { t } = useTranslation();
  const inViewport = useIntersection(ref, '0px');
  const limit = 12;
  let [wallet, setWallet] = useState(null);
  let [transactions, setTransactions] = useState([]);
  let [page, setPage] = useState(1);
  let [storeCanLoad, setStoreCanLoad] = useState(false);
  let [loadmore, setLoadmore] = useState(false);
  let [maxWalletAmount, setMaxWalletAmount] = useState(0);

  const getTransactions = async () => {
    const url = API_ROUTES.WALLET.GET_WALLET_DATA + `?page=${page}&limit=${limit}`;
    const response = await getRequest(url);
    transactions = response.transactions;
    setTransactions(transactions);
    setTimeout(() => { setStoreCanLoad(true) }, 3000);
  };

  const getWalletAmount = async () => {
    if (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) {
      const token = getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
      const decoded = jwt_decode(token);
      const url = API_ROUTES.WALLET.GET_WALLET_AMOUNT(decoded.id);
      const { ack, transactions, max_wallet_amount } = await getRequest(url);
      if (ack === 1) {
        setMaxWalletAmount(max_wallet_amount)
        wallet = transactions;
        setWallet(wallet);
      }
    }
  }

  const loadMoreData = async () => {
    setStoreCanLoad(false)
    setLoadmore(true)
    page++;
    setPage(page);
    const url = API_ROUTES.WALLET.GET_WALLET_DATA + `?page=${page}&limit=${limit}`;
    const response = await getRequest(url);
    if (response.transactions.length > 0) {
      transactions.push(...response.transactions);
      setTransactions(transactions);
      setLoadmore(false)
      setTimeout(() => {
        setStoreCanLoad(true)
      }, 1500);
    } else {
      setLoadmore(false)
      setStoreCanLoad(false)
    }
  }

  if (!loadmore && storeCanLoad && inViewport) {
    loadMoreData();
  }

  useEffect(() => {
    getTransactions();
    getWalletAmount();
  }, []);

  return (
    <div>
      <div className="main-wrapper full-bg">
        <Sidebar />
        <div className="main-mid-wrapper">
          <div className="store-detail-wrapper wallet-content">
            <div className="store-detail-head"><h3 className="h3">{t("wallet")}</h3></div>
            <Grid container columnSpacing={"30px"}>
              <Grid item xs={12} sm={12} md={10}>
                <div className="card-white">
                  <div className="wallet-box-new">
                    <div className="d-flex">
                      <img src={walletIcon} className="wallet-icon" alt="" />
                      <div className="relative">
                        <h4 className="h4">
                          {t("wallet_QAR")} {wallet ? displayPrice(wallet?.amount) : "0.00"}
                        </h4>
                        <p className="p2">{t("WalletBalance")}</p>
                      </div>
                    </div>
                    <Link to={"/add-money"} className="no-decoration" style={{ float: "right" }}>
                      <Button className="MuiButton-outlinedPrimary" size="large">
                        {t("wallet_AddMoney")}
                      </Button>
                    </Link>
                  </div>
                </div>
                {transactions?.length > 0 ?
                <div className="card-white">
                  <div className="business-detail-wrap">
                    {getTransactionsGroupedByDate(transactions).map((transaction, index) => (
                    <span key={`transaction-date-${index}`}>
                      <span className="date">{transaction[0]}</span>
                      {transaction[1].map((detail, i) => detail.type === "top_up" || detail.type === "refund" ?
                      <div className="wallet-row" key={`transaction-detail-${index}-${i}`}>
                        <div className="wallet-row-left">
                          <img src={detail?.amount > 0 ? moneyAdded : moneyPaid} className="money-icon" alt="" />
                          {detail.method === "wallet" ?
                          <div className="relative">
                            {detail.status !== "pending" && detail.status !== "failed"?
                              <h5 className="h6">
                                {t("wallet_MoneyaddedtowalletForRefund")}{" "}
                                {detail?.order_id}
                              </h5> : 
                              detail.status !== "failed"?
                                <h5 className="h6">{t("wallet_pending_transaction")}</h5>:
                                <h5 className="h6">{t("wallet_failed_transaction")}</h5>
                              }
                            <p className="p3">{getTimeFromDate(detail?.createdAt)}</p>
                          </div>:
                          <div className="relative">
                            {detail?.send_by === 'discounts_offers' ? <h5 className="h6">
                              {t("wallet_MoneyaddedtowalletFromDiscountsOffers")}
                            </h5> : 
                            detail.status !== "failed"?<h5 className="h6">
                              {detail?.method === 'cash' ? detail?.amount > 0 ?
                                `${t("wallet_MoneyaddedtowalletByAdmin")}` : `${t("wallet_MoneyDebitefFromwalletByAdmin")}` :
                                `${t("wallet_Moneyaddedtowalletfrom")} xxxx ${getLastFourDigitsOfCardNumber(detail?.card_number)}`}
                            </h5>:<h5 className="h6">{t("wallet_failed_transaction")}</h5>}
                            <p className="p3">{getTimeFromDate(detail?.createdAt)}</p>
                          </div>}
                        </div>
                        <div className="wallet-row-right">
                          <div className="relative">
                            <h5 className="h6 green">
                              {t("wallet_QAR")} {displayPrice(detail?.amount)}
                            </h5>
                            {detail.status !== "pending" && detail.status !== "failed"?
                            <p className="p3">
                              {t("wallet_ClosingBalance")} {t("wallet_QAR")}{" "}
                              {displayPrice(detail?.wallet_closing_balance)}
                            </p>: <WalletStatus status={detail.status} />}
                          </div>
                        </div>
                      </div>:
                      <div className="wallet-row" key={`transaction-detail-${index}-${i}`}>
                        <div className="wallet-row-left">
                          <img src={moneyPaid} className="money-icon" alt="" />
                          <div className="relative">
                            {detail.status !== "pending" ?
                              <h5 className="h6">
                                {t("wallet_PaidonpurchaseoforderID_")} {detail?.order_id}
                              </h5>: detail.status !== "failed"?
                                <h5 className="h6">{t("wallet_pending_transaction")}</h5>:
                                <h5 className="h6">{t("wallet_failed_transaction")}</h5>}
                            <p className="p3">{getTimeFromDate(detail.createdAt)}</p>
                          </div>
                        </div>
                        <div className="wallet-row-right">
                          <div className="relative">
                            <h5 className="h6">
                              - {t("wallet_QAR")} {displayPrice(detail.amount)}
                            </h5>
                            {detail.status !== "pending" &&  detail.status !== "failed"?
                              <p className="p3">
                              {t("wallet_ClosingBalance")} {t("wallet_QAR")}{" "}
                              {displayPrice(detail?.wallet_closing_balance)}
                            </p>:
                            <WalletStatus status={detail.status} /> }
                          </div>
                        </div>
                      </div>)}
                      <Divider />
                    </span>))}
                  </div>
                </div>: <NoDataFound text={t("wallet_noTransactionAvailable")} />}
                <div className="loader-wrap" ref={ref}>
                  <ClipLoader color={"#EF4930"} loading={loadmore} speedMultiplier={0.5} />
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div >
  );
}

export default Wallet;
