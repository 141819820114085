import "./TermsConditions.scss"
import { useTranslation } from "react-i18next";
import { useState, useEffect } from 'react';
import { getRequest } from '../../Helpers/Axios/Services'
import { API_ROUTES } from '../../Helpers/Axios/ApiRoutes'
import { changeSnackbar, currentSnackbar } from "../../redux/reducers/snackbar";
import { changeLoader } from "../../redux/reducers/loader";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

const TermsComponent = () => {

  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  let [terms, setTerms] = useState(null);
  const dispatch = useDispatch();
  const snackbar = useSelector(currentSnackbar);

  const getTerms = async () => {
    changeLoader(true);
    if (searchParams?.get('lang')) {
      localStorage.setItem('i18nextLng', searchParams?.get('lang'))
    }
    const response = await getRequest(API_ROUTES.STATIC_PAGES.GET_STATIC_PAGES('termsofservice'));
    if (response?.ack) {
      terms = response?.cms;
      setTerms(terms);
      changeLoader(false);
    } else {
      dispatch(changeSnackbar({
        ...snackbar,
        isOpen: true,
        message: response.msg,
        state: "error",
      }));
      changeLoader(false);
    }
  }

  useEffect(() => {
    getTerms();
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="" dangerouslySetInnerHTML={{__html: terms?.cms_locales[0].description,}}></div>
    </div>
  );
}

export default TermsComponent;
