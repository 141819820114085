/**
 * @file NeedHelp.js
 * @description Need Help Dialog Box
 * @exports NeedHelp - Dialog Box
 * @exports closeDialog - Function to close the dialog box
 * @exports cancelOrder - Function to cancel the order
 * @imports status - Status of the order to check if the order can be cancelled or not
 */
import { Grid, Dialog, DialogContent, Button } from '@mui/material';
import modalClose from "../assets/images/structure/close-black.svg";
import { useTranslation } from "react-i18next";

const NeedHelp = ({ closeDialog, cancelOrder, status }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={true} onClose={closeDialog} className="main-modal theme-dialog">
      <img src={modalClose} alt="close" onClick={closeDialog} className="modal-close" />
      <DialogContent>
        <h2 className="h3" style={{ marginBottom: "8px" }}>
          {t("order_need_help_dialog")}
        </h2>
        <p className="p3" style={{ marginBottom: "12px", fontSize: "14px" }}>
          {t("order_need_help_dialog_text")}
        </p>
        <Grid item xs={12} textAlign="center" display={"flex"} justifyContent={"flex-end"}>
          {status === "placed" ? (
            <Button
              type="button"
              size="medium"
              variant="text"
              color="primary"
              onClick={cancelOrder}
            >
              {t("cart_cancelOrder")}
            </Button>
          ) : null}
          <a
            href="tel:8006000"
            target={"_blank"}
            size="medium"
            variant="text"
            color="error"
            onClick={closeDialog}
          >
            <Button type="button" size="medium" variant="text" color="error">
              {t("cart_callNow")}
            </Button>
          </a>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default NeedHelp;