import "./ContactUs.scss";
import { useState, useEffect } from 'react'
import Breadcrumb from "../../shared/Breadcrumb";
import { useTranslation } from 'react-i18next';
import { Divider, TextField, InputAdornment, Button } from "@mui/material";
import { postRequest, getRequest } from '../../Helpers/Axios/Services'
import { API_ROUTES } from '../../Helpers/Axios/ApiRoutes'
import Footer from '../../pages/layout/footer/Footer';
import facebookWhite from "../../assets/images/structure/facebook-white.svg";
import twitterWhite from "../../assets/images/structure/twitter-white.svg";
import youtubeWhite from "../../assets/images/structure/youtube-white.svg";
import instagramWhite from "../../assets/images/structure/instagram-white.svg";
import callIcon from "../../assets/images/structure/call.svg";
import mailIcon from "../../assets/images/structure/mail.svg";
import {PhoneMask} from "../../global-modules/MaskInputs/MaskedInputs";
import {axiosConstant} from "../../Helpers/Axios/Constants";
import {validateEmail } from '../../Helpers/HelperFunctions';
import { useDispatch, useSelector } from "react-redux";
import { changeSnackbar, currentSnackbar } from "../../redux/reducers/snackbar";
import { changeLoader } from "../../redux/reducers/loader";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const ContactUs = () => {
  
  const dispatch = useDispatch();
	const snackbar = useSelector(currentSnackbar);
  const { t } = useTranslation();
  let [fullName, setFullName] = useState("");
  let [email, setEmail] = useState("");
  let [mobileNumber, setMobileNumber] = useState("");
  let [subject, setSubject] = useState("");
  let [message, setMessage] = useState("");
  let [countryCode, setCountryCode] = useState(axiosConstant.COUNTRY_CODE);
  let [fullNameErr, setFullNameErr] = useState("");
  let [emailErr, setEmailErr] = useState("");
  let [mobileNumberErr, setMobileNumberErr] = useState("");
  let [subjectErr, setSubjectErr] = useState("");
  let [messageErr, setMessageErr] = useState("");
  let [contactData, setContactData] = useState(null);

  const resetForm = () => {
    setFullName("");
    setEmail("");
    setMobileNumber("");
    setSubject("");
    setMessage("");
    setFullNameErr("");
    setEmailErr("");
    setMobileNumberErr("");
    setSubjectErr("");
    setMessageErr("");
  }

  const handleSubmit = async (e) => {
    dispatch(changeLoader(true));
    e.preventDefault();
    let isValid = true;
    if (fullName === "") {
      setFullNameErr(t("static_pages_fullName_Required"));
      isValid = false;
    }
    if (email === "") {
      setEmailErr(t("static_pages_emailAddress_Required"));
      isValid = false;
    } else if (!validateEmail(email)) {
      setEmailErr(t("static_pages_emailAddress_Invalid"));
      isValid = false;
    }
    if (mobileNumber === "") {
      setMobileNumberErr(t("static_pages_mobileNumber_Required"));
      isValid = false;
    }
    // else if (mobileNumber.length < 8) {
    //   setMobileNumberErr(t("static_pages_mobileNumber_Invalid"));
    //   isValid = false;
    // }
    if (subject === "") {
      setSubjectErr(t("static_pages_subject_Required"));
      isValid = false;
    }
    if (message === "") {
      setMessageErr(t("static_pages_message_Required"));
      isValid = false;
    }
    if (isValid) {
      const data = {
        full_name: fullName,
        email: email,
        phone: mobileNumber.slice(countryCode.length),
        country_code: countryCode,
        subject: subject,
        message: message
      };
      const response = await postRequest(API_ROUTES.STATIC_PAGES.SUBMIT_CONTACT_US, data);
      if (response.ack === 1) {
        resetForm();
        dispatch(changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: response.msg,
          state: "success",
        }));
        dispatch(changeLoader(false));  
      } else {
        dispatch(changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: response.msg,
          state: "error",
        }));
        dispatch(changeLoader(false));
      }
    } else {
      dispatch(changeLoader(false));
    } 
  };

  const getContactData = async () => {
    const response = await postRequest(API_ROUTES.STATIC_PAGES.GET_CONTACT_US, {})
    contactData = response?.data
    setContactData(contactData)
  }

  useEffect(() => {
    getContactData()
  }, []);

  return (
    <>
      <div className="contact-condition-wrapper">
        <div className="container-body">
          <Breadcrumb
            breadcrums={[
              {
                title: t("storefront_homepage"),
                haveLink: true,
                link: "/",
              },
              {
                title: t("static_pages_contact_us"),
                haveLink: false,
              },
            ]}
          />
          <div className="contact-condition-head">
            <h3 className="h3">{t("static_pages_contact_us")}</h3>
          </div>
          <div className="contact-condition-body">
            <div className="contact-condition-body-left">
              <h3 className="h3">{t("static_pages_SupportAndHelp")}</h3>
              <p className="p2 mb-24">{t("static_pages_IfYouHaveAnyQuestions_contact")}</p>
              <h6 className="h6">{t("static_pages_ContactInformation")}</h6>
              <p className="p2 mb-10">{contactData?.addressLine1}</p>
              <p className="p2">{contactData?.addressLine2}</p>
              <div className="email-contact">
                <img src={callIcon} className="icon24" alt="call" />
                <span>{contactData?.phone}</span>
              </div>
                <div className="email-contact">
                    <img src={mailIcon} className="icon24" alt="mail" />
                    <span>{contactData?.email}</span>
                </div>
              <Divider />
              <h6 className="h6">{t("static_pages_FollowUsOn")}</h6>
              <div className="social">
                <a href="https://www.facebook.com/baladiexpress" target="_blank">
                  <img src={facebookWhite} className="social-icons" alt="facebook" />
                </a>
                <a href="https://twitter.com/Baladiexpress" target="_blank">
                  <img src={twitterWhite} className="social-icons" alt="twitter" />
                </a>
                <a href="https://www.youtube.com/@baladiexpress" target="_blank">
                  <img src={youtubeWhite} className="social-icons" alt="youtube" />
                </a>
                <a href="https://www.instagram.com/baladiexpress/" target="_blank">
                  <img src={instagramWhite} className="social-icons" alt="instagram" />
                </a>
              </div>
            </div>
            <div className="contact-condition-body-right">
              <h3 className="h3 text-center">{t("static_pages_GetInTouch")}</h3>
              <form className="contact-form" onSubmit={handleSubmit} action="#" method="post">
                <div className="form-group">
                  <TextField
                    label={t("static_pages_FullName") + "*"}
                    value={fullName}
                    onChange={(e) => {
                      setFullName(e.target.value);
                      setFullNameErr("");
                    }}
                  />
                  <span className='errorspan'>{fullNameErr}</span>
                </div>
                <div className="form-group">
                  <TextField
                    label={t("static_pages_emailAddress") + "*"}
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value)
                      setEmailErr("")
                    }}
                  />
                  <span className='errorspan'>{emailErr}</span>
                </div>
                <div className="form-group">
                  {/* <TextField
                    label={t("static_pages_MobileNumber") + "*"}
                    id="outlined-start-adornment"
                    value={mobileNumber}
                    onChange={(e) => {
                      setMobileNumber(e.target.value)
                      setMobileNumberErr("")
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">+{countryCode}</InputAdornment>
                      ),
                      inputComponent: PhoneMask,
                    }}
                    mask="00000000"
                  /> */}
                  <PhoneInput
                  className="phone-input"
                    country={'qa'}
                    value={mobileNumber}
                    enableLongNumbers={true}
                    onKeyDown={(e) => {
                      if ((mobileNumber.length - countryCode.length) >= 16 && e.keyCode !== 8) {
                        e.preventDefault();
                      }
                    }}
                    onChange={(value, data) => {
                      setMobileNumber(value);
                      setMobileNumberErr('');
                      setCountryCode(data?.dialCode);
                    }}
                  />
                  <span className='errorspan'>{mobileNumberErr}</span>
                </div>
                <div className="form-group">
                  <TextField
                    label={t("static_pages_Subject") + "*"}
                    value={subject}
                    onChange={(e) => {
                      setSubject(e.target.value)
                      setSubjectErr("")
                    }}
                  />
                  <span className='errorspan'>{subjectErr}</span>
                </div>
                <div className="form-group">
                  <TextField
                    label={t("static_pages_WriteYourMessageHere") + "*"}
                    value={message}
                    onChange={(e) => {
                      setMessage(e.target.value)
                      setMessageErr("")
                    }}
                    multiline rows={3} />
                  <span className='errorspan'>{messageErr}</span>
                </div>
                <Button type="submit" variant="contained" size="large" sx={{ width: 1 }}>
                  {t("static_pages_SendMessage")}
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ContactUs;
