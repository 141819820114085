import { useEffect, useState } from "react";
import Slider from "react-slick";
import { Rating } from "@mui/material";
import { Link } from "react-router-dom";
import { Boxed } from "../../../Styles-Elements/Box";
import distanceIcon from "../../../assets/images/structure/distance.svg";
import Ratings from "../../../global-modules/Rating/Ratings";
import {
  getRating,
  getDistanceFromLatLonInKm,
} from "../../../Helpers/HelperFunctions";
import Image from "../../../global-modules/Image/Image";
import reviewImg1 from "../../../assets/images/products/review-1.jpg";
import reviewImg2 from "../../../assets/images/products/review-2.png";
import reviewImg3 from "../../../assets/images/products/review-3.jpg";
import reviewImg4 from "../../../assets/images/products/review-4.jpg";
import reviewImg5 from "../../../assets/images/products/review-5.jpg";
import { useTranslation } from "react-i18next";
import { Flip } from "react-reveal";

import { getRequest } from "../../../Helpers/Axios/Services";
import { API_ROUTES } from "../../../Helpers/Axios/ApiRoutes";
import { currentAddress } from "../../../redux/reducers/address";
import { useSelector } from "react-redux";
import { axiosConstant } from "../../../Helpers/Axios/Constants";
import { useNavigate } from "react-router-dom";
import { currentBaladiData } from "../../../redux/reducers/baladiData";

const Review = () => {
  const { t } = useTranslation();
  const userAddress = useSelector(currentAddress);
  const navigate = useNavigate();
  const [recommendedStores, setRecommendedStores] = useState([]);

  const getRecommendedStores = async () => {
    const response = await getRequest(
      API_ROUTES.NEW_HOME.GET_RECOMMENDED_STORES(
        userAddress?.latitude,
        userAddress?.longitude
      )
    );
    let stores = [];
    response?.result.forEach((element) => {
      const categoryStores = element?.stores;
      categoryStores.forEach((store) => {
        stores.push(store);
      });
    });
    console.log(stores);
    setRecommendedStores(stores);
  };

  useEffect(() => {
    getRecommendedStores();
  }, []);

  return (
    <>
      <section className="home-section7">
      <div className="container-1110">
        <div className="relative text-center">
          <Flip bottom>
            {/* <Rating
              color="#1EA896"
              fontSize="40px"
              name="simple-controlled"
              value={5}
              readOnly
            /> */}
            <h2 className="h2">Top Recommendation Store</h2>
            <p className="p2">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.{" "}
            </p>
          </Flip>
        </div>
        <Slider
          centerMode={false}
          dots={false}
          infinite={true}
          arrows={true}
          speed={500}
          autoplay={true}
          slidesToShow={4}
          slidesToScroll={1}
          responsive={[
            {
              breakpoint: 1025,
              settings: { slidesToShow: 3, slidesToScroll: 1 },
            },
            {
              breakpoint: 992,
              settings: { slidesToShow: 2, slidesToScroll: 1 },
            },
            {
              breakpoint: 576,
              settings: { slidesToShow: 1, slidesToScroll: 1 },
            },
          ]}
        >
          {recommendedStores.map((store, index) => (
            <div>
              <div className="reviews-slider">
                <Link
                  key={`recomended-${index}`}
                  to={`/store/${
                    store?.slug && store?.slug !== "" ? store?.slug : store?.id
                  }`}
                  className={`recommended-card-box no-decoration ${
                    store.online_status === 1 ? "" : "disable"
                  }`}
                >
                  <Image
                    src={axiosConstant.IMAGE_BASE_URL(store.business_logo)}
                    className="recommended-image"
                    alt="store-logo"
                  />
                  {store?.online_status === 1 ? null : (
                    <span className="closed-chips">
                      {t("storefront_closed")}
                    </span>
                  )}
                  <div className="chips-row">
                    {store?.offers
                      ?.filter((offerF, indexF) => indexF < 3)
                      .map((offer, index) => (
                        <span key={`offeroffers-${index}`} className="chips">
                          {offer?.name}
                        </span>
                      ))}
                    {store?.offers?.length > 3 ? (
                      <span className="chips">
                        {" "}
                        +{store?.offers?.length - 3} {t("More")}
                      </span>
                    ) : null}
                  </div>
                  <Boxed
                    padding={"0 12px 0 12px"}
                    className="recommended-content-box"
                  >
                    <div className="recommended-content">
                      <h6 className="h6">
                        {store?.stores_locales[0]?.business_name}
                      </h6>
                      <p className="p2">
                        {store?.business_address &&
                        store?.business_address !== ""
                          ? store?.business_address
                          : `${store?.zone_number}, ${store?.street_number}, ${store?.building_number}, ${store?.country}`}
                      </p>
                      <Boxed
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        mb={"4px"}
                        className="always-ltr-center"
                      >
                        <img src={distanceIcon} alt="distanceIcon" />
                        {/* <span className="p2 p2-mins">10 {t("storefront_mins")}</span> */}
                        <span className="p2 p2-mins">
                          {getDistanceFromLatLonInKm(
                            userAddress?.latitude,
                            userAddress?.longitude,
                            store?.latitude,
                            store?.longitude
                          )}{" "}
                          {t("kmAway")} |
                          <span className="p2 p2-mins">
                            30 ~ 40 {t("storefront_mins")}
                          </span>
                        </span>
                      </Boxed>
                      {store?.avgRating ? (
                        <Boxed
                          display={"flex"}
                          alignItems={"center"}
                          className="rating-show-block"
                        >
                          <Ratings
                            withDiv={false}
                            rating={getRating(store?.avgRating)}
                          />
                        </Boxed>
                      ) : null}
                    </div>
                  </Boxed>
                </Link>
                {/* <img className="top-img" src={reviewImg3} alt="..." />
              <div className="text-center ">
                <Rating name="simple-controlled" value={5} readOnly />
                <h4 className="h4">
                  “These pretzels and Belgian waffles are fantabulous!!!”
                </h4>
                <p className="p4">- David William</p>
              </div> */}
              </div>
            </div>
          ))}
        </Slider>
        </div>
      </section>
    </>
  );
};

export default Review;
