import "./top-toolbar.scss";
import { Button, Menu, MenuItem } from '@mui/material';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Boxed } from '../../../../Styles-Elements/Box';
import { useTranslation } from "react-i18next";
import { currentAddress, changeAddress } from '../../../../redux/reducers/address';
import { postRequest, getRequest } from '../../../../Helpers/Axios/Services';
import { API_ROUTES } from '../../../../Helpers/Axios/ApiRoutes';
import { getLocalStorage, setLocalStorage, LOCAL_STORAGE_KEYS } from "../../../../Helpers/crypto/LocalStorage";
import locationBlack from "../../../../assets/images/structure/location-black.svg";
import qatarLangIcon from "../../../../assets/images/language/qatar-flag-icon.svg";
import englishLangIcon from "../../../../assets/images/language/english.svg";
import dawnArrowBlack from "../../../../assets/images/structure/dawn-arrow-black.svg";
import AddressList from '../../../../DialogBox/Address/AddressList';
import AddAddress from '../../../../DialogBox/Address/AddAddress';

const TopToolbar = ({ userToken }) => {
  const { t, i18n } = useTranslation();
  const languages = [{
    name: t("headerEnglish"),
    code: 'en',
    icon: englishLangIcon
  }, {
    name: t("headerQatar"),
    code: 'ar',
    icon: qatarLangIcon
  }];
  const dispatch = useDispatch();
  const userAddress = useSelector(currentAddress)
  const [lang, setLang] = useState(false);
  const [selectedLang, setSelectedLang] = useState('ar');
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openAddressList, setOpenAddressList] = useState(false);
  const [openAddressAdd, setOpenAddressAdd] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getAddresses = async () => {
    const response = await getRequest(API_ROUTES.ADDRESS.GET_ADDRESS);
    if (response.ack === 1) {
      const defaultAddress = response.addresses.find((address) => address.default_address === true);
      setLocalStorage(LOCAL_STORAGE_KEYS.TOTAL_ADDRESS, response.addresses.length);
      setLocalStorage(LOCAL_STORAGE_KEYS.DEFAULT_ADDRESS, JSON.stringify(defaultAddress));
      dispatch(changeAddress());
    }
  }

  const changeLanguage = (lang) => {
    changeLanguageF(lang);
    window.location.reload();
  }

  const changeLanguageF = async (lang) => {
    setSelectedLang(lang);
    i18n.changeLanguage(lang);
    localStorage.setItem('i18nextLng', lang)
    setLang(!lang)
    if (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) {
      await postRequest(API_ROUTES.AUTHENTICATION.CHANGE_LANGUAGE, { language: lang })
    }
  }

  useEffect(() => {
    const token = getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
    if (userAddress?.id === 0 && token) {
      getAddresses();
    }
    if (localStorage.getItem('i18nextLng')) {
      changeLanguageF(localStorage.getItem('i18nextLng'))
    } else {
      changeLanguageF('en')
    }    
  }, [])

  return (
    <>
      <div className="top-toolbar-wrapper">
        <div className="container-body">
          <Boxed display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
            {userAddress ? (
              <div className="left-wrap">
                <Button
                  onClick={() => userToken ? setOpenAddressList(true) : setOpenAddressAdd(true)}
                >
                  <img src={locationBlack} alt="location" />
                  <span className="p2">
                    {userAddress ?
                      userAddress.business_address && userAddress.business_address !== ""
                        ? ` ${userAddress?.apartment_number}  ${userAddress?.building_number} ${userAddress?.street_number} ${userAddress.zone_number} ${userAddress?.business_address}` :
                        `${userAddress.zone_number}, ${userAddress.building_number}, ${userAddress.street_number}, ${userAddress.country}`
                      : null}
                  </span>
                </Button>
              </div>
            ) : null}
            <div className="right-wrap">
              <span>
              <span className="p2">{t("topHeaderCallUsForAnyQuery")}</span>
              <span className="p2-bold" dir="ltr">{t("topHeaderPhoneNumber")}</span>
              </span>
              <div className="language-block">
                <Button
                  id="basic-button-language"
                  aria-controls={open ? "basic-menu-language" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  <img src={
                    languages.find(lang => lang.code === selectedLang)?.icon
                  } className="language-img" alt="language-icon" />
                  <span className="language-text">{languages.find(lang => lang.code === selectedLang)?.name}</span>
                  <img src={dawnArrowBlack} alt="dawnArrowBlack" onClick={() => setLang(!lang)} />
                </Button>
                <Menu
                  id="basic-menu-language"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button-language",
                  }}
                  disableScrollLock={false}
                  PaperProps={{ style: { marginTop: '12px' } }}
                >
                  <div className={lang ? "nav-drop-down" : "hidden"} onClick={handleClose}>
                    {languages.map((language, index) =>
                      <MenuItem
                        onClick={() => {
                          changeLanguage(language?.code);
                        }}
                        className={
                          selectedLang === language?.code
                            ? "nav-dropdown-content active"
                            : "nav-dropdown-content"
                        }
                        key={`language-${index}`}
                      >
                        <img src={language?.icon} className="language-img" alt={language?.name} />
                        {language?.name}
                      </MenuItem>)}
                  </div>
                </Menu>
              </div>
            </div>
          </Boxed>
        </div>
        {openAddressList ? <AddressList
          handleClose={() => setOpenAddressList(false)}
          openAddAddress={() => {
            setOpenAddressList(false)
            setOpenAddressAdd(true)
          }}
        /> : null}
        {openAddressAdd ? <AddAddress
          handleClose={() => setOpenAddressAdd(false)}
          addressSaved={() => {
            setOpenAddressAdd(false)
          }}
        /> : null}
      </div>
    </>
  );
};

export default TopToolbar;