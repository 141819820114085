import "./Products.scss"
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Grid, Dialog, DialogContent, MenuItem, FormControl, Select } from "@mui/material";
import { Boxed } from "../../Styles-Elements/Box";
import { useTranslation } from "react-i18next";
import { changeCart, currentCartProductIds, currentCartUomIds, currentCart } from '../../redux/reducers/cart';
import { changeWishlist, currentWishlist } from '../../redux/reducers/wishlist';
import { changeSnackbar, currentSnackbar } from "../../redux/reducers/snackbar";
import { changeLoader } from "../../redux/reducers/loader";
import { getRequest, postRequest, putRequest, deleteRequest } from '../../Helpers/Axios/Services';
import { axiosConstant } from '../../Helpers/Axios/Constants';
import { API_ROUTES } from '../../Helpers/Axios/ApiRoutes';
import { getLocalStorage, setLocalStorage, LOCAL_STORAGE_KEYS } from '../../Helpers/crypto/LocalStorage';
import { displayPrice, getFrontTypeImageUrl } from '../../Helpers/HelperFunctions';
import jwt_decode from "jwt-decode";
import heart from "../../assets/images/structure/wishlist-red.svg";
import minusIcon from "../../assets/images/structure/minus-icon.svg"
import plusIcon from "../../assets/images/structure/plus-icon.svg"
import VendorMisMatch from '../../DialogBox/VendorMisMatch';
import AddToCardModifier from '../../DialogBox/AddToCardModifier';
import ReapetLastUseCostumise from '../../DialogBox/ReapetLastUseCostumise';
import Image from "../Image/Image";
import { currentAddress } from '../../redux/reducers/address';
import {currentBaladiData} from '../../redux/reducers/baladiData';

const WishlistProductBox = ({ storesId, online, product, index2, itemId, offers = [] }) => {

  const { t } = useTranslation();
  const userAddress = useSelector(currentAddress)
  let cartProductIds = useSelector(currentCartProductIds);
  let cartUomIds = useSelector(currentCartUomIds);
  let cartData = useSelector(currentCart);
  const baladiData = useSelector(currentBaladiData);
  const dispatch = useDispatch();
  const snackbar = useSelector(currentSnackbar);
  let wishlist = useSelector(currentWishlist);
  let [cartId, setCartId] = useState(0);
  const [openMoveToCard, setOpenMoveToCard] = useState(false);
  let [storeId, setStoreId] = useState(storesId || 0);
  let [uoms, setUoms] = useState(product?.uoms || []);
  let [selectedUom, setSelectedUom] = useState(product?.uoms?.find(uom => uom.isDefault == 1) || {});
  let [isVendor, setIsVendor] = useState(false);
  let [frontImage, setFrontImage] = useState('');
  let [currentUomId, setCurrentUomId] = useState(null);
  const [isAddToCardModifier, setAddToCardModifier] = useState(false);
  const [isReapetLastUseCostumise, setReapetLastUseCostumise] = useState(false);
  let [selectCartItem, setSelectCartItem] = useState(null);
  let [addonsNotes, setAddonsNotes] = useState('');
  let [disableAddToCart, setDisableAddToCart] = useState(false);
  let [productTimeAvailability, setProductTimeAvailability] = useState(true);
  let [storeSlug, setStoreSlug] = useState('');

  const OpenMoveToCard = () => {
    setOpenMoveToCard(true);
  };

  const handleClose = () => {
    setOpenMoveToCard(false);
  };

  const getCartData = async () => {
    let userID = '';
    if (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) {
      const token = getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
      let decoded = jwt_decode(token);
      userID = decoded?.id;
    }
    const deviceId = getLocalStorage(LOCAL_STORAGE_KEYS.MACHINEID);
    let url = API_ROUTES.CART.GET_CART_DATA;
    url += `?latitude=${userAddress?.latitude}&longitude=${userAddress?.longitude}`
    const cartResponse = await postRequest(url, {
      userId: userID,
      deviceId: deviceId
    });
    setLocalStorage(LOCAL_STORAGE_KEYS.CART, JSON.stringify(cartResponse))
    dispatch(changeCart());
    setDisableAddToCart(false)
  }

  const checkAndAddtocart = async (product_id, store_id, uomId) => {
    setDisableAddToCart(true)
    if (getLocalStorage(LOCAL_STORAGE_KEYS.CART) && cartData?.cart_items?.length > 0 && cartData.storeId !== store_id) {
      setIsVendor(true)
      cartId = product_id;
      setCartId(cartId)
      setDisableAddToCart(false)
    } else {
      setAddToCardModifier(true)
      setDisableAddToCart(false)
    }
  }

  const addtocart = async (product_id, store_id, uomId, addons = '') => {

    dispatch(changeLoader(true));
    let userID = '';
    if (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) {
      const token = getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
      let decoded = jwt_decode(token);
      userID = decoded?.id;
    }
    const deviceId = getLocalStorage(LOCAL_STORAGE_KEYS.MACHINEID);
    let categoryId = 0;
    if (product.product_category_relations.length > 0) {
      categoryId = product.product_category_relations[0].categoryId;
    } else {
      dispatch(changeSnackbar({
        ...snackbar,
        isOpen: true,
        message: t("somethingWentWrong"),
        state: "error",
      }));
      setDisableAddToCart(false)
      dispatch(changeLoader(false));
    }
    const data = {
      productId: product_id,
      storeId: store_id,
      uomId: uomId,
      userId: userID,
      deviceId: (userID === '') ? deviceId : '',
      quantity: 1,
      addonIds: addons,
      specialRequest: addonsNotes,
      categoryId: categoryId
    }
    let addToCartUrl = API_ROUTES.CART.ADD_CART_DATA;
    addToCartUrl += `?latitude=${userAddress?.latitude}&longitude=${userAddress?.longitude}`
    const cartResponse = await postRequest(addToCartUrl, data);
    const status = cartResponse.ack;
    dispatch(changeLoader(false));
    if (status === 1) {
      dispatch(changeSnackbar({
        ...snackbar,
        isOpen: true,
        message: cartResponse.msg,
        state: "success",
      }));
      getCartData();
    } else {
      dispatch(changeSnackbar({
        ...snackbar,
        isOpen: true,
        message: cartResponse.msg,
        state: "error",
      }));
      setDisableAddToCart(false)
    }
  }

  const subQuantity = async (product_id) => {
    const cartitem = cartData?.cart_items.find(item => item.productId === product_id && item.uomId === selectedUom?.id)
    if (cartitem.quantity > 1) {
      let updateCartUrl = API_ROUTES.CART.UPDATE_CART_DATA(cartitem.id);
      updateCartUrl += `?latitude=${userAddress?.latitude}&longitude=${userAddress?.longitude}`

      const response = await putRequest(updateCartUrl, {
        quantity: 1,
        action: 'minus'
      })
      let status = response.ack;
      if (status === 1) {
        getCartData();
      } else {
        dispatch(changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: response.msg,
          state: "error",
        }));
      }
    } else {
      const response = await deleteRequest(API_ROUTES.CART.DELETE_CART_DATA(cartitem.id))
      let status = response.ack;
      if (status === 1) {
        dispatch(changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: response.msg,
          state: "success",
        }));
        getCartData();
      } else {
        dispatch(changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: response.msg,
          state: "error",
        }));
      }
    }
  }

  const updateQuantityCart = async (cartItemId) => {
    let updateCartUrl = API_ROUTES.CART.UPDATE_CART_DATA(cartItemId);
    updateCartUrl += `?latitude=${userAddress?.latitude}&longitude=${userAddress?.longitude}`

    const response = await putRequest(updateCartUrl, {
      quantity: 1,
      action: 'add'
    })
    let status = response.ack;
    if (status === 1) {
      getCartData();
    } else {
      dispatch(changeSnackbar({
        ...snackbar,
        isOpen: true,
        message: response.msg,
        state: "error",
      }));
    }
  }

  const addQuantity = async (product_id) => {
    const cartitem = cartData?.cart_items.find(item => item.productId === product_id && item.uomId === selectedUom?.id)
    if (product?.brandId === baladiData.BALADI_BRAND_Id) {
      if (cartitem.remainingQuantity < 1) {
        dispatch(changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: t("cart_productMaxQtyReached"),
          state: "error",
        }));
        return;
      }
      if (cartitem.remainingQuantity < cartitem.relationWithBase) {
        dispatch(changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: t("cart_productMaxQtyReached"),
          state: "error",
        }));
        return;
      }
    }
    if (selectedUom?.uom_addon_relations.length > 0) {
      selectCartItem = cartitem.id;
      setSelectCartItem(selectCartItem);
      setReapetLastUseCostumise(true)
    } else {
      updateQuantityCart(cartitem.id)
    }
  }

  const handleMoveToCart = async () => {
    setOpenMoveToCard(false);
    addtocart();
    handleRemoveFromWishlist(itemId)
  }

  const emptyCart = async () => {
    let userID = '';
    if (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) {
      const token = getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
      let decoded = jwt_decode(token);
      userID = decoded?.id;
    }
    const deviceId = getLocalStorage(LOCAL_STORAGE_KEYS.MACHINEID);
    const data = {
      userId: userID,
      deviceId: (userID === '') ? deviceId : ''
    }
    const cartResponse = await postRequest(API_ROUTES.CART.EMPTY_CART, data);
    let status = cartResponse.ack;
    if (status === 1) {
      localStorage.removeItem(LOCAL_STORAGE_KEYS.CART);
      dispatch(changeCart());
      checkAndAddtocart(cartId, storeId, selectedUom?.id);
    } else {
      dispatch(changeSnackbar({
        ...snackbar,
        isOpen: true,
        message: cartResponse.msg,
        state: "error",
      }));
    }
  }

  const handleRemoveFromWishlist = async (id) => {
    dispatch(changeLoader(true));
    let item = wishlist.find((item) => item.id === id);

    const { ack, msg } = await deleteRequest(API_ROUTES.WISHLIST.DELETE_WISHLIST_DATA(item.product.id, item.storeId));
    if (ack === 1) {
      dispatch(changeSnackbar({
        ...snackbar,
        isOpen: true,
        message: msg,
        state: "success",
      }));
      dispatch(changeLoader(false));
      getWishlist();
    } else {
      dispatch(changeSnackbar({
        ...snackbar,
        isOpen: true,
        message: msg,
        state: "error",
      }));
      dispatch(changeLoader(false));
    }
  }

  const getWishlist = async () => {
    if (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) {
      const { ack, data } = await getRequest(API_ROUTES.WISHLIST.GET_WISHLIST_DATA);
      if (ack === 1) {
        setLocalStorage(LOCAL_STORAGE_KEYS.WISHLIST, JSON.stringify(data))
        dispatch(changeWishlist());
      } else {
        dispatch(changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: msg,
          state: "error",
        }));
      }
    }
  }

  const selectUom = (uomId) => {
    let selectedUom = uoms.find(uom => uom.id === uomId);
    setSelectedUom(selectedUom);
  }

  const getUom = async (product) => {
    uoms = product?.uoms.filter((item) => {
      if (product?.brandId === baladiData.BALADI_BRAND_Id) {
        if (item.relationWithBase <= product?.stockQuantity) {
          return item;
        }
      } else {
        if (item.stock === 1) {
          return item;
        }
      }
      // if (item.manage_store_products?.filter((item) => item.storeId === storeId).length === 0) {
      // }
    }) || [];
    setUoms(uoms);
    selectedUom = uoms.find(uom => cartProductIds?.includes(product?.id) && cartUomIds?.includes(uom?.id));
    if (!selectedUom) {
      selectedUom = uoms.find(uom => uom.isDefault === 1);
    }
    if (!selectedUom) {
      selectedUom = uoms[0];
    }
    setSelectedUom(selectedUom);
    if (selectedUom?.image) {
      frontImage = selectedUom?.image;
    } else if (selectedUom?.uom_images || selectedUom?.uomImages) {
      frontImage = getFrontTypeImageUrl(
        selectedUom?.uom_images
          ? selectedUom?.uom_images
          : selectedUom?.uomImages
            ? selectedUom?.uomImages
            : []
      );
    }
    setFrontImage(frontImage);
  }

  const getNearestStore = async (storeId) => {
    const response = await getRequest(API_ROUTES.WISHLIST.GET_NEAREST_STORE(storeId, userAddress?.latitude, userAddress?.longitude));
    if (response.ack === 1 && response.stores.length > 0) {
      storeSlug = response.stores[0].slug;
      setStoreSlug(storeSlug)
      setStoreId(response.stores[0].id);
    }
  }

  useEffect(() => {
    getUom(product);
    if (storeSlug === '') {
      storeSlug = product.store.slug;
      setStoreSlug(storeSlug)
    }
    if (product?.availability_start_time && product?.availability_end_time && product?.availability_start_time !== "" && product?.availability_end_time !== "") {
      // get curren time
      let today = new Date();
      const hours = today.getHours() < 10 ? `0${today.getHours()}` : today.getHours();
      const minutes = today.getMinutes() < 10 ? `0${today.getMinutes()}` : today.getMinutes();
      let time = hours + ":" + minutes;
      setProductTimeAvailability(
        parseInt(product?.availability_start_time.split(':').join('')) <= parseInt(time.split(':').join('')) &&
        parseInt(product?.availability_end_time.split(':').join('')) >= parseInt(time.split(':').join(''))
      );
    }
  }, [storeId, online, product, index2]);

  useEffect(() => {
    if (storesId !== 0) {
      setStoreId(storesId);
    } else {
      getNearestStore(product?.storeId)
    }
  }, [storesId]);

  return (
    <>
      <Grid key={`product-${storeId}-${index2}`} >
        <div className="product-box product228">
          <span className="wishlist" onClick={() => handleRemoveFromWishlist(itemId)}>
            <img src={heart} alt=".." className="" />
          </span>
          <div className={selectedUom?.stock === 1 ? '' : 'out-of-stock disable'}>
          <Link to={`/product-detail/${storeSlug}/${product?.slug}`}>
            <div className="text-center relative">
              <Image
                src={axiosConstant.IMAGE_BASE_URL(frontImage)}
                className="product-image"
                alt="product"
              />
              <div className="chips-row">
                {offers?.filter((offerF, indexF) => indexF < 3).map((offer, index) =>
                  <span className="chips" key={`offerstoreDetails-${index}`} >{offer.name}</span>)}
                {offers?.length > 3 ? <span className="chips">+{offers?.length - 3} {t("More")}</span> : null}
              </div>
            </div>
            <p className="p3">{product?.store?.stores_locales[0]?.business_name}</p>
            <p className="p1">
              {product?.products_locales[0].title} {selectedUom?.uomName}
            </p>
            <div className="truncate" dangerouslySetInnerHTML={{
              __html: product?.products_locales
                ? product?.products_locales[0]?.description
                : product?.products_locale[0]?.description
            }}></div>
            <div className="product-price">
              <p className="p1">
                QAR {selectedUom?.salePrice !== 0 ? displayPrice(selectedUom?.salePrice) : displayPrice(selectedUom?.price)}
                {selectedUom?.salePrice !== 0 && selectedUom?.price !== selectedUom?.salePrice ?
                  <del className="text-12 ">QAR {displayPrice(selectedUom?.price)}</del> : null}
              </p>
            </div>
          </Link>
          {productTimeAvailability ? <Boxed mt={"16px"}>
            {!cartUomIds?.includes(selectedUom?.id) || cartData.storeId !== storeId? (
              product?.brandId !== baladiData.BALADI_BRAND_Id ?
                selectedUom?.stock === 1 ? (
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => checkAndAddtocart(product?.id, storeId, selectedUom?.id)}
                    className={`${disableAddToCart ? "disable" : ""}`}
                    fullWidth
                  >
                    {t("addToCart")}
                  </Button>
                ) : (
                  <Button variant="outlined" size="small" className={`disable`} fullWidth>
                    {t("outOfStock")}
                  </Button>
                ) : product?.stockQuantity > 0 ? (
                  cartProductIds?.includes(product?.id) ?
                    <Button variant="outlined" size="small" className={`disable`} fullWidth>
                      {t("cart_productAlreadyInCart")}
                    </Button> :
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => checkAndAddtocart(product?.id, storeId, selectedUom?.id)}
                      className={`${disableAddToCart ? "disable" : ""}`}
                      fullWidth
                    >{t("addToCart")}</Button>
                ) : <Button variant="outlined" size="small" className={`disable`} fullWidth>{t("outOfStock")}</Button>
            ) : (
              product?.brandId !== baladiData.BALADI_BRAND_Id ?
                selectedUom?.stock === 1 ?
                  <div className="increase-decrease-box height32 w-100">
                    <Button type="button" onClick={() => subQuantity(product?.id)}>
                      <img src={minusIcon} alt="..." />
                    </Button>
                    <input
                      value={
                        cartData?.cart_items.find(
                          (item) => item?.productId === product?.id && item?.uomId === selectedUom?.id
                        )?.totalQuantity
                      }
                      readOnly={true}
                      className="product-qty"
                    />
                    <Button type="button" onClick={() => addQuantity(product?.id)}>
                      <img src={plusIcon} alt="..." />
                    </Button>
                  </div> : <Button variant="outlined" size="small" className={`disable`} fullWidth>{t("outOfStock")}</Button>
                : product?.stockQuantity > 0 ?
                  <div className="increase-decrease-box height32 w-100">
                    <Button type="button" onClick={() => subQuantity(product?.id)}>
                      <img src={minusIcon} alt="..." />
                    </Button>
                    <input
                      value={
                        cartData?.cart_items.find(
                          (item) => item?.productId === product?.id && item?.uomId === selectedUom?.id
                        )?.totalQuantity
                      }
                      readOnly={true}
                      className="product-qty"
                    />
                    <Button type="button" onClick={() => addQuantity(product?.id)}>
                      <img src={plusIcon} alt="..." />
                    </Button>
                  </div> : <Button variant="outlined" size="small" className={`disable`} fullWidth>{t("outOfStock")}</Button>
            )}
          </Boxed> : <Button variant="outlined" size="small" className={`disable`} fullWidth>{t("Unavailable")}</Button>}
          </div>
        </div>
      </Grid>
      <Dialog open={openMoveToCard} onClose={handleClose} className="main-modal theme-dialog">
        <DialogContent>
          <h2 className="h3" style={{ marginBottom: "10px" }}>
            {t("wishlist_move_to_cart")}
          </h2>
          <p className="p3" style={{ marginBottom: "12px", fontSize: "14px" }}>
            {t("wishlist_Areyousureyouwanttomovethisproducttocart")}
          </p>
          <Grid item xs={12} textAlign="center" display={"flex"} justifyContent={"flex-end"}>
            <Button
              type="button"
              size="medium"
              variant="text"
              color="primary"
              onClick={() => {
                handleMoveToCart();
              }}
            >
              {t("wishlist_Yes")}
            </Button>
            <Button type="button" size="medium" variant="text" color="error" onClick={handleClose}>
              {t("wishlist_No")}
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>

      {isVendor ? (
        <VendorMisMatch
          CloseDialog={() => setIsVendor(false)}
          handleChange={() => {
            setIsVendor(false);
            emptyCart();
          }}
        />
      ) : null}
      {isAddToCardModifier ? (
        <AddToCardModifier
          productName={`${product?.products_locales ? product?.products_locales[0]?.title : product?.products_locale[0]?.title}  ${selectedUom?.uomName}`}
          productPrice={selectedUom?.salePrice !== 0 ? displayPrice(selectedUom?.salePrice) : displayPrice(selectedUom?.price)}
          productImage={frontImage}
          uonId={selectedUom?.id}
          prioductId={product?.id}
          uoms={uoms}
          storeId={storeId}
          handleClose={() => setAddToCardModifier(false)}
          addCart={(cartAddons, notes = '') => {
            setAddToCardModifier(false)
            addonsNotes = notes;
            setAddonsNotes(addonsNotes);
            addtocart(product?.id, storeId, selectedUom?.id, cartAddons.join(','));
          }}
          updateQuantity={() => {
            setAddToCardModifier(false)
            updateQuantityCart(selectCartItem)
          }}
          handleUomChange={(uomId) => {
            selectUom(uomId)
          }}
        />
      ) : null}
      {isReapetLastUseCostumise ? (
        <ReapetLastUseCostumise
          cartId={selectCartItem}
          productName={`${product?.products_locales ? product?.products_locales[0]?.title : product?.products_locale[0]?.title}  ${selectedUom?.uomName}`}
          handleClose={() => setReapetLastUseCostumise(false)}
          repeatLast={() => {
            setReapetLastUseCostumise(false)
            updateQuantityCart(selectCartItem)
          }}
          openCustomization={() => {
            setReapetLastUseCostumise(false)
            setAddToCardModifier(true);
          }}
        />
      ) : null}
    </>
  );

}

export default WishlistProductBox;