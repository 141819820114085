import { useState, useEffect } from "react";
import { currentAddress } from "../../../redux/reducers/address";
import { getRequest } from "../../../Helpers/Axios/Services";
import { API_ROUTES } from "../../../Helpers/Axios/ApiRoutes";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import homeSliderImage from "../../../assets/images/structure/home-banner.jpg";
import SlideComponent from "./SlideComponent";

const Banner = () => {
  let [banners, setBanners] = useState([]);
	const userAddress = useSelector(currentAddress);
  const homeSliderSettings = {
    dots: false,
    arrows: false,
    infinite: true, 
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 1000,     
    fade: true, 
  };

  const getBannerData = async () => {
    let url = API_ROUTES.COMMON.GET_BANNER(10, 1);
    url += `&latitude=${userAddress?.latitude}&longitude=${userAddress?.longitude}`;
    const response = await getRequest(url);
    banners = response?.data?.rows?.length > 0 ? response?.data?.rows : [];
    setBanners(banners);
  };

  useEffect(() => {
    getBannerData();
  }, [userAddress]);

  return (
  	<>
    	<section className="home-section1">
    	  <Slider {...homeSliderSettings} className="slider-parent">
    	  	{banners?.length > 0 ? banners.map((banner, index) => 
    	  		<SlideComponent key={`banner-${index}`} index={index} banner={banner}/>
    	  	) : <img src={homeSliderImage} alt="banner" className="home-slider" />}
          {/* <div>
            <img src={HomeBanner1} alt="Banner 1" className="home-slider" />
          </div>
          <div>
            <img src={HomeBanner2} alt="Banner 2" className="home-slider" />
          </div> */}
        </Slider>
        {/* <div className="container-1110">
          <Grid container columnSpacing={0}>
            <Grid item xs={12} sm={12} md={8}>
              <Bounce bottom>
                <h1 className="h1">
                  {t('fresh')} <br />
                  {t('onlineGroceryShopping')}
                </h1>
                <p className="p2">
                	{t('youCanEasilySearchForWhatCategoryOfItemYouWantToOrder')}
                </p>
                <Button type="button" variant="contained" size="large">
                  {t("shopNow")}
                </Button>
              </Bounce>
            </Grid>
          </Grid>
        </div> */}
      </section>
    </>
  );
};

export default Banner;
