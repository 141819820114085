/**
 * @file ThankYou.js
 * @description Thank You Dialog Box
 * @exports ThankYou - Dialog Box
 * @exports CloseDialog - Function to close the dialog box
 */

import { Dialog, DialogContent } from '@mui/material';
import modalClose from "../assets/images/structure/close-black.svg"
import thankyouImg from "../assets/images/structure/thankyou-img.webp"
import { Boxed } from '../Styles-Elements/Box';
import { useTranslation } from "react-i18next";

const ThankYou = ({ CloseDialog }) => {
  const { t } = useTranslation();
  return (
    <Dialog open={true} onClose={CloseDialog} className="main-modal">
      <img src={modalClose} alt="close" onClick={CloseDialog} className='modal-close' />
      <DialogContent>
        <Boxed className="text-center" mt={'10px'} mb={'20px'}>
          <img src={thankyouImg} alt="Thank-you" />
        </Boxed>
        <h2 className='h3 text-center' style={{ marginBottom: '8px' }}>{t('Yay')}</h2>
        <p className='p3 text-center' style={{ marginBottom: '12px', fontSize: '14px' }}>{t('thankYouForRegisteringOnBaladiExpress')}</p>
      </DialogContent>
    </Dialog>
  )
};

export default ThankYou;