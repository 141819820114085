/**
 * @file Breadcrumb.js
 * @description This component is used to show breadcrumb.
 * @exports Breadcrumb component
 * @param {array} breadcrums - array of objects
 */

import rightArrowGray from "../assets/images/structure/right-arrow-gray.svg"
import { Link } from "react-router-dom";

const Breadcrumb = ({ breadcrums }) => {
	return (
		<div>
			<div className="container-body">
				<div className='breadcrumbs-wrapper'>
					{breadcrums?.length > 0 ? breadcrums?.map((crums, index) => {
						if (index !== 0) {
							if (crums.haveLink) {
								return <span key={`bread-${index}`}>
									<img src={rightArrowGray} alt="rightArrowGray" />
									<Link to={crums.link} className='breadcrumb-text links'>{crums.title}</Link>
								</span>
							} else {
								return <span key={`bread-${index}`}>
									<img src={rightArrowGray} alt="rightArrowGray" />
									<span className='breadcrumb-text'>{crums.title}</span>
								</span>
							}
						} else {
							if (crums.haveLink) {
								return <Link key={`bread-${index}`} to={crums.link} className='breadcrumb-text links'>{crums.title}</Link>
							} else {
								return <span key={`bread-${index}`} className='breadcrumb-text'>{crums.title}</span>
							}
						}
					}) : null}
				</div>
			</div>
		</div>
	)
};

export default Breadcrumb;