import React, { useRef, useEffect, useState } from 'react';
import { Flip, Fade } from 'react-reveal';
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import { getRequest } from "../../../Helpers/Axios/Services";
import { API_ROUTES } from "../../../Helpers/Axios/ApiRoutes";
import { currentAddress } from "../../../redux/reducers/address";
import { useSelector } from "react-redux";
import { axiosConstant } from '../../../Helpers/Axios/Constants';
import { useNavigate } from "react-router-dom";
import { currentBaladiData } from "../../../redux/reducers/baladiData";

const Partners = () => {
    const { t } = useTranslation();
		const navigate = useNavigate();
		const userAddress = useSelector(currentAddress);
		const baladiData = useSelector(currentBaladiData);
    const videoRefs = useRef([]);
    const [categoryWidgets, setCategoryWidgets] = useState(null);

    const settings = {
      centerMode: false,
      dots: false,
      infinite: false,
      centerPadding: '20px',
      arrows: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1025,
          settings: { slidesToShow: 3, slidesToScroll: 1 },
        },
        {
          breakpoint: 769,
          settings: { slidesToShow: 2, slidesToScroll: 1 },
        },
        {
          breakpoint: 576,
          settings: { slidesToShow: 1, slidesToScroll: 1 },
        },
      ],
      afterChange: (currentSlide) => {
        if (videoRefs.current[currentSlide]) {
          videoRefs.current[currentSlide].play();
        }
      },
      beforeChange: (currentSlide) => {
        if (videoRefs.current[currentSlide]) {
          videoRefs.current[currentSlide].pause();
        }
      },
    };

    const getWigdets = async () => {
      const response = await getRequest(API_ROUTES.NEW_HOME.GET_APP_HOME(userAddress?.latitude, userAddress?.longitude));
			setCategoryWidgets(response.widgetDataRes.find(el => el.sort_id == 2));
    }

    useEffect(() => {
        getWigdets();
    }, []);

  return (
		categoryWidgets && <>
      <section className="home-section8">
        <div className="container-1110">
          <div className="text-center">
            <Flip bottom>
                <h2 className="h2">{categoryWidgets?.widget_locales[0].name}</h2>
                <p className="p2">{categoryWidgets?.widget_locales[0].description}</p>
            </Flip>
          </div>

						{/* <Fade bottom>
							<div className="trending-box">
								<video
									ref={(el) => (videoRefs.current[3] = el)}
									className="trending-video"
									width="100%"
									loop
									autoPlay
									muted
								>
									<source src={trendingVideo} type="video/mp4" />
									Your browser does not support the video tag.
								</video>
								<div className="off-div">
									<div className="border-white">
										<h6 className="h6">25% OFF</h6>
										<p className="p3">TODAY’S DISCOUNT</p>
									</div>
								</div>
								<h5 className="h5">Bakery</h5>
							</div>
						</Fade> */}

          <Slider {...settings}>
						{categoryWidgets?.widget_mappings.map((item, index) => {
							// http://localhost:3000/store/baladi-express-store/Water-and-Ice-MF
							return (
								<Fade bottom key={`trending-${index}`} 
								>
									<div className="trending-box" 
										onClick={() => navigate(`/store/${baladiData.BALADI_STORE_SLUG}/${item?.category?.slug}`)}
										style={{ cursor: 'pointer' }}
									>
										<img className="trending-video" src={axiosConstant.IMAGE_BASE_URL(item?.category?.image_gif)} alt="..." />
										{/* <div className="off-div">
											<div className="border-white">
												<h6 className="h6">25% OFF</h6>
												<p className="p3">TODAY’S DISCOUNT</p>
											</div>
										</div> */}
										<h5 className="h5">{item?.category?.category_locales[0].name}</h5>
									</div>
								</Fade>
							)
						})}

          </Slider>
        </div>
      </section>
    </>
  );
};

export default Partners;
