import encryptDcrypt from './encryptDcrypt';

/**
 * @file LocalStorage.js
 * @description Constant for local storage keys
 * @returns local storage keys
 */
const LOCAL_STORAGE_KEYS = {
  AUTH_TOKEN: '_qjtxabugis',
  LANGUAGE: '_jriggntehc',
  MACHINEID: '_rezuiluaxt',
  ACTIVE_LINK: '_smkmthvrel',
  CART: '_gjzboujszq',
  WISHLIST: '_riokgnogpy',
  SELECTED_ADDRESS: '_uuuvcskrbd',
  DEFAULT_ADDRESS: '_AjtxabugiA',
  TOTAL_ADDRESS: '_BjtxabugiB',
  BALADI_DATA: '_CjtxabugiC',
};

/**
 * @description This function is used to set the local storage
 * @param {*} key 
 * @param {*} value 
 */
const setLocalStorage = (key, value) => {
  localStorage.setItem(key, encryptDcrypt(value));
}

/**
 * @description This function is used to get the local storage
 * @param {*} key
 * @returns local storage value
 * @default null
 */
const getLocalStorage = (key) => {
  if (localStorage.getItem(key)) {
    return encryptDcrypt(localStorage.getItem(key), 'de');
  }
}

export { setLocalStorage, getLocalStorage, LOCAL_STORAGE_KEYS };