import { useEffect, useState } from "react";
import { Button, Grid } from "@mui/material";
import BuyGroceriesImg from "../../../assets/images/structure/BuyGroceriesImg.png";
import FlashEndSaleImg from "../../../assets/images/structure/FlashEndSaleImg.png";
import BaladiCarNew from "../../../assets/images/structure/BaladiCarNew.png";
import { useTranslation } from "react-i18next";
import { Flip } from "react-reveal";
import { useNavigate } from "react-router-dom";
import { getRequest } from "../../../Helpers/Axios/Services";
import { API_ROUTES } from "../../../Helpers/Axios/ApiRoutes";
import { useSelector } from "react-redux";
import { currentAddress } from "../../../redux/reducers/address";
import { axiosConstant } from '../../../Helpers/Axios/Constants';
import { currentBaladiData } from "../../../redux/reducers/baladiData";

const Groceries = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const baladiData = useSelector(currentBaladiData)
  const userAddress = useSelector(currentAddress);

  const getWidget = async () => {
    const response = await getRequest(API_ROUTES.NEW_HOME.GET_WIDGET(userAddress?.latitude, userAddress?.longitude))
    setData(response?.widgetDataRes[0])
  }

  const gotoBrandStore = async (banner) => {
    
    const response = await getRequest(API_ROUTES.COMMON.GET_NEAREST_STORE_BY_BRAND(banner?.brandId, userAddress.latitude,userAddress.longitude))
    let storeSlug = response.stores[0].slug;
    let redirectUrl = (banner?.storeId !== baladiData.BALADI_STORE_Id) ?
    `/store/${storeSlug}${banner?.header_banner_locales[0].url.trim() !== '' ?
      `?s=${banner?.header_banner_locales[0].url}` : ''}` :
    `/category/${storeSlug}${banner?.header_banner_locales[0].url.trim() !== '' ?
      `?s=${banner?.header_banner_locales[0].url}` : ''}`
    navigate(redirectUrl)
  }

  useEffect(() => {
    getWidget()
  }, [])

  return (
    <>
      <section className="home-section4">
        <div className="container-1110">
          <Grid container columnSpacing={{ xs: 0, sm: 0, md: 2, lg: "30px" }}>

            {data?.widget_mappings?.map((item, index) =>
              {
                const banner = item.banner
                let redirectUrl = '';
                let isBrand = false;
                if (banner?.productId !== 0) {
                  redirectUrl = `/product-detail/${banner?.store?.slug || banner?.storeId}/${banner?.product?.slug || banner?.productId}`
                } else if (banner?.categoryId !== 0) {
                  redirectUrl = (banner?.storeId !== baladiData.BALADI_STORE_Id) ?
                    `/store/${banner?.store?.slug || banner?.storeId}${banner?.header_banner_locales[0].url.trim() !== '' ?
                      `?s=${banner?.header_banner_locales[0].url}&cat=${banner?.categoryId}` : `?cat=${banner?.categoryId}`}` :
                    `/store/${banner?.store?.slug || banner?.storeId}/${banner?.category?.slug}${banner?.header_banner_locales[0].url.trim() !== '' ?
                      `?s=${banner?.header_banner_locales[0].url}&cat=${banner?.categoryId}` : ''}`
                } else if (banner?.storeId !== 0) {
                  redirectUrl = (banner?.storeId !== baladiData.BALADI_STORE_Id) ?
                    `/store/${banner?.store?.slug || banner?.storeId}${banner?.header_banner_locales[0].url.trim() !== '' ?
                      `?s=${banner?.header_banner_locales[0].url}` : ''}` :
                    `/category/${banner?.business_type?.slug}${banner?.header_banner_locales[0].url.trim() !== '' ?
                      `?s=${banner?.header_banner_locales[0].url}` : ''}`
                } else if (banner?.brandId !== 0) {
                  isBrand = true;
                } else {
                  redirectUrl = (banner?.businessId != 2) ?
                    `/storefront/${banner?.business_type?.slug || banner?.businessId}${banner?.header_banner_locales[0].url.trim() !== '' ?
                      `?s=${banner?.header_banner_locales[0].url}` : ''}` :
                    `/category/${banner?.business_type?.slug}${banner?.header_banner_locales[0].url.trim() !== '' ?
                      `?s=${banner?.header_banner_locales[0].url}` : ''}`
                }
                return (
                  <Grid item xs={12} sm={12} md={6}>
                    <img
                      onClick={() => {
                        (isBrand) ? gotoBrandStore(banner) : navigate(redirectUrl)
                        console.log(redirectUrl);
                      }}
                      src={axiosConstant.IMAGE_BASE_URL(
                        localStorage.getItem('i18nextLng') === 'ar' ? item.banner?.image_ar : item.banner?.image
                      )}
                      alt="..."
                      className="shop-bottom-img"
                      style={{
                        borderRadius: "20px",
                        cursor: "pointer",
                      }}
                    />
                  </Grid>
                )
              }
            )}

            {/* <Grid item xs={12} sm={12} md={6}>
              <div className="shop-now-box">
                <div className="relative">
                  <h4 className="h4">{"buyGroceries"}</h4>
                  <p className="p2">
                    {t("youCanEasilySearchForWhatCategoryOfItemYouWantToOrder")}
                  </p>
                  <Button type="button" variant="contained" size="large" onClick={() => navigate("/category/grocery")}>
                    {t("shopNow")}
                  </Button>
                </div>
                <img
                  src={BuyGroceriesImg}
                  alt="..."
                  className="shop-bottom-img"
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <div className="shop-now-box bg-change">
                <div className="relative">
                  <h4 className="h4">{t("flashEndSale")}</h4>
                  <p className="p2">
                    {t("youCanEasilySearchForWhatCategoryOfItemYouWantToOrder")}
                  </p>
                  <Button type="button" variant="contained" size="large">
                    {t("shopNow")}
                  </Button>
                </div>
                <img
                  src={FlashEndSaleImg}
                  alt="..."
                  className="shop-bottom-img"
                />
              </div>
            </Grid> */}
          </Grid>
        </div>
      </section>

      <section className="home-section5">
        <div className="container-1110">
          <Grid container columnSpacing={0}>
            <Grid item xs={12} sm={12} md={6}>
              <div className="guarantee-vector">
                <img src={BaladiCarNew} alt="..." className="car-img" />
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"flex-start"}
              flexDirection={"column"}
            >
              <Flip bottom>
                <h2 className="h2">
                {t('weGuarantee')}
                  <br /> 30 {('minutesDelivery')}
                </h2>
              </Flip>
              <p className="p2">
              {t('baladiExpressIsAQuickAndConvenientServiceThatHasBecomeIncreasingly__')}
              </p>
              <Button type="button" variant="contained" size="large" onClick={() => navigate("/category/grocery")}>
                {t("orderNow")}
              </Button>
            </Grid>
          </Grid>
        </div>
      </section>
    </>
  );
};

export default Groceries;
