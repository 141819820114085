import { useState, useEffect } from 'react';
import "./Stores.scss"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { Boxed } from "../../../Styles-Elements/Box";
import { useTranslation } from "react-i18next";
import { axiosConstant } from '../../../Helpers/Axios/Constants';
import { Link } from "react-router-dom";
import { getRating, getDistanceFromLatLonInKm } from "../../../Helpers/HelperFunctions";
import { currentAddress } from '../../../redux/reducers/address';
import distanceIcon from "../../../assets/images/structure/distance.svg"
import Ratings from "../../../global-modules/Rating/Ratings";
import Image from "../../../global-modules/Image/Image";
import NoDataFound from "../../../global-modules/EmptyPages/NoDataFound";
import ComingSoon from '../../../global-modules/ComingSoon/ComingSoon';

const Stores = ({
  storesFirst,
  storesSecond,
  recomendedStores,
  search,
  businessType,
  slug,
  handleChangeDiscount,
  discountDataOfAll
}) => {
  const userAddress = useSelector(currentAddress)
  const { t } = useTranslation();
  const [activeButton, setActiveButton] = useState(null);
  const handleButtonClick = (buttonId) => {
    setActiveButton(activeButton === buttonId ? '' : buttonId);
  };

  var settings = {
    centerMode: true,
    dots: false,
    infinite: recomendedStores?.length > 4 ? true : false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          centerMode: false,
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      }
    ]
  };

  useEffect(() => {
    handleChangeDiscount(activeButton);
  }, [activeButton]);

  useEffect(() => {
    setActiveButton('');
  }, [slug]);

  return (
    <div>
      {search !== "" ? (
        <div className="container-body">
          <div className="search-results-block">
            <span className="p1">{t("storefront_searchResultFor")}</span>
            <span className="p1-semibold"> {search}</span>
          </div>
        </div>
      ) : null}
      <div className="stores-wrapper">
        <div className="container-body">
          <div className='discount-button-row'>
            {discountDataOfAll?.filter((discount) => discount.type === "flat").length > 0 ? <button
              className={activeButton === 'flat' ? 'discount-button active' : 'discount-button'}
              onClick={() => handleButtonClick('flat')}
            >
              {t("storefront_FlatDiscount")}
            </button> : null}
            {discountDataOfAll?.filter((discount) => discount.type === "percentage").length > 0 ?
              <button
                className={activeButton === 'percentage' ? 'discount-button active' : 'discount-button'}
                onClick={() => handleButtonClick('percentage')}
              >
                {t("storefront_PercentageDiscount")}
              </button> : null}
            {discountDataOfAll?.filter((discount) => discount.type === "bogo").length > 0 ?
              <button
                className={activeButton === 'bogo' ? 'discount-button active' : 'discount-button'}
                onClick={() => handleButtonClick('bogo')}
              >
                {t("storefront_BOGO_Buyonegetone_")}
              </button> : null}
          </div>
        </div>
        {(storesFirst?.length === 0 && storesSecond?.length === 0) ?
          search === '' ? <ComingSoon /> : <NoDataFound text={t('storefront_NoStoresFound')} />
          : <>
            <div className="container-body">
              {/* new-stores-block start */}

              {/* new-stores-block end */}

              <div className="stores-block">
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  {storesFirst.length > 0
                    ? storesFirst.map((store, index) => (
                      <Grid item xs={4} key={`store-first-${index}`}>
                        <div>
                          <Link
                            to={`/store/${store?.slug && store?.slug !== "" ? store?.slug : store?.id}`}
                            className={`store-box no-decoration ${store.online_status === 1 ? "" : "disable"}`}
                          >
                            <div className="store-content-block">
                              <h6 className="h6">
                                {store.stores_locales[0].business_name}
                              </h6>
                              <p className="p2">
                                {store?.business_address && store?.business_address !== ""
                                  ? store?.business_address
                                  : `${store?.zone_number}, ${store?.street_number}, ${store?.building_number}, ${store?.country}`}
                              </p>
                              <Boxed
                                display={"flex"}
                                alignItems={"center"}
                                mb={"4px"}
                                className="always-ltr"
                              >
                                <img src={distanceIcon} alt="distanceIcon" />
                                <span className="p2 p2-mins">
                                  {getDistanceFromLatLonInKm(
                                    userAddress?.latitude,
                                    userAddress?.longitude,
                                    store?.latitude,
                                    store?.longitude
                                  )}{" "}
                                  {t("kmAway")} |
                                  <span className="p2 p2-mins">30 ~ 40 {t("storefront_mins")}</span>
                                </span>
                              </Boxed>
                              {store?.avgRating ? (
                                <Boxed
                                  display={"flex"}
                                  alignItems={"center"}
                                  className="rating-show-block"
                                >
                                  <Ratings rating={getRating(store?.avgRating)} />
                                </Boxed>
                              ) : null}
                            </div>
                            <div className="store-img-block">
                              <Image
                                src={axiosConstant.IMAGE_BASE_URL(store?.business_logo)}
                                className="store-image"
                                alt="store-logo"
                              />
                              <div className='chips-row'>
                                {store?.online_status === 1 ? null : (
                                  <span className="closed-chips">{t("storefront_closed")}</span>
                                )}
                                {store?.offers?.filter((offerF, indexF) => indexF < 1).map((offer, index) =>
                                  <span className="discount-chips"
                                    key={`offersstore-${index}`}
                                  >{offer.name}</span>
                                )}
                                {store?.offers?.length > 1 ? <span className="discount-chips">+{store?.offers?.length - 1} {t("More")}</span> : null}
                              </div>
                            </div>
                          </Link>

                          {/* <Link
                      key={`recomended-${index}`}
                      to={`/store/${store?.slug && store?.slug !== "" ? store?.slug : store?.id}`}
                      className={`recommended-card-box no-decoration ${store.online_status === 1 ? "" : "disable"
                        }`}
                    >
                      <Image
                        src={axiosConstant.IMAGE_BASE_URL(store?.business_logo)}
                        className="recommended-image"
                        alt="store-logo"
                      />
                      <span className="closed-chips">{t("storefront_closed")}</span>
                      <div className="chips-row">
                        <span className="chips">{t("storefront_Flat")} 100 {t("storefront_OFF")}</span>
                        <span className="chips">40% {t("storefront_Discount")}</span>
                        <span className="chips">{t("storefront_Buy")} 1 {t("storefront_get")} 1</span>
                      </div>
                      <Boxed padding={"0 12px 0 12px"} className="recommended-content-box">
                        <div className="recommended-content">
                          <h6 className="h6">
                            {store?.stores_locales[0]?.business_name}
                          </h6>
                          <p className="p2">
                            {store?.business_address && store?.business_address !== ""
                              ? store?.business_address
                              : `${store?.zone_number}, ${store?.street_number}, ${store?.building_number}, ${store?.country}`}
                          </p>
                          <Boxed
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            mb={"4px"}
                            className="always-ltr-center"
                          >
                            <img src={distanceIcon} alt="distanceIcon" />
                            <span className="p2 p2-mins">10 {t("storefront_mins")}</span>
                            <span className="p2 p2-mins">
                              {getDistanceFromLatLonInKm(
                                userAddress?.latitude,
                                userAddress?.longitude,
                                store?.latitude,
                                store?.longitude
                              )}{" "}
                              {t("kmAway")}
                            </span>
                          </Boxed>
                          {store?.avgRating ? (
                            <Boxed
                              display={"flex"}
                              alignItems={"center"}
                              className="rating-show-block"
                            >
                              <Ratings withDiv={false} rating={getRating(store?.avgRating)} />
                            </Boxed>
                          ) : null}
                        </div>
                      </Boxed>
                    </Link> */}
                        </div>
                      </Grid>
                    ))
                    : null}
                </Grid>
              </div>
            </div>
            {search === "" && recomendedStores?.length > 0 ? (
              <div className="recommended-stores-block">
                <div className="container-body">
                  <div className="recommended-head">
                    <h3 className="h3">{t("storefront_recommendedStores")}</h3>
                    <Link
                      to={`/recommended-store/${businessType?.slug && businessType?.slug !== ""
                        ? businessType?.slug
                        : businessType?.id
                        }`}
                      className="p1"
                      style={{ cursor: "pointer" }}
                    >
                      {t("storefront_seeAll")}
                    </Link>
                  </div>
                  <Slider
                    {...settings}
                  >
                    {recomendedStores.map((store, index) => (
                      <Link
                        key={`recomended-${index}`}
                        to={`/store/${store?.slug && store?.slug !== "" ? store?.slug : store?.id}`}
                        className={`recommended-card-box no-decoration ${store.online_status === 1 ? "" : "disable"
                          }`}
                      >
                        <Image
                          src={axiosConstant.IMAGE_BASE_URL(store.business_logo)}
                          className="recommended-image"
                          alt="store-logo"
                        />
                        {store?.online_status === 1 ? null : (
                          <span className="closed-chips">{t("storefront_closed")}</span>
                        )}
                        <div className="chips-row">
                          {store?.offers?.filter((offerF, indexF) => indexF < 3).map((offer, index) =>
                            <span key={`offeroffers-${index}`} className="chips">{offer?.name}</span>
                          )}
                          {store?.offers?.length > 3 ? <span className="chips"> +{store?.offers?.length - 3} {t("More")}</span> : null}
                        </div>
                        <Boxed padding={"0 12px 0 12px"} className="recommended-content-box">
                          <div className="recommended-content">
                            <h6 className="h6">
                              {store?.stores_locales[0]?.business_name}
                            </h6>
                            <p className="p2">
                              {store?.business_address && store?.business_address !== ""
                                ? store?.business_address
                                : `${store?.zone_number}, ${store?.street_number}, ${store?.building_number}, ${store?.country}`}
                            </p>
                            <Boxed
                              display={"flex"}
                              alignItems={"center"}
                              justifyContent={"center"}
                              mb={"4px"}
                              className="always-ltr-center"
                            >
                              <img src={distanceIcon} alt="distanceIcon" />
                              {/* <span className="p2 p2-mins">10 {t("storefront_mins")}</span> */}
                              <span className="p2 p2-mins">
                                {getDistanceFromLatLonInKm(
                                  userAddress?.latitude,
                                  userAddress?.longitude,
                                  store?.latitude,
                                  store?.longitude
                                )}{" "}
                                {t("kmAway")} |
                                <span className="p2 p2-mins">30 ~ 40 {t("storefront_mins")}</span>
                              </span>
                            </Boxed>
                            {store?.avgRating ? (
                              <Boxed
                                display={"flex"}
                                alignItems={"center"}
                                className="rating-show-block"
                              >
                                <Ratings withDiv={false} rating={getRating(store?.avgRating)} />
                              </Boxed>
                            ) : null}
                          </div>
                        </Boxed>
                      </Link>
                    ))}
                  </Slider>
                </div>
              </div>
            ) : null}
            <div className="container-body">
              <div className="stores-block">
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  {storesSecond?.length > 0
                    ? storesSecond?.map((store, index) => (
                      <Grid item xs={4} key={`store-second-${index}`}>
                        <div>
                          <Link
                            to={`/store/${store?.slug && store?.slug !== "" ? store?.slug : store?.id}`}
                            className={`store-box no-decoration ${store.online_status === 1 ? "" : "disable"}`}
                          >
                            <div className="store-content-block">
                              <h6 className="h6">
                                {store?.stores_locales[0]?.business_name}
                              </h6>
                              <p className="p2">
                                {store?.business_address && store?.business_address !== ""
                                  ? store?.business_address
                                  : `${store?.zone_number}, ${store?.street_number}, ${store?.building_number}, ${store?.country}`}
                              </p>
                              <Boxed
                                display={"flex"}
                                alignItems={"center"}
                                mb={"4px"}
                                className="always-ltr"
                              >
                                <img src={distanceIcon} alt="distanceIcon" />

                                <span className="p2 p2-mins">
                                  {getDistanceFromLatLonInKm(
                                    userAddress?.latitude,
                                    userAddress?.longitude,
                                    store?.latitude,
                                    store?.longitude
                                  )}{" "}
                                  {t("kmAway")} |
                                  <span className="p2 p2-mins">30 ~ 40 {t("storefront_mins")}</span>
                                </span>
                              </Boxed>
                              {store?.avgRating ? (
                                <Ratings rating={getRating(store?.avgRating)} />
                              ) : null}
                            </div>
                            <div className="store-img-block">
                              <Image
                                src={axiosConstant.IMAGE_BASE_URL(store?.business_logo)}
                                className="store-image"
                                alt="store-logo"
                              />
                              <div className='chips-row'>
                                {store?.online_status === 1 ? null : (
                                  <span className="closed-chips">{t("storefront_closed")}</span>
                                )}
                                {store?.offers?.filter((offerF, indexF) => indexF < 1).map((offer, index) =>
                                  <span className="discount-chips" key={`offerss-${index}`} >{offer.name}</span>
                                )}
                                {store?.offers?.length > 1 ? <span className="discount-chips">+{store?.offers?.length - 1} {t("More")}</span> : null}
                              </div>
                            </div>
                          </Link>
                          {/* <Link
                      key={`recomended-${index}`}
                      to={`/store/${store?.slug && store?.slug !== "" ? store?.slug : store?.id}`}
                      className={`recommended-card-box no-decoration ${store.online_status === 1 ? "" : "disable"
                        }`}
                    >
                      <Image
                        src={axiosConstant.IMAGE_BASE_URL(store?.business_logo)}
                        className="recommended-image"
                        alt="store-logo"
                      />
                      <span className="closed-chips">{t("storefront_closed")}</span>
                      
                      <div className="chips-row">
                  <span className="chips">{t("storefront_Flat")} 100 {t("storefront_OFF")}</span>
                  <span className="chips">40% {t("storefront_Discount")}</span>
                  <span className="chips">{t("storefront_Buy")} 1 {t("storefront_get")} 1</span>
                </div>
                      <Boxed padding={"0 12px 0 12px"} className="recommended-content-box">
                        <div className="recommended-content">
                          <h6 className="h6">
                            {store?.stores_locales[0]?.business_name}
                          </h6>
                          <p className="p2">
                            {store?.business_address && store?.business_address !== ""
                              ? store?.business_address
                              : `${store?.zone_number}, ${store?.street_number}, ${store?.building_number}, ${store?.country}`}
                          </p>
                          <Boxed
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            mb={"4px"}
                            className="always-ltr-center"
                          >
                            <img src={distanceIcon} alt="distanceIcon" />
                            <span className="p2 p2-mins">10 {t("storefront_mins")}</span>
                            <span className="p2 p2-mins">
                              {getDistanceFromLatLonInKm(
                                userAddress?.latitude,
                                userAddress?.longitude,
                                store?.latitude,
                                store?.longitude
                              )}{" "}
                              {t("kmAway")}
                            </span>
                          </Boxed>
                          {store?.avgRating ? (
                            <Boxed
                              display={"flex"}
                              alignItems={"center"}
                              className="rating-show-block"
                            >
                              <Ratings withDiv={false} rating={getRating(store?.avgRating)} />
                            </Boxed>
                          ) : null}
                        </div>
                      </Boxed>
                    </Link> */}
                        </div>
                      </Grid>
                    ))
                    : null}
                </Grid>
              </div>
            </div>
          </>}

      </div>
    </div>
  );
};

export default Stores;