import { Button, Grid } from "@mui/material";
import section3Img from "../../../assets/images/structure/section-3-img.jpg";
import whiteRevenueIcon from "../../../assets/images/structure/Revenue-white-icon.svg";
import whiteDeliveryIcon from "../../../assets/images/structure/Delivery-white-icon.svg";
// import "./../HomeNew/HomeNew.scss";
import { useTranslation } from "react-i18next";
import { Flip } from "react-reveal";
import { axiosConstant } from '../../../Helpers/Axios/Constants';

const GrowBusiness = () => {
  const { t } = useTranslation();

  return (
    <>
      <section className="home-section3">
        <div className="container-1110">
          <Grid container columnSpacing={0}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div className="section3-img">
                <img src={section3Img} alt="" className="" />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Flip bottom>
                <h2 className="h2">
                {t('growYourBusinessWithBaladisPartnerNetwork_')}
                </h2>
              </Flip>
              <p className="p2 ">
                <img src={whiteRevenueIcon} alt="" className="" />
                {t('unlockNewRevenueOpportunities')}
              </p>
              <p className="p2 ">
                <img src={whiteDeliveryIcon} alt="" className="" />
                {t('effortlessDeliverySolutions')}
              </p>
              <p className="p2 mb-48">
                {t('partnerWithBaladiAndExpandYourReachToA')}
              </p>
              <Button
                type="button"
                variant="contained"
                size="large"
                className="white-btn"
                onClick={() => 
                {
                  window.open(
                    axiosConstant.VENDOR_URL, "_blank"
                  )
                  // href={axiosConstant.VENDOR_URL} target="_blank"
                }}
              >
                {t("becomeOurPartner")}
              </Button>
            </Grid>
          </Grid>
        </div>
      </section>
    </>
  );
};

export default GrowBusiness;
