/**
 * @file Constants.js
 * @description This file contains all the constants
 * @version 1.0.0
 * @exports {Object} axiosConstant
 */

/**
 * @description This is the base url for the api
 * @development server url https://devapi.baladiexpress.com
 * @staging server url https://stagapi.baladiexpress.com
 * @production server url https://api.baladiexpress.com
*/
const baseurl = "https://devapi.baladiexpress.com";
// const baseurl = "https://localhost:4243";
import defaultImage from "../../assets/images/structure/default.jpeg";

export const axiosConstant = {
  BASE_URL: baseurl,
  /**
   * @description This is the base url for the images
   * @param {*} image
   * @returns
   */
  // check character at index 0 if it is `/` then remove it
  IMAGE_BASE_URL: (image) => {
    let imageUrl = image?.charAt(0) === "/" ? image : "/" + image;
    imageUrl = image ? baseurl + imageUrl : defaultImage;
    return imageUrl;
  },
  /**
   * @description This is the base url for the vendor Website
   * @development server url https://devvendor.baladiexpress.com
   * @staging server url https://stagvendor.baladiexpress.com
   * @production server url https://vendor.baladiexpress.com
   */
  VENDOR_URL: "https://vendor.baladiexpress.com",
  /**
   * @description This is the base role for the user
   */
  ROLE: "user",
  /**
   * @description This is the country code for the user
   */
  COUNTRY_CODE: "974",
  /**
   * @description This is the days of the week
   */
  DAYS: [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ],
  /**
   * @description This is the months of the year
   */
  MONTH: [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ],
  /**
   * @description This is the api key for the google maps
   */
  API_KEY: 'baladi_dev_pX2Rldl9LSFlVSVM3TDN4YTA3ZHNmZ2R5V0E4ZE1S', // development
  // API_KEY: 'baladi_dev_KHYUIS7L3xa07dsfgdyWA8dMRRsFkc1sfd3432jLB', // staging
  GOOGLE_RECAPTCHA_SITE_KEY: "6LcjpiIkAAAAAP5s9sGtWwCPQgu9IRVCSZp4rqAQ",
  GOOGLE_RECAPTCHA_SECRET_KEY: "6LcjpiIkAAAAACkoTMbw3JGv8VtBORZx7u7-n1DT",
  GOOGLE_GEOCODE_API_KEY: "AIzaSyDZcgHBjIRYDXnj91drlzpOJIEnMH8dzwI",
  /**
   * @description This is the api key and url for the tokan app
   */
  TOOKANAPP_API_URL: "https://api.tookanapp.com/v2/get_fare_estimate",
  TOOKANAPP_API_KEY: "53676884f4440216464a287211482c471ae3cdf32adb733c5e1508c3",

  /**
   * @description This is the api key for amplitude analytics
   */
  REACT_APP_AMPLITUDE_API_KEY: "f366580ce541d9e6f8a3a2af57953951",
  REACT_APP_SEGMENT_WRITE_KEY: "",
};
