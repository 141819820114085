import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import logo from "../../../assets/images/logo/header-logo.svg";
import facebookWhite from "../../../assets/images/structure/facebook-white.svg";
import twitterWhite from "../../../assets/images/structure/twitter-white.svg";
import youtubeWhite from "../../../assets/images/structure/youtube-white.svg";
import instagramWhite from "../../../assets/images/structure/instagram-white.svg";
import appleStore from "../../../assets/images/structure/apple-store.svg";
import googlePlay from "../../../assets/images/structure/google-play.svg";
import { axiosConstant } from "../../../Helpers/Axios/Constants";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const FooterNew = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="footer-wrapper">
        <div className="container-footerbody">
          <div className="footer-space mid-wrap">
            <Grid
              container
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} sm={12} md={6} lg={7} >
                <img src={logo} alt=".." className="footer-logo" />
                <p>
                  Hey there! Dive into Baladi Express for an exciting online
                  shopping spree in Qatar. With a treasure trove of over 150,000
                  products ranging from the freshest bites to trendy fashion and
                  everything in between, we promise a seamless journey from
                  click to doorstep. Our quick delivery, user-friendly
                  experience, and sweet loyalty perks are all about making your
                  day. So, why wait? Let’s make shopping fun again!
                </p>
                <span className="head line-top">{t("followUsOn")}</span>
                <div className="footer-social-wrap">
                  <a
                    href="https://www.facebook.com/baladiexpress"
                    target="_blank"
                  >
                    <img
                      src={facebookWhite}
                      className="social-icons"
                      alt="facebook"
                    />
                  </a>
                  <a href="https://twitter.com/Baladiexpress" target="_blank">
                    <img
                      src={twitterWhite}
                      className="social-icons"
                      alt="twitter"
                    />
                  </a>
                  <a
                    href="https://www.youtube.com/@baladiexpress"
                    target="_blank"
                  >
                    <img
                      src={youtubeWhite}
                      className="social-icons"
                      alt="youtube"
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/baladiexpress/"
                    target="_blank"
                  >
                    <img
                      src={instagramWhite}
                      className="social-icons"
                      alt="instagram"
                    />
                  </a>
                  
                </div>
                <p className="copyRightText">© 2024 Baladi Express. All rights reserved.</p>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={5} > 
                <Grid container>
                  <Grid item xs={6} sm={6} md={6}>
                    <span className="head">{t("GetToKnowUs")}</span>
                    <Link to={"/about_baladi/get"} className="p1">
                      {t("AboutBaladi")}
                    </Link>
                    <Link className="p1">Become a Partner</Link>
                    <Link to={"/contact-us"} className="p1">
                      {t("contactUs")}
                    </Link>
                    <Link to={"/faq"} className="p1">
                      {t("FAQs")}
                    </Link>
                    <span className="head footer-let">{t("LetUsHelpYou")}</span>
                    <Link to={"/privacy_policy/get"} className="p1">
                      {t("privacyPolicy")}
                    </Link>
                    <Link to={"/terms-conditions"} className="p1">
                      {t("termsAndConditions")}
                    </Link>
                    <Link to={"/warrenty_policy/get"} className="p1">
                      {t("warrantyPolicy")}
                    </Link>
                    <Link to={"/delivery_information/get"} className="p1">
                      {t("deliveryInformation")}
                    </Link>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6}>
                  <span className="head">SERVICEABLE CITIES</span>
                    <Link className="p1">
                    Doha
                    </Link>
                    <Link className="p1">Al Rayyan</Link>
                    <Link className="p1">
                    Al Ruwais
                    </Link>
                    <Link className="p1">
                    Mesaieed
                    </Link>
                    <Link className="p1">
                    Al Khor
                    </Link>
                    <Link className="p1">
                    Al Wakrah
                    </Link>
                    <Link className="p1">
                    Umm Salal Muhammad
                    </Link>
                    <Link className="p1">
                    Al Wakrah
                    </Link>
                    <Link className="p1">
                    +56 cities
                    </Link>

                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
};

export default FooterNew;
