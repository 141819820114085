import {
    Grid
} from "@mui/material";
import playStore from "../../../assets/images/structure/play-store.svg";
import appleStore from "../../../assets/images/structure/apple-store.svg";
import section6ImgMobile from "../../../assets/images/structure/section6-img.png";
import { useTranslation } from "react-i18next";
import { Flip } from 'react-reveal';



const MobileApp = () => {
    const { t } = useTranslation();

    return (
        <>
            <section className="home-section6">
                <div className="container-1110">
                    <Grid container columnSpacing={"0px"}>
                        <Grid item xs={12} sm={12} md={7}>
                        <Flip bottom>
                            <h2 className="h2">
                                {t('allThisFromTheConvenienceOfYourPhone')}
                            </h2>
                            </Flip>
                            <p className="p2">{t('downloadTheBaladiMobileApp')}</p>
                            <a
                                className="stores-img"
                                href="https://play.google.com/store/apps/details?id=baladi.customer.app.baladi_customer_app"
                                target="_blank"
                            >
                                <img src={playStore} alt="playStore" />
                            </a>
                            <a
                                className="stores-img"
                                href="https://apps.apple.com/qa/app/baladiexpress/id1635029670"
                                target="_blank"
                            >
                                <img src={appleStore} alt="appleStore" />
                            </a>
                        </Grid>
                        <Grid item xs={12} sm={12} md={5}>
                            <img
                                src={section6ImgMobile}
                                alt="..."
                                className="section-6-img"
                            />
                        </Grid>
                    </Grid>
                </div>
            </section>
        </>
    );
};

export default MobileApp;
