import { useTranslation } from "react-i18next";
import { getDateAndTimeFromTimestamp } from "../../Helpers/HelperFunctions";

const OrderLogs = ({ status, orderLogs }) => {

  const { t } = useTranslation();

  return (
    <div className='order-tracking-block'>
      <div className={`order-tracking-list ${orderLogs?.find(log => log.status === 'placed') ? 'success' : ''}`}>
        <small className='dot-circle'></small>
        <span className='p2'>{t('order_placed')}</span>
        {orderLogs?.find(log => log.status === 'placed') ?
          <span className='p3'>
            {getDateAndTimeFromTimestamp(orderLogs?.find(log => log.status === 'placed')?.updated_at)}
          </span> : null}
      </div>
      {status === 'cancelled' ? <>
        <div className={`order-tracking-list ${orderLogs?.find(log => log.status === 'cancelled') ? 'success' : ''}`}>
          <small className='dot-circle'></small>
          <span className='p2'>{t('order_cancelled')}</span>
          {orderLogs?.find(log => log.status === 'cancelled') ?
            <span className='p3'>
              {getDateAndTimeFromTimestamp(orderLogs?.find(log => log.status === 'cancelled')?.updated_at)}
            </span> : null}
        </div>
      </> : null}
      {status === 'rejected' ? <>
        <div className={`order-tracking-list ${orderLogs?.find(log => log.status === 'rejected') ? 'success' : ''}`}>
          <small className='dot-circle'></small>
          <span className='p2'>{t('order_rejected')}</span>
          {orderLogs?.find(log => log.status === 'rejected') ?
            <span className='p3'>
              {getDateAndTimeFromTimestamp(orderLogs?.find(log => log.status === 'rejected')?.updated_at)}
            </span> : null}
        </div>
      </> : null}
      {status !== 'cancelled' && status !== 'rejected' ? <>
        <div className={`order-tracking-list ${orderLogs?.find(log => log.status === 'processing') ? 'success' : ''}`}>
          <small className='dot-circle'></small>
          <span className='p2'>{t('order_processing')}</span>
          {orderLogs?.find(log => log.status === 'processing') ?
            <span className='p3'>
              {getDateAndTimeFromTimestamp(orderLogs?.find(log => log.status === 'processing')?.updated_at)}
            </span> : null}
        </div>
        <div className={`order-tracking-list ${orderLogs?.find(log => log.status === 'shipped') ? 'success' : ''}`}>
          <small className='dot-circle'></small>
          <span className='p2'>{t('order_Dispatched')}</span>
          {orderLogs?.find(log => log.status === 'shipped') ?
            <span className='p3'>
              {getDateAndTimeFromTimestamp(orderLogs?.find(log => log.status === 'shipped')?.updated_at)}
            </span> : null}
        </div>
        <div className={`order-tracking-list ${orderLogs?.find(log => log.status === 'completed') ? 'success' : ''}`}>
          <small className='dot-circle'></small>
          <span className='p2'>{t('order_Delivered')}</span>
          {orderLogs?.find(log => log.status === 'completed') ?
            <span className='p3'>
              {getDateAndTimeFromTimestamp(orderLogs?.find(log => log.status === 'completed')?.updated_at)}
            </span> : null}
        </div>
      </> : null}
    </div>
  );
}

export default OrderLogs;