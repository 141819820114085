/**
 * @file Geocode.js
 * @description Geocode helper to get address from lat and lng
 * @import Geocode from "react-geocode";
 * @import { axiosConstant } from "./Axios/Constants";
 * @param {number} lat
 * @param {number} lng
 * @returns {string} address - address from lat and lng
 */

import Geocode from "react-geocode";
import { axiosConstant } from "./Axios/Constants";
import {getRequest} from "./Axios/Services";
import {API_ROUTES} from "./Axios/ApiRoutes";
Geocode.setApiKey(axiosConstant.GOOGLE_GEOCODE_API_KEY);


export const getAdressFromLatLng = async (lat, lng) => {

  const response = await getRequest(API_ROUTES.GOOGLE_MAPS_API.GET_LAT_LNG_FROM_ADDRESS(lat, lng));
  // const response = await Geocode.fromLatLng(lat, lng);
  // const result = response.data.results
  return response.data.formatted_address

  // const filteredAddress = result.filter((address) => !address.types.includes("plus_code") )
  // const routeAddres = filteredAddress.find((address) => address.types.includes('route'));
  // if (routeAddres) {
  //   return routeAddres.formatted_address;
  // } 
  // const administrativeAreaLevel2Address = filteredAddress.find((address) => address.types.includes('administrative_area_level_2'));
  // if (administrativeAreaLevel2Address) {
  //   return administrativeAreaLevel2Address.formatted_address;
  // }
  // const premiseAddress = filteredAddress.find((address) => address.types.includes('premise'));
  // if (premiseAddress) {
  //   return premiseAddress.formatted_address;
  // }
  // const address = filteredAddress[0].formatted_address;
  // return address;
};