import "./storeDetails.scss"
import { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import infoGray from "../../../assets/images/structure/info-gray.webp"
import defaultImage from "../../../assets/images/structure/default.jpeg";
import starGreen from "../../../assets/images/structure/star-green.svg"
import searchBlack from "../../../assets/images/structure/search-black.svg";
import { Boxed } from "../../../Styles-Elements/Box";
import { useTranslation } from "react-i18next";
import { axiosConstant } from '../../../Helpers/Axios/Constants';
import { TextField } from "@mui/material";
import { arrangeDays, getRatingToDecimal, getReviewCount, getDistanceFromLatLonInKm } from "../../../Helpers/HelperFunctions";
import { currentAddress } from '../../../redux/reducers/address';
import Image from "../../../global-modules/Image/Image";
import { currentBaladiData } from "redux/reducers/baladiData";

const StoreDetails = ({ storeDetails, searchData, searchQuery }) => {
  const { t } = useTranslation();
  const userAddress = useSelector(currentAddress)
  const baladiInfo = useSelector(currentBaladiData);
  let [search, setSearch] = useState('');
  let [workingHours, setWorkingHours] = useState([]);
  let [searchAble, setSearchAble] = useState(false);

  const handleSearch = (e) => {
    search = e.target.value;
    setSearch(search)
    setSearchAble(true);
  }

  const calculateMaridian = (time) => {
    const splitTime = time?.split(':');
    if (splitTime) {
      let hour = splitTime[0];
      let maridian = 'AM';
      if (hour > 12) {
        hour -= 12;
        maridian = 'PM';
      }
      return `${hour?.toString().padStart(2, '0')}:${splitTime[1]?.toString().padStart(2, '0')} ${maridian}`;
    }
  }

  const calcutapeStartEndTime = (start, end) => {
    let startTime = calculateMaridian(start);
    let endTime = calculateMaridian(end);
    const calculatedTime = (startTime) ? `${startTime} - ${endTime}` : '';
    return calculatedTime;
  }

  const handleArrangeDays = () => {
    if (storeDetails?.manage_working_hours.length > 0) {
      const arrangedDays = arrangeDays(storeDetails?.manage_working_hours);
      workingHours = arrangedDays;
      setWorkingHours(workingHours)
    }
  }

  const getCurrentStatus = () => {
    const d = new Date();
    const currentDay = axiosConstant.DAYS[d.getDay()];
    const thisDay = workingHours?.find(working_hour => working_hour?.day === currentDay);
    let thisDayTime = calcutapeStartEndTime(thisDay?.starttime, thisDay?.endtime);
    if (thisDay?.timejson !== '' && thisDay?.timejson !== undefined) {
      const timejson = JSON.parse(thisDay?.timejson);
      const firstSlot = timejson[0];
      const lastSlot = timejson[timejson.length - 1];
      thisDayTime = calcutapeStartEndTime(firstSlot?.starttime, lastSlot?.endtime);

      thisDayTime = timejson.map((timeWork, index) => {
        return calcutapeStartEndTime(timeWork?.starttime, timeWork?.endtime);
      }).join(', ');

    }
    return thisDayTime;
  }

  useEffect(() => {
    if (searchAble) {
      const getData = setTimeout(() => {
        searchData(search)
      }, 500);
      return () => clearTimeout(getData);
    }
  }, [search]);

  useEffect(() => {
    handleArrangeDays();
    search = searchQuery;
    setSearch(search)
  }, [storeDetails])

  return (
    storeDetails ? <div>
      <Boxed
        display={"flex"}
        alignItems={"flex-start"}
        justifyContent={"space-between"}
        className="store-details-wrapper"
      >
        <Boxed display={"flex"} alignItems={"flex-start"} className="left-wrap">
          <div className="store-logo-box">
            <Image src={storeDetails?.business_logo !== '' ?
              axiosConstant.IMAGE_BASE_URL(storeDetails?.business_logo) : defaultImage}
              className="str-logo" alt="logo" />
          </div>
          <div className="content-box" style={{
            position: 'relative',
          }}>
            <h3 className="h3 detail-chips-parent">
              <span className="ml-1">{storeDetails?.stores_locales[0].business_name}</span>
              <div className="chips-row">
                {storeDetails?.offers?.map((offer, index) =>
                  <span className="chips" key={`offerstoreDetails-${index}`} >{offer.name}</span>)}
              </div>
            </h3>
            {storeDetails?.id !== baladiInfo.BALADI_STORE_Id ? <p className="p1">
              <span>
                {storeDetails?.business_address && storeDetails?.business_address !== '' ? storeDetails?.business_address :
                  `${storeDetails?.street_number}, ${storeDetails?.building_number},${storeDetails?.zone_number}, ${storeDetails?.country}`
                }
              </span>
            </p> : <p className="p1 open-now">{t('storefront_Open24X7')} </p>}
            {storeDetails?.id !== baladiInfo.BALADI_STORE_Id ?
              storeDetails?.open247 !== baladiInfo.BALADI_STORE_Id ? <Boxed display={"flex"} alignItems={"center"}>
                <span className="open-now">{storeDetails?.online_status === 1 ? t("storefront_openNow") + ":" : t("storefront_closed")}</span>
                {storeDetails?.online_status === 1 && storeDetails ? <span className="p2 truncate-time">{getCurrentStatus()}</span> : null}
                {storeDetails?.online_status === 1 && workingHours?.length > 0 ? <div className="info-box">
                  <img src={infoGray} className="info-icon" alt="info" />
                  <div className="info-content">
                    {workingHours?.map((working_hour, index) =>
                      <div className="hour-row" key={`working_hour_${index}`}>
                        <span className="p3" style={{ textTransform: 'capitalize' }}>{working_hour?.day}</span>
                        {working_hour?.open ?
                          <span className="p3 bold">
                            {working_hour?.timejson !== '' ?
                              JSON.parse(working_hour?.timejson).map((timeWork, index) =>
                                <p className="mt-2" key={`hour-${index}`}>{calcutapeStartEndTime(timeWork?.starttime, timeWork?.endtime)}</p>
                              ) :
                              <p className="mt-2">{calcutapeStartEndTime(working_hour?.starttime, working_hour?.endtime)}</p>
                            }
                          </span> : <span className="p3 bold">{t("storefront_closed")}</span>}
                      </div>
                    )}
                  </div>
                </div> : null}
              </Boxed> :
                <p className="p1 open-now">{storeDetails?.online_status === 1 ? t("storefront_Open247") : t("storefront_closed")} </p>
              : null}
            <div className="">
              <div className="searches-block">
                <img src={searchBlack} className="searches-icon" alt="search" />
                <TextField id="outlined-basic" sx={{ width: '100%', backgroundColor: '#fff', }}
                  className="searches-input" variant="outlined"
                  placeholder={t('searchByName')}
                  value={search}
                  type={'search'}
                  onChange={handleSearch}
                />
              </div>
            </div>
          </div>
        </Boxed >
        <Boxed display={"flex"} alignItems={"center"} className={`right-wrap ${storeDetails?.id !== baladiInfo.BALADI_STORE_Id ? '' : 'no-event'}`}>
          {storeDetails?.avgRating ?
            <Link to={`/rating-review/${storeDetails?.id}`} className="text-center detailside" style={{ textDecoration: 'none' }}>
              <Boxed display={"flex"} alignItems={"center"} justifyContent={"center"}>
                <img src={starGreen} alt="star-green" />
                <span className="p1">{storeDetails?.id !== baladiInfo.BALADI_STORE_Id ? getRatingToDecimal(storeDetails?.avgRating) : getRatingToDecimal(5)}</span>
              </Boxed>
              {storeDetails?.id !== baladiInfo.BALADI_STORE_Id ? <p className="p2">{getReviewCount(storeDetails?.countOfratings)} {t("storefront_ratings")}</p> : null}
            </Link>
            : null}
          {storeDetails?.id !== baladiInfo.BALADI_STORE_Id ? <div className="text-center detailside">
            <Boxed>
              {/* <span className="p1">26 {t("storefront_mins")}</span> */}
              <span className="p1">
                {getDistanceFromLatLonInKm(userAddress?.latitude, userAddress?.longitude, storeDetails?.latitude, storeDetails?.longitude)} {t('kmAway')}
              </span>
            </Boxed>
            <p className="p2">{t("storefront_deliveryDistance")}</p>
          </div> : null}
        </Boxed>
      </Boxed>
    </div> : null
  );
};

export default StoreDetails;