/** 
 * @file AddressList.js
 * @description Address List Dialog Box
 * @exports AddressList - Dialog Box
 * @exports handleClose - Function to close the dialog box
 * @exports openAddAddress - Function to open add address dialog box
*/
import { useState, useEffect, useCallback } from "react";
import { Dialog, DialogContent, Button } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import locationIcon from "../../assets/images/structure/location-black.svg";
import modalClose from "../../assets/images/structure/close-black.svg";
import { useTranslation } from "react-i18next";
import { currentAddress, changeAddress } from '../../redux/reducers/address';
import { getRequest } from '../../Helpers/Axios/Services';
import { API_ROUTES } from '../../Helpers/Axios/ApiRoutes';
import { getLocalStorage, setLocalStorage, LOCAL_STORAGE_KEYS } from "../../Helpers/crypto/LocalStorage";
import { changeLoader } from "../../redux/reducers/loader";
import { ampli } from "../../ampli";
import jwt_decode from "jwt-decode";

const AddressList = ({ handleClose, openAddAddress }) => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userAddress = useSelector(currentAddress)
  let [addressList, setAddressList] = useState([]);
  let addressDefault = useSelector(currentAddress);

  const getAddresses = useCallback(async () => {
    dispatch(changeLoader(true));
    const response = await getRequest(API_ROUTES.ADDRESS.GET_ADDRESS);
    if (response.ack === 1) {
      addressList = response.addresses;
      setAddressList(addressList);
      dispatch(changeLoader(false));
      if (addressList.length === 0) {
        openAddAddress();
      }
    } else {
      dispatch(changeLoader(false));
    }
  })

  const handleAddressChange = (address_id) => {
    const selectedAddress = addressList.find((item) => item.id === address_id);
    setLocalStorage(LOCAL_STORAGE_KEYS.TOTAL_ADDRESS, addressList.length);
    setLocalStorage(LOCAL_STORAGE_KEYS.DEFAULT_ADDRESS, JSON.stringify(selectedAddress));
    dispatch(changeAddress());
    // ampli
    let userID = '';
    if (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) {
      const token = getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
      let decoded = jwt_decode(token);
      userID = decoded?.id;
    }
    ampli.track({
      event_type: 'LocationChanged',
      event_properties: {
        category: 'Docs',
        name: 'SDK Library',
        description: 'SDK Library Description',
        user_id: userID,
        address: selectedAddress,
        latitude: addressDefault?.latitude,
        longitude: addressDefault?.longitude,
      },
    });
    handleClose();
  }

  useEffect(() => {
    getAddresses()
  }, [])


  return addressList?.length > 0 ? (
    <Dialog open={true} onClose={handleClose} className="main-modal maxwidth-576">
      <img src={modalClose} alt="close" onClick={handleClose} className="modal-close" />
      <DialogContent>
        <form action="#" method="post">
          <h5 className="h5" style={{ fontSize: "14px", marginBottom: "20px", }}>
            {t("cart_ChooseYourLocation")}
          </h5>
          <h5 className="h5" style={{ fontSize: "14px", marginBottom: "20px", marginTop: "0px" }}>
            {t("cart_SavedAddress")}
          </h5>
          <div className="overflow-200">
            {addressList.map((address) => (
              <div
                key={`address-${address.id}`}
                className={`address-row cursor-pointer ${userAddress?.id === address?.id ? "activeAddress" : ""
                  }`}
                onClick={() => {
                  handleAddressChange(address.id);
                }}
              >
                <img src={locationIcon} alt="..." className="icon24" />
                <p className="p2 bold">{address.title}</p>
                <p className="p2 ">
                  {address?.business_address && address?.business_address !== ""
                    ? `${address?.apartment_number} ${address?.building_number} ${address?.street_number} ${address?.zone_number} ${address?.business_address}`
                    : `${address?.zone_number}, ${address?.building_number}, ${address?.street_number}, ${address?.country}`}
                </p>
              </div>
            ))}
          </div>
          <Button
            className="MuiButton-outlinedPrimary"
            size="small"
            style={{ marginTop: "20px", width: "140px" }}
            onClick={openAddAddress}
          >
            {t("cart_AddNewAddress")}
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  ) : null;
};

export default AddressList;
