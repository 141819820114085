import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate, useSearchParams } from "react-router-dom";
import { getSessionStorage, SESSION_STORAGE_KEYS } from "../Helpers/crypto/SessionStorage";
import { setLocalStorage, LOCAL_STORAGE_KEYS } from "../Helpers/crypto/LocalStorage";
import { postRequest, getRequest } from "../Helpers/Axios/Services";
import { API_ROUTES } from "../Helpers/Axios/ApiRoutes";
import { changeSnackbar, currentSnackbar } from "../redux/reducers/snackbar";

const WalletPaymentRedirect = () => {

  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const snackbar = useSelector(currentSnackbar);

  const handleCheckPayment = async () => {
    const response = await getRequest(API_ROUTES.AUTHENTICATION.REFRESH_TOKEN)
    if (response.ack === 1) {
      setLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN, response.data);
    }
    if (searchParams.get('trctn')) {
      const data = { trctn: searchParams.get('trctn') };
      const response = await postRequest(API_ROUTES.WALLET.CHECK_PAYMENT_STATUS, data)
      if (response.ack === 1) {
        if (getSessionStorage(SESSION_STORAGE_KEYS.RETURN_TO_CHECKOUT)) {
          sessionStorage.removeItem(SESSION_STORAGE_KEYS.RETURN_TO_CHECKOUT);
          navigate(`/checkout`);
        } else {
          navigate(`/wallet`);
        }
      } else {
        sessionStorage.removeItem(SESSION_STORAGE_KEYS.TRANSACTION_ID);
        let message = response.msg;
        if (response?.msg === undefined) {
          message = 'Payment failed. Please try again.'
        }
        dispatch(changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: message,
          state: "error",
        }));
        navigate(`/wallet`);
      }
    } else {
      navigate(`/`);
    }
  };

  useEffect(() => {
    handleCheckPayment();
  }, []);

  return (
    <div className="thankyou-content">
      <CircularProgress size={100} />
      <h3 className="h3">{t('wallet_checkPaymentConnection')}</h3>
    </div>
  );
};

export default WalletPaymentRedirect;
