import encryptDcrypt from './encryptDcrypt';

/**
 * @file SessionStorage.js
 * @description Constant for session storage keys
 * @returns session storage keys
 */
export const SESSION_STORAGE_KEYS = {
  CARD_TOKEN: '_xzbqnmfmje',
  CARD_NUMBER: '_qrnpwauowg',
  ORDER_ID: '_gbrlzqyulj',
  TRANSACTION_ID: '_oypyioqygo',
  RETURN_TO_CHECKOUT: '_qjxqjxqjxq',
};

/**
 * @description This function is used to set the session storage
 * @param {*} key 
 * @param {*} value 
 */
export const setSessionStorage = (key, value) => {
  sessionStorage.setItem(key, encryptDcrypt(value));
}

/**
 * @description This function is used to get the session storage
 * @param {*} key
 * @returns session storage value
 * @default null
 */
export const getSessionStorage = (key) => {
  if (sessionStorage.getItem(key)) {
    return encryptDcrypt(sessionStorage.getItem(key), 'de');
  }
}

