/**
 * @file VendorMisMatch.js
 * @description Vendor MisMatch Dialog Box
 * @exports VendorMisMatch - Dialog Box
 * @exports CloseDialog - Function to close the dialog box
 * @exports handleChange - Function to remove old cart product and add the new product of different vendor
 */

import { Grid, Button, Dialog, DialogContent } from '@mui/material';
import { useTranslation } from "react-i18next";

const VendorMisMatch = ({ CloseDialog, handleChange }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={true} onClose={CloseDialog} className="main-modal theme-dialog">
      <DialogContent>
        <h2 className='h3 text-center' style={{ marginBottom: '8px' }}>{t('cart_item_exists')}</h2>
        <p className='p3 text-center' style={{ marginBottom: '12px', fontSize: '14px' }}>{t('cart_item_exists_of_vendor')}</p>
        <Grid item xs={12} textAlign="center" display={'flex'} justifyContent={'flex-end'}>
          <Button type='button' size="medium" variant="text" color="primary" onClick={handleChange}>{t('cart_yes_refresh')}</Button>
          <Button type='button' size="medium" variant="text" color="error" onClick={CloseDialog}>{t('cart_No')}</Button>
        </Grid>
      </DialogContent>
    </Dialog>
  )
};

export default VendorMisMatch;