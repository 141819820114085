/**
 * @file Redux Store
 * @description This file contains the redux store configuration
 * @exports {Object} store
 */
import { configureStore } from '@reduxjs/toolkit'
import activeLink from './reducers/activeLink'
import loader from './reducers/loader'
import snackbar from './reducers/snackbar'
import addressStore from './reducers/address'
import cart from './reducers/cart'
import wishlist from './reducers/wishlist'
import baladiData from './reducers/baladiData'

export default configureStore({
  reducer: {
    loader: loader,
    snackbar: snackbar,
    activeLink: activeLink,
    addressStore: addressStore,
    cart: cart,
    wishlist: wishlist,
    baladiData: baladiData,
  },
})
