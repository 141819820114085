import pageNotFound from "../../assets/images/structure/page-not-found.webp";

const PageNotFound = () => {
  return (
    <div className="page-not-found">
      <img src={pageNotFound} alt="404" className="image404" />
    </div>
  );
}

export default PageNotFound;