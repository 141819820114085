import "./MyProfile.scss";
import { useState, useEffect } from 'react';
import { Grid, Button, Divider, TextField, Collapse, IconButton, InputAdornment } from '@mui/material';
import { getLocalStorage, setLocalStorage, LOCAL_STORAGE_KEYS } from '../../Helpers/crypto/LocalStorage';
import { postRequest, getRequest } from '../../Helpers/Axios/Services'
import { API_ROUTES } from '../../Helpers/Axios/ApiRoutes'
import { useDispatch, useSelector } from "react-redux";
import { changeSnackbar, currentSnackbar } from "../../redux/reducers/snackbar";
import { PhoneMask, otpMask } from '../../global-modules/MaskInputs/MaskedInputs';
import { useTranslation } from "react-i18next";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import jwt_decode from "jwt-decode";
import Sidebar from '../layout/sidebar/Sidebar';
import editIcon from "../../assets/images/structure/edit-icon.svg";

const MyProfile = () => {
  const { t } = useTranslation();
  const [expandedName, setExpandedName] = useState(false);
  const [expandedEmail, setExpandedEmail] = useState(false);
  const [expandedPhone, setExpandedPhone] = useState(false);
  let [userDetails, setUserDetails] = useState(null);
  let [userFullname, setUserFullname] = useState('');
  let [userFullnameError, setUserFullnameError] = useState('');
  let [userEmail, setUserEmail] = useState('');
  let [emailError, setEmailError] = useState('');
  let [emailOtp, setEmailOtp] = useState('');
  let [showGetOldOTPE, setGetOldOTPE] = useState(false);
  let [EmailOtpText, setEmailOtpText] = useState(t('getOTP'));
  let [isEmailOtp, setIsEmailOtp] = useState(false);
  let [isEmailValid, setIsEmailOtpValid] = useState(false);
  let [timeLeft, setTimeLeft] = useState(31);
  const [isTimer, setIsTimer] = useState(false);
  let [userEmail2, setUserEmail2] = useState('');
  let [emailError2, setEmailError2] = useState('');
  let [emailOtp2, setEmailOtp2] = useState('');
  let [showGetOldOTPE2, setGetOldOTPE2] = useState(false);
  let [EmailOtpText2, setEmailOtpText2] = useState(t('getOTP'));
  let [isEmailOtp2, setIsEmailOtp2] = useState(false);
  let [isEmailValid2, setIsEmailOtpValid2] = useState(false);
  let [timeLeft2, setTimeLeft2] = useState(31);
  const [isTimer2, setIsTimer2] = useState(false);
  let [timeLeftPhone, setTimeLeftPhone] = useState(31);
  let [userContactNumber, setUserContactNumber] = useState('');
  let [userCountryCode, setUserCountryCode] = useState('');
  let [userCountryCode2, setUserCountryCode2] = useState('');
  let [phoneError, setPhoneError] = useState('');
  let [phoneOtp, setPhoneOtp] = useState('');
  let [isPhoneOtp, setisPhoneOtp] = useState(false);
  let [isPhoneValid, setisPhoneOtpValid] = useState(false);
  let [showGetOldOTP, setGetOldOTP] = useState(false);
  let [PhoneOtpText, setPhoneOtpText] = useState(t('getOTP'));
  let [timeLeftPhone2, setTimeLeftPhone2] = useState(31);
  let [userContactNumber2, setUserContactNumber2] = useState('');
  let [phoneError2, setPhoneError2] = useState('');
  let [phoneOtp2, setPhoneOtp2] = useState('');
  let [isPhoneOtp2, setisPhoneOtp2] = useState(false);
  let [isPhoneValid2, setisPhoneOtpValid2] = useState(false);
  let [showGetOldOTP2, setGetOldOTP2] = useState(false);
  let [PhoneOtpText2, setPhoneOtpText2] = useState(t('getOTP'));

  const dispatch = useDispatch();
  const snackbar = useSelector(currentSnackbar);

  const handleExpandClickName = () => {
    setExpandedName(!expandedName);
  };
  const handleExpandClickEmail = () => {
    setExpandedEmail(!expandedEmail);
  };
  const handleExpandClickPhone = () => {
    setExpandedPhone(!expandedPhone);
  };

  const handleVerifyOtp = async (type) => {

    if (type === 'email') {
      let isValid = true;
      setEmailError('');
      if (emailOtp === '' || emailOtp.length !== 4) {
        setEmailError(t('OtpIsRequired'))
        isValid = false;
      }
      if (isValid) {
        const response = await postRequest(API_ROUTES.AUTHENTICATION.VERIFY_API_FOR_EMAIL, {
          email: userEmail,
          otp: emailOtp
        });
        if (response.ack === 1) {
          dispatch(changeSnackbar({
            ...snackbar,
            isOpen: true,
            message: response.msg,
            state: "success",
          }));
          setIsEmailOtp(false)
          setGetOldOTPE(false)
          setIsEmailOtpValid(true);
        } else {
          dispatch(changeSnackbar({
            ...snackbar,
            isOpen: true,
            message: response.msg,
            state: "error",
          }));
        }
      }
    }

    if (type === 'email2') {
      let isValid = true;
      setEmailError2('');
      if (userEmail2 !== '' && !validateEmail(userEmail2)) {
        setEmailError2(t('EmailInvalid'))
        isValid = false;
      }
      if (isValid) {
        const response2 = await postRequest(API_ROUTES.AUTHENTICATION.UPDATE_PROFILE, {
          email: userEmail2
        })
        if (response2.ack === 1) {
          dispatch(changeSnackbar({
            ...snackbar,
            isOpen: true,
            message: response2.msg,
            state: "success",
          }));
          setLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN, response2.token);
          getUserDetails();
          resetChangeEmail();
          setExpandedEmail(!expandedEmail);
        } else {
          dispatch(changeSnackbar({
            ...snackbar,
            isOpen: true,
            message: response2.msg,
            state: "error",
          }));
        }
      }
    }

    if (type === 'phone') {
      let isValid = true;
      setPhoneError('');
      if (userContactNumber === '') {
        setPhoneError(t('phoneInvalid'))
        isValid = false;
      }
      if (phoneOtp === '' || phoneOtp.length !== 4) {
        setEmailError(t('OtpIsRequired'))
        isValid = false;
      }
      if (isValid) {
        const response = await postRequest(API_ROUTES.AUTHENTICATION.VERIFY_API_FOR_OLD_MOBILE, {
          contact_number: userContactNumber,
          country_code: userCountryCode,
          otp: phoneOtp
        });
        if (response.ack === 1) {
          dispatch(changeSnackbar({
            ...snackbar,
            isOpen: true,
            message: response.msg,
            state: "success",
          }));
          setisPhoneOtp(false)
          setGetOldOTP(false)
          setisPhoneOtpValid(true);
        } else {
          dispatch(changeSnackbar({
            ...snackbar,
            isOpen: true,
            message: response.msg,
            state: "error",
          }));
        }
      }
    }

    if (type === 'phone2') {
      let isValid = true;
      setPhoneError2('');
      if (userContactNumber2 === '') {
        setPhoneError2(t('phoneInvalid'))
        isValid = false;
      }
      if (phoneOtp2 === '' || phoneOtp2.length !== 4) {
        setPhoneError2(t('OtpIsRequired'))
        isValid = false;
      }
      if (isValid) {
        const response = await postRequest(API_ROUTES.AUTHENTICATION.VERIFY_API_FOR_OLD_MOBILE, {
          contact_number: userContactNumber2.slice(userCountryCode2.length),
          country_code: userCountryCode2,
          otp: phoneOtp2
        });
        if (response.ack === 1) {
          dispatch(changeSnackbar({
            ...snackbar,
            isOpen: true,
            message: response.msg,
            state: "success",
          }));
          setisPhoneOtp2(false)
          setGetOldOTP2(false)
          setisPhoneOtpValid2(true);
          const response2 = await postRequest(API_ROUTES.AUTHENTICATION.UPDATE_PROFILE, {
            contact_number: userContactNumber2.slice(userCountryCode2.length),
            country_code: userCountryCode2
          })
          if (response2.ack === 1) {
            dispatch(changeSnackbar({
              ...snackbar,
              isOpen: true,
              message: response2.msg,
              state: "success",
            }));
            setLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN, response2.token);
            getUserDetails();
            resetChangePhone();
            setExpandedPhone(!expandedPhone);
          } else {
            dispatch(changeSnackbar({
              ...snackbar,
              isOpen: true,
              message: response2.msg,
              state: "error",
            }));
          }
        } else {
          dispatch(changeSnackbar({
            ...snackbar,
            isOpen: true,
            message: response.msg,
            state: "error",
          }));
        }
      }
    }
  }

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  };

  const resetChangePhone = () => {
    setPhoneError('');
    setPhoneOtp('');
    setisPhoneOtp(false);
    setisPhoneOtpValid(false);
    setGetOldOTP(false);
    setPhoneOtpText(t('getOTP'));
    setUserContactNumber2('');
    setPhoneError2('');
    setPhoneOtp2('');
    setisPhoneOtp2(false);
    setisPhoneOtpValid2(false);
    setGetOldOTP2(false);
    setPhoneOtpText2(t('getOTP'));
  }

  const resetChangeEmail = () => {
    setEmailError('');
    setEmailOtp('');
    setIsEmailOtp(false);
    setIsEmailOtpValid(false);
    setGetOldOTPE(false);
    setEmailOtpText(t('getOTP'));
    setUserEmail2('');
    setEmailError2('');
    setEmailOtp2('');
    setGetOldOTPE2(false);
    setIsEmailOtpValid2(false)
    setIsEmailOtp2(false);
    setEmailOtpText2(t('getOTP'));
  }

  const getUserFormDetails = () => {
    userFullname = userDetails?.full_name;
    setUserFullname(userFullname)
    userEmail = userDetails?.email;
    setUserEmail2(userEmail)
    if (userEmail === '') { setIsEmailOtpValid(true); }
    userContactNumber = userDetails?.contact_number?.toString();
    setUserContactNumber(userContactNumber)
    userCountryCode = userDetails?.country_code;
    setUserCountryCode(userCountryCode)
  }

  const getUserDetails = async () => {
    const response = await getRequest(API_ROUTES.AUTHENTICATION.REFRESH_TOKEN)
    if (response.ack === 1) {
      setLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN, response.data);
    }
    const token = getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
    let decoded = jwt_decode(token);
    userDetails = decoded;
    setUserDetails(userDetails);
    getUserFormDetails();
  }

  const updateTimerPhone = () => {
    if (timeLeftPhone > 1) {
      timeLeftPhone--;
      setTimeLeftPhone(timeLeftPhone)
      setTimeout(() => { updateTimerPhone(); }, 1000);
    } else {
      setGetOldOTP(false)
    }
  }

  const updateTimerPhone2 = () => {
    if (timeLeftPhone2 > 1) {
      timeLeftPhone2--;
      setTimeLeftPhone2(timeLeftPhone2)
      setTimeout(() => { updateTimerPhone2(); }, 1000);
    } else {
      setGetOldOTP2(false)
    }
  }

  const updateTimerEmail = () => {
    if (timeLeft > 1) {
      timeLeft--;
      setTimeLeft(timeLeft)
      setTimeout(() => { updateTimerEmail(); }, 1000);
    } else {
      setGetOldOTPE(false)
    }
  }

  const updateTimerEmail2 = () => {
    if (timeLeft2 > 1) {
      timeLeft2--;
      setTimeLeft2(timeLeft2)
      setTimeout(() => { updateTimerEmail2(); }, 1000);
    } else {
      setGetOldOTPE2(false)
    }
  }

  const getOtpForOld = async (type) => {
    if (type === 'email') {
      let isValid = true;
      setEmailError('');
      if (!validateEmail(userEmail)) {
        setEmailError(t('EmailInvalid'))
        isValid = false;
      }
      if (isValid) {
        const response = await postRequest(API_ROUTES.AUTHENTICATION.SEND_API_FOR_OLD_EMAIL, { email: userEmail });
        if (response.ack === 1) {
          setEmailOtp('');
          setEmailOtpText(t('resendOTP'))
          timeLeft = 31;
          setTimeLeft(timeLeft);
          setIsTimer(true);
          setIsEmailOtp(true);
          updateTimerEmail();
          setGetOldOTPE(true)
        } else {
          dispatch(changeSnackbar({
            ...snackbar,
            isOpen: true,
            message: response.msg,
            state: "error",
          }));
        }
      }
    }
    if (type === 'email2') {
      let isValid = true;
      setEmailError2('');
      if (!validateEmail(userEmail2)) {
        setEmailError2(t('EmailInvalid'))
        isValid = false;
      }
      if (isValid) {
        const response = await postRequest(API_ROUTES.AUTHENTICATION.SEND_API_FOR_NEW_EMAIL, { email: userEmail2 });
        if (response.ack === 1) {
          setEmailOtp2('');
          setEmailOtpText2(t('resendOTP'))
          timeLeft2 = 31;
          setTimeLeft2(timeLeft2);
          setIsTimer2(true);
          setIsEmailOtp2(true);
          updateTimerEmail2();
          setGetOldOTPE2(true)
        } else {
          if (response.msg === '') {
            response.errMsg.forEach(element => {
              for (const [key, value] of Object.entries(element)) {
                if (key === 'email') { setEmailError2(value); }
              }
            });
          } else {
            dispatch(changeSnackbar({
              ...snackbar,
              isOpen: true,
              message: response.msg,
              state: "error",
            }));
          }
        }
      }
    }
    if (type === 'phone') {
      let isValid = true;
      setPhoneError('');
      if (userContactNumber === '') {
        setPhoneError(t('phoneInvalid'))
        isValid = false;
      }
      if (isValid) {
        const response = await postRequest(API_ROUTES.AUTHENTICATION.SEND_API_FOR_OLD_MOBILE, {
          contact_number: userContactNumber,
          country_code: userCountryCode
        });
        if (response.ack === 1) {
          setPhoneOtpText(t('resendOTP'))
          timeLeftPhone = 31;
          setTimeLeftPhone(timeLeftPhone);
          setisPhoneOtp(true);
          updateTimerPhone();
          setGetOldOTP(true)
        } else {
          dispatch(changeSnackbar({
            ...snackbar,
            isOpen: true,
            message: response.msg,
            state: "error",
          }));
        }
      }
    }
    if (type === 'phone2') {
      let isValid = true;
      setPhoneError2('');
      if (userContactNumber2 === '') {
        setPhoneError2(t('phoneInvalid'))
        isValid = false;
      }
      if (isValid) {
        const response = await postRequest(API_ROUTES.AUTHENTICATION.SEND_API_FOR_NEW_MOBILE, {
          contact_number: userContactNumber2.slice(userCountryCode2.length),
          country_code: userCountryCode2
        });
        if (response.ack === 1) {
          setPhoneOtpText2(t('resendOTP'))
          timeLeftPhone2 = 31;
          setTimeLeftPhone2(timeLeftPhone2);
          setisPhoneOtp2(true);
          updateTimerPhone2();
          setGetOldOTP2(true)
        } else {
          if (response.msg === '') {
            response.errMsg.forEach(element => {
              for (const [key, value] of Object.entries(element)) {
                if (key === 'contact_number') { setPhoneError2(value); }
              }
            });
          } else {
            dispatch(changeSnackbar({
              ...snackbar,
              isOpen: true,
              message: response.msg,
              state: "error",
            }));
          }
        }
      }
    }
  }

  const handleNameSubmit = async (e) => {
    e.preventDefault();
    if (userFullname === '') {
      setUserFullnameError(t('fullNameRequired'));
    } else {
      setUserFullnameError('');
      const response = await postRequest(API_ROUTES.AUTHENTICATION.UPDATE_PROFILE, {
        full_name: userFullname
      })
      if (response.ack === 1) {
        dispatch(
          changeSnackbar({
            ...snackbar,
            isOpen: true,
            message: response.msg,
            state: "success",
          })
        );
        setLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN, response.token);
        getUserDetails();
        setExpandedName(!expandedName);
      } else {
        if (response?.msg && response?.msg !== '') {
          dispatch(changeSnackbar({
            ...snackbar,
            isOpen: true,
            message: response?.msg,
            state: "error",
          }));
        } else if (response?.errMsg) {
          response?.errMsg.forEach(element => {
            for (const [key, value] of Object.entries(element)) {
              if (key === 'full_name') { setUserFullnameError(value); }
            }
          });
        }
      }
    }
  }

  useEffect(() => {

    getUserDetails();
  }, [])

  return (
    <div>
      <div className="main-wrapper full-bg">
        <Sidebar />
        <div className="main-mid-wrapper">
          <div className="store-detail-wrapper">
            <div className="store-detail-head">
              <h3 className="h3">{t("myProfile")}</h3>
            </div>
            <Grid container columnSpacing={0}>
              <Grid item xs={12}>
                <div className="card-white">
                  <div className="business-detail-wrap">
                    <h6 className="h6">{t("personalDetails")}</h6>
                    <div className="collapse-input-row">
                      <div className="collapse-input-property">{t("fullName")}</div>
                      {!expandedName ? (
                        <div className="collapse-input-value">{userDetails?.full_name}</div>
                      ) : null}
                      <IconButton
                        expand={expandedName}
                        onClick={handleExpandClickName}
                        aria-expanded={expandedName}
                        aria-label="show more"
                        className="edit-icon"
                      >
                        <img src={editIcon} alt="edit-name" className="edit-icon" />
                      </IconButton>
                    </div>
                    <Collapse in={expandedName} timeout="auto" unmountOnExit>
                      <p className="p2 margin-direction">{t("Enteryournewfullname")} </p>
                      <form onSubmit={handleNameSubmit} action="#" method="post">
                        <Grid container columnSpacing={3}>
                          <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
                            <div className="form-group">
                              <TextField
                                label={t("fullName") + "*"}
                                inputProps={{
                                  maxLength: 100,
                                }}
                                onChange={(e) => {
                                  setUserFullname(e.target.value);
                                  setUserFullnameError("");
                                }}
                                value={userFullname}
                              />
                              <span className="errorspan">{userFullnameError}</span>
                            </div>
                            <div className="ltr-align-right">
                              <Button
                                type="button"
                                className="secondary-gray mx-3"
                                variant="contained"
                                size="large"
                                onClick={handleExpandClickName}
                              >
                                {t("cancel")}
                              </Button>
                              <Button type="submit" variant="contained" size="large">
                                {t("save")}
                              </Button>
                            </div>
                          </Grid>
                        </Grid>
                      </form>
                    </Collapse>
                    <Divider />
                    <div className="collapse-input-row">
                      <div className="collapse-input-property">{t("email")}</div>
                      {!expandedEmail ? (
                        <div className="collapse-input-value">
                          {userDetails?.email ? userDetails?.email : "-"}
                        </div>
                      ) : null}
                      <IconButton
                        expand={expandedEmail}
                        onClick={handleExpandClickEmail}
                        aria-expanded={expandedEmail}
                        aria-label="show more"
                        className="edit-icon"
                      >
                        <img src={editIcon} alt="edit-email" className="edit-icon" />
                      </IconButton>
                    </div>
                    <Collapse in={expandedEmail} timeout="auto" unmountOnExit>
                      <p className="p2 margin-direction">{t("Enteryournewemail_wellsendyou4digitOTP")} </p>
                      <Grid container columnSpacing={3}>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                          <div className="form-group">
                            <TextField
                              label={t("newemailAddress")}
                              onChange={(e) => { setUserEmail2(e.target.value); }}
                              value={userEmail2}
                            />
                            <span className="errorspan">{emailError2}</span>
                            <span
                              className={"redspan"}
                              onClick={() => { handleVerifyOtp("email2"); }}
                            > Save Email </span>
                          </div>
                        </Grid>
                      </Grid>
                    </Collapse>
                    <Divider />

                    <div className="collapse-input-row">
                      <div className="collapse-input-property">{t("mobile")}</div>
                      {!expandedPhone ? (
                        <div className="collapse-input-value always-lts">{`+${userDetails?.country_code}-${userDetails?.contact_number}`}</div>
                      ) : null}
                      <IconButton
                        expand={expandedPhone}
                        onClick={handleExpandClickPhone}
                        aria-expanded={expandedPhone}
                        aria-label="show more"
                        className="edit-icon"
                      >
                        <img src={editIcon} alt="edit-mobile" className="edit-icon" />
                      </IconButton>
                    </div>
                    <Collapse in={expandedPhone} timeout="auto" unmountOnExit>
                      <p className="p2 margin-direction">{t("Enteryournewmobilenumber_wellsendotptoverify")} </p>

                      <Grid container columnSpacing={3}>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                          <div className="form-group">
                            <TextField
                              label={t("oldmobileNumber")}
                              onChange={(e) => {
                                setUserContactNumber(e.target.value);
                              }}
                              id="outlined-start-adornment"
                              value={userContactNumber}
                              disabled={isPhoneValid}
                              InputProps={{
                                readOnly: true,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    +{userCountryCode}
                                  </InputAdornment>
                                )
                              }}
                            />
                            <span className="errorspan">{phoneError}</span>
                            {!showGetOldOTP && !isPhoneValid ? (
                              <span
                                className={"redspan"}
                                onClick={() => {
                                  getOtpForOld("phone");
                                }}
                              >
                                {PhoneOtpText}
                              </span>
                            ) : null}
                          </div>
                        </Grid>
                        {isPhoneOtp ? (
                          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                            <div className="form-group">
                              <TextField
                                label={t("otp")}
                                placeholder={t("enterOTP")}
                                id="outlined-start-adornment"
                                value={phoneOtp}
                                onChange={(e) => {
                                  setPhoneOtp(e.target.value);
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      {showGetOldOTP
                                        ? timeLeftPhone < 10
                                          ? `00:0${timeLeftPhone}`
                                          : `00:${timeLeftPhone}`
                                        : null}
                                    </InputAdornment>
                                  ),
                                  inputComponent: otpMask,
                                }}
                                mask="0000"
                              />
                              {phoneOtp.length === 4 ? (
                                <span
                                  className={"redspan"}
                                  onClick={() => {
                                    handleVerifyOtp("phone");
                                  }}
                                >
                                  {t("verifyOTP")}
                                </span>
                              ) : null}
                            </div>
                          </Grid>
                        ) : null}
                      </Grid>

                      {isPhoneValid ? (
                        <Grid container columnSpacing={3}>
                          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                            <div className="form-group">
                              <PhoneInput
                                className="phone-input"
                                country={'qa'}
                                value={userContactNumber2}
                                enableLongNumbers={true}
                                onKeyDown={(e) => {
                                  if ((userContactNumber2.length - userCountryCode2.length) >= 16 && e.keyCode !== 8) {
                                    e.preventDefault();
                                  }
                                }}
                                onChange={(value, data) => {
                                  setUserCountryCode2(data.dialCode);
                                  setUserContactNumber2(value);
                                }}
                              />
                              {/* <TextField
                                label={t("newmobileNumber")}
                                onChange={(e) => {
                                  setUserContactNumber2(e.target.value);
                                }}
                                id="outlined-start-adornment"
                                value={userContactNumber2}
                                disabled={isPhoneValid2}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      +{userCountryCode}
                                    </InputAdornment>
                                  ),
                                  inputComponent: PhoneMask,
                                }}
                                mask="00000000"
                              /> */}
                              <span className="errorspan">{phoneError2}</span>
                              {!showGetOldOTP2 &&
                                !isPhoneValid2 &&
                                userContactNumber2.length > 4 ? (
                                <span
                                  className={"redspan"}
                                  onClick={() => {
                                    getOtpForOld("phone2");
                                  }}
                                >
                                  {PhoneOtpText2}
                                </span>
                              ) : null}
                            </div>
                          </Grid>
                          {isPhoneOtp2 ? (
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                              <div className="form-group">
                                <TextField
                                  label={t("otp")}
                                  placeholder={t("enterOTP")}
                                  id="outlined-start-adornment"
                                  value={phoneOtp2}
                                  onChange={(e) => {
                                    setPhoneOtp2(e.target.value);
                                  }}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        {showGetOldOTP2
                                          ? timeLeftPhone2 < 10
                                            ? `00:0${timeLeftPhone2}`
                                            : `00:${timeLeftPhone2}`
                                          : null}
                                      </InputAdornment>
                                    ),
                                    inputComponent: otpMask,
                                  }}
                                  mask="0000"
                                />
                                {phoneOtp2.length === 4 ? (
                                  <span
                                    className={"redspan"}
                                    onClick={() => {
                                      handleVerifyOtp("phone2");
                                    }}
                                  >
                                    {t("verifyOTP")}
                                  </span>
                                ) : null}
                              </div>
                            </Grid>
                          ) : null}
                        </Grid>
                      ) : null}
                    </Collapse>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyProfile;
