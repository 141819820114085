/**
 * @file cart.js
 * @description This file contains the cart store configuration
 * @requires LOCAL_STORAGE_KEYS
 * @requires getLocalStorage
 * @requires JSON
 */

import { createSlice } from '@reduxjs/toolkit';
import { getLocalStorage, LOCAL_STORAGE_KEYS } from '../../Helpers/crypto/LocalStorage';
import { ampli } from "../../ampli";


const getCart = () => {
  if (getLocalStorage(LOCAL_STORAGE_KEYS.CART)) {
    const cartData = JSON.parse(getLocalStorage(LOCAL_STORAGE_KEYS.CART));
    return cartData;
  } else {
    return [];
  }
}

const localCartValue = () => {
  let cart = getCart();
  let cartData = (cart && cart?.data) ? cart?.data : [];
  if (cartData?.cart_items?.length > 0) {
    let cartItems = cartData?.cart_items?.map(item => {
      // total quantity if particular item
      let totalItems = 0;
      // after calculation with relationWithBase no of items in cart
      let addedQuantity = 0;
      // this is the base UOM
      const baseUom = item?.product?.uoms?.find(uom => uom?.isDefault === 1)
      // this is a current uom
      const CurrentUom = item?.product?.uoms?.find(uom => uom?.id === item?.uomId)
      let isInactive = false;
      // let categoryStatus = 'active';
      cartData.cart_items.forEach(newItems => {
        // categoryStatus = newItems.category[0].status;
        if (item?.productId === newItems?.productId) {
          const uom = item?.product?.uoms?.find(uom => uom?.id === newItems?.uomId)
          addedQuantity += newItems?.quantity * uom?.relationWithBase
        }
        /**
         * change productId to uomId if to show different quantity in different uom and vice versa
         */
        if (item?.uomId === newItems?.uomId) {
          totalItems += newItems?.quantity;
        }
        const hasStore = item?.product?.manage_store_products?.filter(store => store?.storeId === cartData?.storeId);
        if (hasStore?.length > 0) { isInactive = true; }
      });
      // item.categoryStatus = categoryStatus;
      item.isProductInactive = isInactive;
      item.totalQuantity = totalItems; // total quantity if particular item
      item.totalBaseQuantity = item?.product?.stockQuantity; // total quantity of base uom
      item.addedQuantity = addedQuantity; // after calculation with relationWithBase no of items in cart
      item.relationWithBase = CurrentUom?.relationWithBase ? CurrentUom?.relationWithBase : 1; // relation with base uom
      item.remainingQuantity = item?.product?.stockQuantity - addedQuantity // remaining quantity of base uom
      return item; // return item
    });
    cartData.cart_items = cartItems;
  }
  return cartData;
}

const localCartCount = () => {
  let cart = getCart();
  return (cart, cart?.itemCount) ? cart?.itemCount : 0;
}

const localOrderSummery = () => {
  let cart = getCart();
  let cartSummery = (cart && cart?.orderSummery) ? cart?.orderSummery : {};
  let coupon = localCartCoupon();
  return cartSummery;
}

const localStore = () => {
  let cart = getCart();
  return (cart) ? cart?.store : {};
}

const localCartProductIds = () => {
  let cart = getCart();
  return (cart && cart?.data) ? cart?.data.cart_items?.map(prod => prod.productId) : [];
}

const localCartUomIds = () => {
  let cart = getCart();
  return (cart && cart?.data) ? cart?.data?.cart_items?.map(prod => prod.uomId) : [];
}

const localCartCoupon = () => {
  const cart = getCart();
  let coupon = (cart && cart?.data && cart?.data?.coupon_data && cart?.data?.coupon_data !== '') ?
    JSON.parse(cart?.data.coupon_data) : {};
  coupon.couponResult = cart?.couponResult ? cart?.couponResult : {};
  return coupon
}


export const cart = createSlice({
    name: 'cart',
    initialState: {
      cartData: localCartValue(),
      cartCoupon: localCartCoupon(),
      cartProductIds: localCartProductIds(),
      cartUomIds: localCartUomIds(),
      itemCount: localCartCount(),
      orderSummery: localOrderSummery(),
      cartStore: localStore()
    },
    reducers: {
      changeCart: (state, action) => {
        state.cartData = localCartValue()
        state.cartCoupon = localCartCoupon(),
          state.cartProductIds = localCartProductIds()
        state.cartUomIds = localCartUomIds(),
          state.itemCount = localCartCount()
        state.orderSummery = localOrderSummery()
        state.cartStore = localStore()
      }
    },
  })

export const { changeCart } = cart.actions;
export const currentCart = (state) => state.cart?.cartData
export const currentCartCoupon = (state) => state.cart?.cartCoupon
export const currentCartProductIds = (state) => state.cart?.cartProductIds
export const currentCartUomIds = (state) => state.cart?.cartUomIds
export const currentCartCount = (state) => state.cart?.itemCount
export const currentOrderSummery = (state) => state.cart?.orderSummery
export const currentCartStore = (state) => state.cart?.cartStore

export default cart.reducer;
