import "./HeaderNew.scss";
import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, Outlet } from "react-router-dom";
import {
  Search as SearchIcon,
  Close as CloseIcon,
  Menu as MenuIcon,
} from "@mui/icons-material";
import { axiosConstant } from '../../../Helpers/Axios/Constants';
import { TextField, InputAdornment, Button, Menu, MenuItem, Fade, Divider, Badge, Select } from "@mui/material"
import { setLocalStorage, getLocalStorage, LOCAL_STORAGE_KEYS } from "../../../Helpers/crypto/LocalStorage";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import TopToolbar from "../header/top-toolbar/TopToolbar";
import Logo from "../../../assets/images/logo/header-logo.svg";
import LocalMall from "../../../assets/images/structure/local_mall.svg";
import Person from "../../../assets/images/structure/person.svg";

import ProfileIcon from "../../../assets/images/structure/profile-icon.svg";
import CardIcon from "../../../assets/images/structure/card-icon.svg";
import DeleteIcon from "../../../assets/images/structure/delete-black.svg";
import LockIcon from "../../../assets/images/structure/lock-icon.svg";
import LogoutIcon from "../../../assets/images/structure/logout-icon.svg";
import locationBlack from "../../../assets/images/structure/location-black.svg";

import Login from "../../../DialogBox/Login";
import Forgot from "../../../DialogBox/Forgot";
import OTPVerificationForgot from "../../../DialogBox/OTPVerificationForgot";
import Logout from "../../../DialogBox/Logout";
import CheckCart from "../../../DialogBox/CheckCart";
import UpdatePassword from "../../../DialogBox/UpdatePassword";
import ChangePassword from "../../../DialogBox/ChangePassword";
import AddressList from '../../../DialogBox/Address/AddressList';
import AddAddress from '../../../DialogBox/Address/AddAddress';
import ConfirmDialog from "../../../DialogBox/ConfirmDialog";

import { changeActiveLink, currentActiveLink } from "../../../redux/reducers/activeLink";
import { changeCart, currentCartCount } from '../../../redux/reducers/cart';
import { currentWishCount, changeWishlist } from '../../../redux/reducers/wishlist';
import { sortCategories, } from '../../../Helpers/HelperFunctions';
import jwt_decode from "jwt-decode";
import { currentAddress } from '../../../redux/reducers/address';
import { getRequest, postRequest } from "../../../Helpers/Axios/Services";
import { API_ROUTES } from "../../../Helpers/Axios/ApiRoutes";
import SearchCategoryModal from "DialogBox/SearchCategoryModal/SearchCategoryModal";


const Header = () => {
  const dispatch = useDispatch();
	const { t } = useTranslation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);
  const searchBarRef = useRef(null);
  const cartCount = useSelector(currentCartCount);
  const userAddress = useSelector(currentAddress)
  let [userToken, setUserToken] = useState(null);
  let [type, setTypes] = useState('grocery');
  let [code, setCode] = useState("");
  let [userId, setuserId] = useState("");
  let [forgotUname, setForgotUname] = useState("");
  let [identity, setIdentity] = useState("");
  let [countryCode, setCountryCode] = useState("974");
  let [businessTypes, setBusinessTypes] = useState([]);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [openLogout, setOpenLogout] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const [openCheckCart, setOpenCheckCart] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [searchCategoryModal, setOpenSearchCategoryModal] = useState(false);
  const [openForgot, setOpenForgot] = useState(false);
  const [openUpdatePassword, setOpenUpdatePassword] = useState(false);
  const [openOTPVerification, setOpenOTPVerification] = useState(false);
  const [openAddressList, setOpenAddressList] = useState(false);
  const [openAddressAdd, setOpenAddressAdd] = useState(false);
  const [isSearchBarVisible, setSearchBarVisible] = useState(false);
  const [isToggleIconClosed, setToggleIconClosed] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateCartData = async (type) => {
    setOpenCheckCart(false);
    const deviceId = getLocalStorage(LOCAL_STORAGE_KEYS.MACHINEID);
    const response = await postRequest(API_ROUTES.CART.MOVE_CART_DATA_ON_LOGIN, {
      deviceId: deviceId,
      action: type
    });
    const status = response.ack
    if (status === 0) {
      setOpenCheckCart(true);
    } else {
      getCartData();
    }
  }
  
  const getUserToken = async () => {
    const UserToken = getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
    if (UserToken) {
      userToken = UserToken;
      setUserToken(userToken);
    } else {
      userToken = null;
      setUserToken(userToken);
    }
  };

  const getCartData = async () => {
    let userID = '';
    if (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) {
      const token = getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
      let decoded = jwt_decode(token);
      userID = decoded?.id;
    }
    const deviceId = getLocalStorage(LOCAL_STORAGE_KEYS.MACHINEID);
    let url = API_ROUTES.CART.GET_CART_DATA;
    url = url + `?latitude=${userAddress?.latitude}&longitude=${userAddress?.longitude}`;
    const cartResponse = await postRequest(url, {
      userId: userID,
      deviceId: deviceId
    });
    setLocalStorage(LOCAL_STORAGE_KEYS.CART, JSON.stringify(cartResponse))
    dispatch(changeCart());
  }


  const getAllBusinessTypes = async () => {
    const response = await getRequest(API_ROUTES.COMMON.GET_BUSINESS_CATEGORIES);
    let data = response.businesstypes;
    let status = response.ack
    businessTypes = status === 1 ? data : [];
    setBusinessTypes(businessTypes);
  };

  const checkCartData = async () => {
    const deviceId = getLocalStorage(LOCAL_STORAGE_KEYS.MACHINEID);
    const response = await postRequest(API_ROUTES.CART.CHECK_CART_DATA_ON_LOGIN, { deviceId: deviceId });
    const status = response.ack
    if (status === 0) {
      setOpenCheckCart(true);
    } else {
      getCartData();
    }
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchBarRef.current &&
        !searchBarRef.current.contains(event.target)
      ) {
        setSearchBarVisible(false);
        setToggleIconClosed(false);
      }
    };

    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    document.body.addEventListener("click", handleClickOutside);
    window.addEventListener("scroll", handleScroll);

    return () => {
      document.body.removeEventListener("click", handleClickOutside);
      window.removeEventListener("scroll", handleScroll);
    };    
  }, 
  []);

  const toggleSearchBar = () => {
    setSearchBarVisible(!isSearchBarVisible);
    setToggleIconClosed(!isToggleIconClosed);
  };

  useEffect(() => {
    getAllBusinessTypes()
    getUserToken();
    setInterval(() => {
      getUserToken();
    }, 500);
    setInterval(() => {
      if (sessionStorage.getItem('signupSuccess')) {
        checkCartData();
        sessionStorage.removeItem('signupSuccess');
      }
    }, 1000);
  }, [])

  return (
    <>
      <div className={`header-wrapper ${isScrolled ? 'scrolled' : ''}`}  >
        <TopToolbar />
        <div className="header-bottom">
          <div className="container-body">
            <div className="header-body">
              <div onClick={() => navigate("/")} style={{ cursor: "pointer" }}>
                <img src={Logo} alt="Logo" />
              </div>
              <div className="search-area">
                <div>
                  <Button
                    id="fade-button"
                    aria-controls={open ? "fade-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                  >
                    <MenuIcon />
                    <span className="browse-cat-text">{t('BrowseCategory')}</span>
                  </Button>
                  <Menu
                    id="fade-menu"
                    MenuListProps={{
                      "aria-labelledby": "fade-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                    className="menu-list large-height"
                    PaperProps={{ style: { marginTop: '19px' } }}
                  >
                    {businessTypes.map((businessType, index) => 
                    <>
                      <MenuItem onClick={() => {
                        navigate(
                        businessType?.slug === "grocery"?
                          `/category/${businessType?.slug}`:
                          `/storefront/${businessType?.slug}`)
                        handleClose();
                        }}
                        key={index}  
                        >
                        <div className="menu-option">
                          <img src={axiosConstant.IMAGE_BASE_URL(businessType.image)} alt="Menu1" />
                          <span>{businessType.business_type_locales[0].name}</span>
                        </div>
                      </MenuItem>
                      <Divider variant="middle" className="my-none" />
                    </>)}
                  </Menu>
                </div>
                <div className="search-bar">
                  <div className="search-bar-mobile">
                    <div className="mobileToggle" onClick={() => setOpenSearchCategoryModal(true)}>
                      {isToggleIconClosed ? <CloseIcon /> : <SearchIcon />}
                    </div>
                    {/* {isSearchBarVisible && (
                      <TextField
                        className="mobileSearchBar"
                        placeholder="Search for Products or Vendors"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )} */}
                  </div>
                  <div className="search-bar-desktop">
                    <TextField
                      className=""
                      placeholder="Search for Products or Vendors"
                      inputProps={{
                        readOnly: true,
                      }}
                      onClick={() => {
                        setOpenSearchCategoryModal(true);
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="header-icons">
                <Link to={"/cart"}  className="header-content">
                  <Badge badgeContent={cartCount} color="primary" sx={{display: 'block'}}>
                    <img src={LocalMall} alt="LocalMall" />
                  </Badge>
                  <p>{t('cart')}</p>
                </Link>

                {userToken?<>
                <div className="header-content" onClick={(event) => setAnchorEl2(event.currentTarget)}>
                  <img src={Person} alt="LocalMall" />
                  <p>{t('profile')}</p>
                </div>
                <Menu
                    anchorEl={anchorEl2}
                    id="account-menu2"
                    open={open2}
                    onClose={() => setAnchorEl2(null)}
                    onClick={() => setAnchorEl2(null)}
                    transformOrigin={{ horizontal: "center", vertical: "top" }}
                    anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
                    disableScrollLock={false}
                    PaperProps={{ style: { marginTop: '18px' } }}
                  >
                    <Link className="header-drop" to={"/my-profile"}>
                      <MenuItem className="header-drop">
                        <img src={ProfileIcon} alt=".." />
                        {t("headerMyProfile")}
                      </MenuItem>
                    </Link>
                    <Link className="header-drop" to={"/order-history"}>
                      <MenuItem className="header-drop">
                        <img src={CardIcon} alt=".." />
                        {t("headerMmyOrders")}
                      </MenuItem>
                    </Link>
                    <MenuItem
                      className="header-drop"
                      onClick={() => setOpenConfirmDialog(true)}  
                    >
                      <img src={DeleteIcon} alt=".." />
                      {t("headerDeleteAccount")}
                    </MenuItem>
                    <MenuItem
                      className="header-drop"
                      onClick={() => {
                        setOpenChangePassword(true);
                        setAnchorEl2(null)
                      }}
                    >
                      <img src={LockIcon} alt=".." />
                      {t("headerChangePassword")}
                    </MenuItem>
                    <MenuItem
                      className="header-drop"
                      onClick={() => {
                        setOpenLogout(true);
                        setAnchorEl2(null)
                      }}
                    >
                      <img src={LogoutIcon} alt=".." />
                      {t("headerLogout")}
                    </MenuItem>
                  </Menu>
                </>:
                <div className="header-content" onClick={() => setOpenLogin(true)}>
                  <img src={Person} alt="LocalMall" />
                  <p>{t('login')}</p>
                </div>}

              </div>
            </div>
          </div>
        </div>
      </div>

      {searchCategoryModal ? (
        <SearchCategoryModal
        businessTypes={businessTypes}
          CloseDialog={() => {
            setOpenSearchCategoryModal(false);
          }}
        />
      ) : null}

      {openLogin ? (
        <Login
          handleClose={() => setOpenLogin(false)}
          forgotDialog={(forgotidentity, country_code) => {
            identity = forgotidentity;
            setIdentity(identity);
            countryCode = country_code;
            setCountryCode(countryCode)
            setOpenLogin(false);
            setOpenForgot(true);
          }}
          loginSuccess={() => {
            setOpenLogin(false);
            getUserToken();
            checkCartData();
          }}
        />
      ) : null}
      {openForgot ? (
        <Forgot
          identity={identity}
          countryCode={countryCode}
          handleClose={() => {
            setOpenForgot(false);
            setIdentity("");
          }}
          openVerifyForgot={(res) => {
            forgotUname = res.uname;
            setForgotUname(forgotUname);
            countryCode = res.country_code;
            setCountryCode(countryCode);
            setOpenForgot(false);
            setOpenOTPVerification(true);
            setIdentity("");
          }}
        />
      ) : null}
      {openOTPVerification ? (
        <OTPVerificationForgot
          forgotUname={forgotUname}
          countryCode={countryCode}
          handleClose={() => setOpenOTPVerification(false)}
          openPasswordUpdate={(res) => {
            setCode(res.code);
            setuserId(res.user_id);
            setOpenOTPVerification(false);
            setOpenUpdatePassword(true);
          }}
        />
      ) : null}
      {openUpdatePassword ? (
        <UpdatePassword
          code={code}
          userId={userId}
          handleClose={() => setOpenUpdatePassword(false)}
          loginOpen={(response) => {
            setOpenUpdatePassword(false);
            setOpenLogin(true);
            dispatch(
              changeSnackbar({
                ...snackbar,
                isOpen: true,
                message: response.msg,
                state: "success",
              })
            );
          }}
        />
      ) : null}
      {openLogout ? (
        <Logout
          CloseDialog={() => {
            setOpenLogout(false);
          }}
          logOutSuccess={() => {
            setOpenLogout(false);
            getUserToken();
            getCartData();
            navigate("/");
          }}
        />
      ) : null}
      {openChangePassword ? (
        <ChangePassword
          handleClose={() => {
            setOpenChangePassword(false);
          }}
          successChange={() => {
            setOpenChangePassword(false);
          }}
        />
      ) : null}
      {openCheckCart ? (
        <CheckCart
          DiscardGuest={() => updateCartData("discard_guest")}
          DiscardUser={() => updateCartData("discard_user")}
        />
      ) : null}

      {openAddressList ? <AddressList
        handleClose={() => setOpenAddressList(false)}
        openAddAddress={() => {
          setOpenAddressList(false)
          setOpenAddressAdd(true)
        }}
      /> : null}
      {openAddressAdd ? <AddAddress
        handleClose={() => setOpenAddressAdd(false)}
        addressSaved={() => {
          setOpenAddressAdd(false)
        }}
      /> : null}
      {openConfirmDialog?<ConfirmDialog
        CloseDialog={() => setOpenConfirmDialog(false)}
        topHeading={t('headerDeleteAccount')}
        infoDialogMessage={t('headerDeleteAccountConfirmation')}
        infoDialogMessageLine2={t('headerDeleteAccountConfirmation2')}
        handleYes={() => {
          setOpenConfirmDialog(false);
          navigate("/delete-account");
        }}
      />: null}
      <Outlet />
    </>
  );
};

export default Header;
