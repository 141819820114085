/**
 * @file ConfirmDialog.js
 * @description Confirm Dialog Box
 * @exports ConfirmDialog - Dialog Box
 * @exports CloseDialog - Function to close the dialog box=
 */

import { Grid, Dialog, DialogContent, Button } from '@mui/material';
import modalClose from "../assets/images/structure/close-black.svg";
import { useTranslation } from "react-i18next";

const ConfirmDialog = ({
  topHeading="Warning",
  infoDialogMessage="Are you sure?",
  infoDialogMessageLine2="",
  CloseDialog,
  handleYes
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={true} onClose={CloseDialog} className="main-modal theme-dialog">
      <img src={modalClose} alt="close" onClick={CloseDialog} className='modal-close' />
      <DialogContent style={{ maxWidth: '600px' }}>
        <h2 className='h3 text-center' style={{ marginBottom: '8px' }}>{topHeading}</h2>
        <p className='p3 text-center' style={{ marginBottom: '12px', fontSize: '14px' }}>{infoDialogMessage}</p>
        {infoDialogMessageLine2 !==''?
        <p className='p3 text-center' style={{ marginBottom: '12px', fontSize: '14px' }}>{infoDialogMessageLine2}</p>:null}
        <Grid item xs={12} textAlign="center" display={'flex'} justifyContent={'center'}>
          <Button type='button' size="medium" variant="contained" onClick={handleYes}
            style={{ marginRight: '8px' }}>{t('Yes')}</Button>
          <Button type='button' size="medium" variant="contained" onClick={CloseDialog}>{t('No')}</Button>
        </Grid>
      </DialogContent>
    </Dialog>
  )
};

export default ConfirmDialog;