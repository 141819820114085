/**
 * @file MoveToWishlist.js
 * @description Move to Wishlist Dialog Box
 * @exports MoveToWishlist - Dialog Box
 * @exports CloseDialog - Function to close the dialog box
 * @exports MoveCartToWishlist - Function to move all the products from cart to wishlist
 */

import { Button, Grid, Dialog, DialogContent } from "@mui/material";
import { useTranslation } from "react-i18next";

const MoveToWishlist = ({ CloseDialog, MoveCartToWishlist }) => {
  const { t } = useTranslation();
  return (
    <Dialog open={true} onClose={CloseDialog} className="main-modal theme-dialog">
      <DialogContent>
        <h2 className="h3" style={{ marginBottom: "8px" }}>
          {t("cart_MoveAllToWishlist")}
        </h2>
        <p className="p3" style={{ marginBottom: "16px", fontSize: "14px" }}>
          {t("cart_Areyousureyouwanttomovealltheproductsfromcart")}
        </p>
        <Grid item xs={12} textAlign="center" display={"flex"} justifyContent={"flex-end"}>
          <Button
            type="button"
            size="medium"
            variant="text"
            color="primary"
            onClick={CloseDialog}
          >
            {t("cart_Cancel")}
          </Button>
          <Button type="button" size="medium" variant="text" color="error"
            onClick={() => { MoveCartToWishlist(); }}
          >
            {t("cart_Move")}
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default MoveToWishlist;