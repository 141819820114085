import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getRequest } from "../../Helpers/Axios/Services";
import { API_ROUTES } from "../../Helpers/Axios/ApiRoutes";
import { useDispatch, useSelector } from "react-redux";
import {
  LOCAL_STORAGE_KEYS,
  getLocalStorage
} from "../../Helpers/crypto/LocalStorage";
import { currentAddress } from "../../redux/reducers/address";
import { ampli } from "../../../src/ampli";
import jwt_decode from "jwt-decode";
import { currentBaladiData } from "../../redux/reducers/baladiData";
import "./HomeNew.scss";
import FooterNew from "pages/layout/FooterNew/FooterNew";
import Banner from "pages/HomeNew/Banner/Banner";
import GrowBusiness from "pages/HomeNew/GrowBusiness/GrowBusiness";
import Groceries from "pages/HomeNew/Groceries/Groceries";
import MobileApp from "pages/HomeNew/MobileAppDownload/MobileApp";
import Review from "pages/HomeNew/Reviews/Review";
import Partners from "pages/HomeNew/Partners/Partners";
import ChooseEnjoy from "pages/HomeNew/ChooseAndEnjoy/ChooseEnjoy";
// import Subscribe from "pages/HomeNew/Subscribe/Subscribe";

const HomeNew = () => {
  let [businessTypes, setBusinessTypes] = useState([]);
  let [isLoading, setIsLoading] = useState(true);
  let [banners, setBanners] = useState([]);
  const userAddress = useSelector(currentAddress);


  const getBannerData = async () => {
    let url = API_ROUTES.COMMON.GET_BANNER(10, 1);
    url += `&latitude=${userAddress?.latitude}&longitude=${userAddress?.longitude}`;
    const response = await getRequest(url);
    banners = response?.data?.rows?.length > 0 ? response?.data?.rows : [];
    setBanners(banners);
  };


  const getAllBusinessTypes = async () => {
    const response = await getRequest(
      API_ROUTES.COMMON.GET_BUSINESS_CATEGORIES
    );
    businessTypes = response.businesstypes.map((category) => {
      let item = category;
      let offers = category?.discounts_and_offer_relations?.map(
        (offer) => offer.discounts_and_offer
      );
      offers = [...new Map(offers?.map((item) => [item["id"], item])).values()];
      item.offers = offers;
      return item;
    });
    setBusinessTypes(businessTypes);
    setIsLoading(false);
  };

  useEffect(() => {
    isLoading = true;
    setIsLoading(isLoading);
    getAllBusinessTypes();
    // getBannerData();
  }, []);

  useEffect(() => {
    getBannerData();
  }, [userAddress]);


  useEffect(() => {
    //ampli===
    let userID = "";
    if (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) {
      const token = getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
      let decoded = jwt_decode(token);
      userID = decoded?.id;
    }
    ampli.track({
      event_type: "HomepageViewed",
      event_properties: {
        category: "Docs",
        name: "SDK Library",
        description: "SDK Library Description",
        is_guest_user: true,
        userId: userID ? userID : 0,
      },
    });
  }, []);

  return (
    <>
    <div className="change-font">
      <Banner />
      <ChooseEnjoy/> 
      <GrowBusiness />
      <Groceries />
      <MobileApp />
      <Review />
      <Partners /> 
      {/*<Subscribe />*/}
      <FooterNew />
    </div>
    </>
  );
};

export default HomeNew;
