import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { currentAddress } from "../../../redux/reducers/address";
import { useTranslation } from "react-i18next";
import { getRequest } from "../../../Helpers/Axios/Services";
import { API_ROUTES } from "../../../Helpers/Axios/ApiRoutes";
import { useDispatch, useSelector } from "react-redux";
import homeSliderImage from "../../../assets/images/structure/home-banner.jpg";
import { axiosConstant } from '../../../Helpers/Axios/Constants';
import { changeSnackbar } from "redux/reducers/snackbar";
import {currentBaladiData} from '../../../redux/reducers/baladiData';

const SlideComponent = ({ banner }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const baladiData = useSelector(currentBaladiData)
	const userAddress = useSelector(currentAddress);
  let [redirectUrl, setRedirectUrl] = useState("");
  let [isBrand, setIsBrand] = useState(false);


  const gotoBrandStore = async (banner) => {
  	const response = await getRequest(API_ROUTES.COMMON.GET_NEAREST_STORE_BY_BRAND(banner?.brandId, userAddress.latitude,userAddress.longitude))
  	if (response.stores.length === 0) {
			dispatch(changeSnackbar({
				isOpen: true,
				state: "error",
				message: "Store not found",
				vertical: 'top',
				horizontal: 'right'
      }));
      return
    }
    let storeSlug = response.stores[0].slug;
    let redirectUrl = (banner?.storeId !== baladiData.BALADI_STORE_Id) ?
    `/store/${storeSlug}${banner?.header_banner_locales[0].url.trim() !== '' ?
      `?s=${banner?.header_banner_locales[0].url}` : ''}` :
    `/category/${storeSlug}${banner?.header_banner_locales[0].url.trim() !== '' ?
      `?s=${banner?.header_banner_locales[0].url}` : ''}`
    navigate(redirectUrl)
  }

	const getBannerData = async () => {
		if (banner?.productId !== 0) {
			redirectUrl = `/product-detail/${banner?.store?.slug || banner?.storeId}/${banner?.product?.slug || banner?.productId}`
		} else if (banner?.categoryId != 0) {
			redirectUrl = (banner?.storeId !== baladiData.BALADI_STORE_Id) ?
				`/store/${banner?.store?.slug || banner?.storeId}${banner?.header_banner_locales[0].url.trim() !== '' ?
					`?s=${banner?.header_banner_locales[0].url}&cat=${banner?.categoryId}` : `?cat=${banner?.categoryId}`}` :
				`/store/${banner?.store?.slug || banner?.storeId}/${banner?.category?.slug}${banner?.header_banner_locales[0].url.trim() !== '' ?
					`?s=${banner?.header_banner_locales[0].url}&cat=${banner?.categoryId}` : ''}`
		} else if (banner?.storeId != 0) {
			redirectUrl = (banner?.storeId !== baladiData.BALADI_STORE_Id) ?
				`/store/${banner?.store?.slug || banner?.storeId}${banner?.header_banner_locales[0].url.trim() !== '' ?
					`?s=${banner?.header_banner_locales[0].url}` : ''}` :
				`/category/${banner?.business_type?.slug}${banner?.header_banner_locales[0].url.trim() !== '' ?
					`?s=${banner?.header_banner_locales[0].url}` : ''}`
		} else if (banner?.brandId != 0) {
			isBrand = true;
			setIsBrand(isBrand);
		} else {
			redirectUrl = (banner?.businessId != 2) ?
				`/storefront/${banner?.business_type?.slug || banner?.businessId}${banner?.header_banner_locales[0].url.trim() !== '' ?
					`?s=${banner?.header_banner_locales[0].url}` : ''}` :
				`/category/${banner?.business_type?.slug}${banner?.header_banner_locales[0].url.trim() !== '' ?
					`?s=${banner?.header_banner_locales[0].url}` : ''}`
		}
		setRedirectUrl(redirectUrl)
		console.log("redirectUrl", redirectUrl);
		console.log("isBrand", isBrand);
	}

  useEffect(() => {
    getBannerData()
  }, [banner]);

  return (
    isBrand ? 
      <div aria-label="banner" >
      < img
        src={axiosConstant.IMAGE_BASE_URL(
          localStorage.getItem('i18nextLng') === 'ar' ? banner?.image_ar : banner?.image
        )}
        alt="banner-brand" className="home-slider"
        style={{ cursor: 'pointer' }}
        onClick={() => { gotoBrandStore(banner); }}
        onError={(e) => { e.target.onerror = null; e.target.src = homeSliderImage; }}
      />
    </div>:
    <Link aria-label="banner" to={redirectUrl}>
      < img
        src={axiosConstant.IMAGE_BASE_URL(
          localStorage.getItem('i18nextLng') === 'ar' ? banner?.image_ar : banner?.image
        )}
        alt="banner-link" className="home-slider"
        onError={(e) => { e.target.onerror = null; e.target.src = homeSliderImage; }}
      />
    </Link>
  );
};

export default SlideComponent;
