import "./OrderDetails.scss"
import { useState, useEffect } from 'react';
import { Boxed } from "../../Styles-Elements/Box"
import { axiosConstant } from '../../Helpers/Axios/Constants';
import {
  displayPrice,
  getFrontTypeImageUrl,
  getNameBasedOnLanguage
} from '../../Helpers/HelperFunctions';
import { useTranslation } from "react-i18next";
import Image from "../../global-modules/Image/Image";

const OrderItem = ({ order_item, index }) => {

  const { t } = useTranslation();
  let [uom, setUom] = useState(null);
  let [addonNames, setAddonNames] = useState('');
  let [addonPrice, setAddonPrice] = useState(0);

  useEffect(() => {
    uom = order_item?.uom;
    setUom(uom);
    addonNames = '';
    if (order_item?.addons_json && order_item?.addons_json !== '') {
      const jsonAddon = JSON.parse(order_item?.addons_json);
      // getNameBasedOnLanguage
      addonNames = jsonAddon.map((addon) => {
        return getNameBasedOnLanguage(addon?.addon_items_locales);
      }).join(', ');
    }
    setAddonNames(addonNames)
    addonPrice = order_item?.addonPrice ? order_item?.addonPrice : 0;
    setAddonPrice(addonPrice)
  }, []);

  return (
    <div className="product-blocks" key={`order-item-${index}`}>
      <Boxed display={"flex"} alignItems={"flex-start"} className="">
        <Image
          src={axiosConstant.IMAGE_BASE_URL(
            getFrontTypeImageUrl(uom?.uom_images)
          )}
          className="product-blocks-img"
          alt=""
        />
        <div className="product-blocks-content">
          <p className="p2">
            {order_item?.product?.products_locales[0].title} {uom?.uomName}
          </p>
          <p className="p3 latter-spacing1" dir="ltr">
            {order_item?.quantity} x {t("QAR")}{" "}
            {order_item?.salePrice !== 0
              ? displayPrice(order_item?.salePrice + addonPrice)
              : displayPrice(order_item?.price + addonPrice)}
            {order_item?.salePrice !== 0 && order_item?.salePrice !== order_item?.price ? (
              <span className="order-down-price">
                {" "}
                {`${t("QAR")} ${displayPrice(order_item?.price + addonPrice)}`}
              </span>
            ) : null}
          </p>
          {addonNames !== '' ? <p className="p3 latter-spacing1 maxwidth-400">{addonNames}</p> : null}
          {order_item?.specialRequest !== '' ? <p className="p3 latter-spacing1 maxwidth-400"><b>{t('order_note')}</b>: {order_item?.specialRequest}</p> : null}

        </div>
      </Boxed>
      <p className="p2 latter-spacing1">
        {t("QAR")}{" "}
        {order_item?.salePrice !== 0
          ? displayPrice((order_item?.salePrice + addonPrice) * order_item?.quantity)
          : displayPrice((order_item?.price + addonPrice) * order_item?.quantity)}
      </p>
      {/* this is my offer code please don't remove code  */}
      {/* <p className="p2 latter-spacing1 p2-secondary">FREE</p> */}
    </div>
  );
}

export default OrderItem