/**
 * @file baladiData.js
 * @description This file contains the baladiData store configuration
 * @exports {Object} baladiData
 * @exports {Object} currentBaladiData
 * @exports {Object} changeBaladiData
 */

import { createSlice } from '@reduxjs/toolkit'
import { getLocalStorage, LOCAL_STORAGE_KEYS } from '../../Helpers/crypto/LocalStorage';

const getBaladiData = () => {
  let baladiData = getLocalStorage(LOCAL_STORAGE_KEYS.BALADI_DATA);
  if (baladiData) {
    return JSON.parse(baladiData);
  } else {
    return {};
  }
}

export const baladiData = createSlice({
  name: 'baladiData',
  initialState: {
    value: getBaladiData(),
  },
  reducers: {
    changeBaladiData: (state, action) => {
      state.value = getBaladiData()
    },
  },
})

// Action creators are generated for each case reducer function
export const { changeBaladiData } = baladiData.actions
export const currentBaladiData = (state) => state.baladiData.value;
export default baladiData.reducer
