const CryptoJS = require("crypto-js");

/**
 * @file encryptDcrypt.js
 * @description This function is used to encrypt and decrypt the data
 * @param {*} value 
 * @param {*} type 
 * @default en
 * @returns encrypted or decrypted data
 */
const encryptDcrypt = (value, type = 'en') => {
  if (value !== undefined) {
    let secret = 'baladi_dev_KHYUIS7L3xa07dsfgdyWA8dMRRsFkc1sfd3432jLB';
    if (type === 'en') {
      return CryptoJS.AES.encrypt(value.toString(), secret);
    } else {
      let c = CryptoJS.AES.decrypt(value.toString(), secret);
      return c.toString(CryptoJS.enc.Utf8);
    }
  }
}
export default encryptDcrypt;