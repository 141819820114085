/**
 * @file This file contains all the Arabic language translations
 * Arabic Language
 * @type {Object}
 * @returns {Object} language - The language object
 */

import common from './ar/common.json';
import header from './ar/header.json';
import sidebar from './ar/sidebar.json';
import signup from './ar/signup.json';
import changepassword from './ar/changepassword.json';
import storefront from './ar/storefront.json';
import address from './ar/address.json';
import cart from './ar/cart.json';
import order from './ar/order.json';
import wishlist from './ar/wishlist.json';
import review from './ar/review.json';
import wallet from './ar/wallet.json';
import termsCondition from './ar/termsCondition.json';
import faq from './ar/faq.json';
import static_pages from './ar/static_pages.json';
import manage_card from './ar/manage_card.json';

const ar = () => {
  const language = {
    ...common,
    ...header,
    ...sidebar,
    ...signup,
    ...changepassword,
    ...storefront,
    ...address,
    ...cart,
    ...order,
    ...wishlist,
    ...review,
    ...wallet,
    ...termsCondition,
    ...faq,
    ...static_pages,
    ...manage_card
  }
  return language;
}

export default ar