import { Boxed } from "../../Styles-Elements/Box"
import { Grid, Button } from '@mui/material';
import "./OrderDetails.scss"
import Sidebar from '../layout/sidebar/Sidebar'
import arrowLeftBlack from "../../assets/images/structure/arrow-left-black.svg"
import pizzaImgSmall from "../../assets/images/products/pizza-img-small.svg"
import locationBlack from "../../assets/images/structure/location-black.svg"
import messageBlack from "../../assets/images/structure/message-black.svg"
import { useTranslation } from "react-i18next";




const OrderDetails = () => {




  const { t } = useTranslation();
  return (
    <div>
      <div className="main-wrapper full-bg">
        <Sidebar />
        <div className="main-mid-wrapper">
          <div className="order-detail-wrapper">
            <div className="card-white">
              <div className="store-detail-head">
                <img src={arrowLeftBlack} alt="arrowLeftBlack" />
                <h3 className="h3">#BDE45544655</h3>
                <p className="p2 p2-tertiary">{t("pending")}</p>
                <p className="p2 p2-secondary">{t("preparing")}</p>
                <p className="p2 p2-secondary">{t("dispatched")}</p>
                <p className="p2 p2-secondary">{t("delivered")}</p>
                <p className="p2 p2-primary">{t("cancelled")}</p>
                <p className="p2 p2-primary">{t("rejected")}</p>
              </div>
              <div className="store-bottom-head-block">
                <Boxed display={"flex"} alignItems={"center"}>
                  <div className="block-boxes">
                    <p className="p3">{t("orderDate")}</p>
                    <p className="p2">17 Jan 2023 - 11:30 AM</p>
                  </div>
                  <div className="block-boxes">
                    <p className="p3">{t("paymentMethod")}</p>
                    <p className="p2">{t("cod")}</p>
                  </div>
                  <div className="block-boxes">
                    <p className="p3">{t("deliverBy")}</p>
                    <p className="p2">{t("deliverBy")} : 24 Jan 2023 - 11:30 AM </p>
                  </div>
                </Boxed>
              </div>
            </div>
            <Grid container columnSpacing={"30px"}>
              <Grid item xs={12} sm={12} md={8}>
                <div className="card-white">
                  <div className="order-product-detail-wrap">
                    <p className="p3">{t("productDetails")}</p>
                    <div className="product-blocks">
                      <Boxed display={"flex"} alignItems={"center"} className="">
                        <img
                          src={pizzaImgSmall}
                          className="product-blocks-img"
                          alt="pizzaImgSmall"
                        />
                        <div className="product-blocks-content">
                          <p className="p2">Margherita {t("pizza")}</p>
                          <p className="p3 latter-spacing1">1 x {t("QAR")} 20.00</p>
                        </div>
                      </Boxed>
                      <p className="p2 latter-spacing1">{t("QAR")} 20.00</p>
                    </div>
                    <div className="product-blocks">
                      <Boxed display={"flex"} alignItems={"center"} className="">
                        <img
                          src={pizzaImgSmall}
                          className="product-blocks-img"
                          alt="pizzaImgSmall"
                        />
                        <div className="product-blocks-content">
                          <p className="p2">Farmhouse {t("pizza")}</p>
                          <p className="p3 latter-spacing1">2 x {t("QAR")} 20.00</p>
                        </div>
                      </Boxed>
                      <p className="p2 latter-spacing1">{t("QAR")} 40.00</p>
                    </div>
                    <div className="product-blocks">
                      <Boxed display={"flex"} alignItems={"center"} className="">
                        <img
                          src={pizzaImgSmall}
                          className="product-blocks-img"
                          alt="pizzaImgSmall"
                        />
                        <div className="product-blocks-content">
                          <p className="p2">Peppy Paneer {t("pizza")}</p>
                          <p className="p3 latter-spacing1">1 x {t("QAR")} 10.00</p>
                        </div>
                      </Boxed>
                      <p className="p2 latter-spacing1">{t("QAR")} 10.00</p>
                    </div>
                    <div className="product-blocks">
                      <Boxed display={"flex"} alignItems={"center"} className="">
                        <img
                          src={pizzaImgSmall}
                          className="product-blocks-img"
                          alt="pizzaImgSmall"
                        />
                        <div className="product-blocks-content">
                          <p className="p2">Fresh Veggie {t("pizza")}</p>
                          <p className="p3 latter-spacing1">2 x {t("QAR")} 15.00</p>
                        </div>
                      </Boxed>
                      <p className="p2 latter-spacing1">{t("QAR")} 30.00</p>
                    </div>
                  </div>
                </div>
                <div className="card-white">
                  <div className="order-shipping-detail-wrap">
                    <p className="p3">{t("shippingDetails")}</p>
                    <div className="shipping-blocks">
                      <h6 className="h6">Ellias Andrew | +974 98765432</h6>
                      <Boxed display={"flex"} alignItems={"center"} mb={"8px"}>
                        <img src={locationBlack} alt="locationBlack" className="icon20" />
                        <p className="p3">219 Goldfield Drive, New York, United States,NY 10004</p>
                      </Boxed>
                      <Boxed display={"flex"} alignItems={"center"} mb={"8px"}>
                        <img src={messageBlack} alt="messageBlack" className="icon20" />
                        <p className="p3">allex_carry@gmail.com</p>
                      </Boxed>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <div className="card-white">
                  <div className="order-payment-details-wrap">
                    <p className="p3">{t("paymentDetails")}</p>
                    <div className="payment-lists">
                      <span className="p2">{t("orderTotal")}</span>
                      <span className="p2-bold latter-spacing1">{t("QAR")} 100.00</span>
                    </div>
                    <div className="payment-lists">
                      <span className="p2">{t("tax")}</span>
                      <span className="p2-bold latter-spacing1">{t("QAR")} 20.15</span>
                    </div>
                    <div className="payment-lists">
                      <span className="p2">{t("deliveryCharges")}</span>
                      <span className="p2-bold latter-spacing1">{t("QAR")} 20.00</span>
                    </div>
                    <div className="payment-lists-total">
                      <span className="p2">{t("totalAmount")}</span>
                      <span className="p2 latter-spacing1">{t("QAR")} 140.15</span>
                    </div>
                  </div>
                </div>
                <Boxed display={"flex"} alignItems={"center"} mt={"20px"}>
                  <Button
                    className="secondary"
                    variant="contained"
                    size="large"
                    sx={{ width: "100%" }}
                  >
                    {t("track")}
                  </Button>
                </Boxed>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderDetails;
