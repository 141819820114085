import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { Grid } from "@mui/material";
import { getRequest } from "../../../Helpers/Axios/Services";
import { API_ROUTES } from "../../../Helpers/Axios/ApiRoutes";
import { useDispatch, useSelector } from "react-redux";
import category1 from "../../../assets/images/products/category1.jpg";
import { useTranslation } from "react-i18next";
import { axiosConstant } from "../../../Helpers/Axios/Constants";
import { changeActiveLink } from "../../../redux/reducers/activeLink";
import {
  setLocalStorage,
  LOCAL_STORAGE_KEYS,
  getLocalStorage,
} from "../../../Helpers/crypto/LocalStorage";
import Image from "../../../global-modules/Image/Image";
import { currentAddress } from "../../../redux/reducers/address";
import { ampli } from "../../../ampli";
import jwt_decode from "jwt-decode";
import { currentBaladiData } from "../../../redux/reducers/baladiData";
import { Flip } from 'react-reveal';

const ChooseEnjoy = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let [type, setTypes] = useState("grocery");
  let [businessTypes, setBusinessTypes] = useState([]);
  let [search, setSearch] = useState("");
  let [isLoading, setIsLoading] = useState(true);
  let [banners, setBanners] = useState([]);
  const userAddress = useSelector(currentAddress);
  const baladiData = useSelector(currentBaladiData);

  const getBannerData = async () => {
    let url = API_ROUTES.COMMON.GET_BANNER(10, 1);
    url += `&latitude=${userAddress?.latitude}&longitude=${userAddress?.longitude}`;
    const response = await getRequest(url);
    banners = response?.data?.rows?.length > 0 ? response?.data?.rows : [];
    setBanners(banners);
  };

  const getAllBusinessTypes = async () => {
    const response = await getRequest(
      API_ROUTES.COMMON.GET_BUSINESS_CATEGORIES
    );
    businessTypes = response.businesstypes.map((category) => {
      let item = category;
      let offers = category?.discounts_and_offer_relations?.map(
        (offer) => offer.discounts_and_offer
      );
      offers = [...new Map(offers?.map((item) => [item["id"], item])).values()];
      item.offers = offers;
      return item;
    });
    setBusinessTypes(businessTypes);
    setIsLoading(false);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    isLoading = true;
    setIsLoading(isLoading);
    getAllBusinessTypes();
    // getBannerData();
  }, []);

  useEffect(() => {
    getBannerData();
  }, [userAddress]);

  useEffect(() => {
    //ampli===
    let userID = "";
    if (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) {
      const token = getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
      let decoded = jwt_decode(token);
      userID = decoded?.id;
    }
    ampli.track({
      event_type: "HomepageViewed",
      event_properties: {
        category: "Docs",
        name: "SDK Library",
        description: "SDK Library Description",
        is_guest_user: true,
        userId: userID ? userID : 0,
      },
    });
  }, []);

  return (
    <>
      <section className="home-section2">
        <div className="container-1110">
          <Grid container columnSpacing={0}>
            <Grid item xs={12} sm={12} md={12}>
              <div className="text-center homeNewTitle">
              <Flip bottom>
                <h3 className="h2">{t('choose_&_Enjoy')}</h3>
                <p className="p2">
                {t('youCanEasilySearchForWhatCategoryOfItemYouWantToOrder')}
                </p>
                </Flip>
              </div>
              <Slider
                centerMode={false}
                dots={false}
                infinite={false}
                arrows={true}
                speed={500}
                slidesToShow={5}
                slidesToScroll={1}
                responsive={[
                  {
                    breakpoint: 1025,
                    settings: { slidesToShow: 4, slidesToScroll: 1 },
                  },
                  {
                    breakpoint: 769,
                    settings: { slidesToShow: 3, slidesToScroll: 1 },
                  },
                  {
                    breakpoint: 576,
                    settings: { slidesToShow: 1, slidesToScroll: 1 },
                  },
                ]}
              >
                {businessTypes?.map((category, index) => (
                  <div
                    key={`category-slide-${index}`}
                    className="category-slide-box"
                  >
                    <div className="card-block">
                      <Link
                        aria-label="category-link"
                        onClick={() => {
                          setLocalStorage(
                            LOCAL_STORAGE_KEYS.ACTIVE_LINK,
                            category?.id
                          );
                          dispatch(changeActiveLink());
                        }}
                        to={
                          category?.slug !== "grocery"
                            ? `/storefront/${category?.slug}`
                            : `/category/${category?.slug}`
                        }
                      >
                        <Image
                          src={
                            category?.image === ""
                              ? category1
                              : axiosConstant.IMAGE_BASE_URL(category?.image)
                          }
                          className="card-img"
                          alt={category?.business_type_locales[0].name}
                        />
                      </Link>
                    </div>
                    <div className="card-content-box">
                      <Link
                        aria-label="category-link"
                        onClick={() => {
                          setLocalStorage(
                            LOCAL_STORAGE_KEYS.ACTIVE_LINK,
                            category?.id
                          );
                          dispatch(changeActiveLink());
                        }}
                        className="h3"
                        to={
                          category?.slug !== "grocery"
                            ? `/storefront/${category?.slug}`
                            : `/category/${category?.slug}`
                        }
                      >
                        {category?.business_type_locales[0].name}
                      </Link>
                    </div>
                  </div>
                ))}
              </Slider>
            </Grid>
          </Grid>
        </div>
      </section>
    </>
  );
};

export default ChooseEnjoy;
