/** @format */
import { useParams, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { Button } from "@mui/material";
import "./FieldTransaction.scss";
import fieldIcon from "../../assets/images/structure/info-gray.webp";
import Footer from "../layout/footer/Footer";
import { useTranslation } from "react-i18next";
import { changeCart } from '../../redux/reducers/cart';
import { LOCAL_STORAGE_KEYS } from '../../Helpers/crypto/LocalStorage';

const FieldTransaction = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { orderid } = useParams();

  useEffect(() =>  {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.CART);
    dispatch(changeCart());
  }, [])

  return (
    <>
      <div className="thankyou-content">
        <img src={fieldIcon} alt="..." className="" />
        <h3 className="h3">{t('cart_FieldTransaction')}</h3>
        <p className="p2">{t("cart_OrderID")}: {orderid}</p>
        <p className="p2">{t('cart_faildTransaction')}</p>
        <Link to={"/order-history"} className="no-decoration">
          <Button type="button" variant="contained" size="large" sx={{ width: "150px" }}>{t("cart_myOrders")}</Button>
        </Link>
      </div>
      <Footer />
    </>
  );
};

export default FieldTransaction;
