
import "./ProductDetail.scss";
import { useParams, Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Divider, Grid, Button } from "@mui/material";
import { useState, useEffect } from 'react';
import { Boxed } from "../../Styles-Elements/Box";
import { useTranslation } from "react-i18next";
import { changeActiveLink } from "../../redux/reducers/activeLink";
import { getRequest, postRequest, putRequest, deleteRequest } from '../../Helpers/Axios/Services';
import { API_ROUTES } from '../../Helpers/Axios/ApiRoutes';
import { changeSnackbar, currentSnackbar } from "../../redux/reducers/snackbar";
import { changeCart, currentCartProductIds, currentCartUomIds, currentCart } from '../../redux/reducers/cart';
import { changeWishlist, currentWishlist, currentWishProductIds } from '../../redux/reducers/wishlist';
import { changeLoader } from "../../redux/reducers/loader";
import { axiosConstant } from '../../Helpers/Axios/Constants';
import { getLocalStorage, setLocalStorage, LOCAL_STORAGE_KEYS } from '../../Helpers/crypto/LocalStorage';
import { displayPrice, sortImagesBasedOnType, getFrontTypeImageUrl } from '../../Helpers/HelperFunctions';
import VendorMisMatch from '../../DialogBox/VendorMisMatch';
import AddToCardModifier from '../../DialogBox/AddToCardModifier';
import ReapetLastUseCostumise from '../../DialogBox/ReapetLastUseCostumise';
import Footer from "../layout/footer/Footer";
import Breadcrumb from "../../shared/Breadcrumb";
import StoreTopBox from "./StoreTopBox";
import jwt_decode from "jwt-decode";
import heartBorder from "../../assets/images/structure/wishlist-black.svg";
import heart from "../../assets/images/structure/wishlist-red.svg";
import minusIcon from "../../assets/images/structure/minus-icon.svg";
import plusIcon from "../../assets/images/structure/plus-icon.svg";
import checkIcon from "../../assets/images/structure/check-green.svg";
import SliderImage from "react-zoom-slider";
import { currentAddress } from '../../redux/reducers/address';
import { ampli } from "../../../src/ampli";
import { currentBaladiData } from '../../redux/reducers/baladiData';

const ProductDetailGrocery = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { productslug, storeslug } = useParams();
  const userAddress = useSelector(currentAddress)
  const snackbar = useSelector(currentSnackbar);
  const baladiData = useSelector(currentBaladiData);
  const cartUomIds = useSelector(currentCartUomIds);
  const cartProductIds = useSelector(currentCartProductIds);
  const cartData = useSelector(currentCart);
  const wishlist = useSelector(currentWishlist)
  const wishProductIds = useSelector(currentWishProductIds);

  const [isAddToCardModifier, setAddToCardModifier] = useState(false);
  const [isReapetLastUseCostumise, setReapetLastUseCostumise] = useState(false);
  let [cartId, setCartId] = useState(0);
  let [isVendor, setIsVendor] = useState(false);
  let [storeDetails, setStoreDetails] = useState(null);
  let [productDetails, setProductDetails] = useState(null);
  let [images, setImages] = useState([]);
  let [userToken, setUserToken] = useState(null);
  let [uoms, setUoms] = useState([]);
  let [selectedUom, setSelectedUom] = useState(null);
  let [selectCartItem, setSelectCartItem] = useState(null);
  let [addonsNotes, setAddonsNotes] = useState('');
  let [disableAddToCart, setDisableAddToCart] = useState(false);
  let [productTimeAvailability, setProductTimeAvailability] = useState(true);
  let [productOffers, setProductOffers] = useState([]);

  let [wishlistData, setWishlistData] = useState([]);

  const getUserToken = () => {
    const UserToken = getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
    if (UserToken) {
      userToken = UserToken;
      setUserToken(userToken);
    } else {
      userToken = null;
      setUserToken(userToken);
    }
  }

  const setActiveLink = async (typeid) => {
    setLocalStorage(LOCAL_STORAGE_KEYS.ACTIVE_LINK, typeid)
    dispatch(changeActiveLink());
  }

  const getWishDate = async () => {
    if (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) {
      const { ack, data, discountDataOfAll } = await getRequest(API_ROUTES.WISHLIST.GET_WISHLIST_DATA);
      if (ack === 1) {
        const productOffers = discountDataOfAll?.filter((item) => item?.apply_type === 'product');
        const categoryOffers = discountDataOfAll?.filter((item) => item?.apply_type === 'category');
        const storeOffers = discountDataOfAll?.filter((item) => item?.apply_type === 'store');
        const businessOffers = discountDataOfAll?.filter((item) => item?.apply_type === "business_type");
        const products = data.map((item) => {
          let offers = productOffers?.filter((offer) =>
            offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(oof => oof.productId).includes(item?.product?.id).length > 0) ?
            productOffers?.filter((offer) => offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(oof => oof.productId).includes(item?.product?.id)) :
            productOffers?.filter((offer) => offer?.apply_on === 'all' && offer?.discounts_and_offer_relations?.map(oof => oof.categoryId).includes(item?.product?.categoryId));
          if (offers?.length === 0) {
            offers = categoryOffers?.filter((offer) =>
              offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(oof => oof.categoryId).includes(item?.product?.categoryId).length > 0) ?
              categoryOffers?.filter((offer) => offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(oof => oof.categoryId).includes(item?.product?.categoryId)) :
              categoryOffers?.filter((offer) => offer?.apply_on === 'all' && offer?.discounts_and_offer_relations?.map(oof => oof.storeId).includes(item?.product?.store?.id));
          }

          if (offers?.length === 0) {
            offers = storeOffers?.filter((offer) =>
              offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(oof => oof.storeId).includes(item?.product?.store?.id).length > 0) ?
              storeOffers?.filter((offer) => offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(oof => oof.storeId).includes(item?.product?.store?.id)) :
              storeOffers?.filter((offer) => offer?.apply_on === 'all' && offer?.discounts_and_offer_relations?.map(oof => oof.businessTypeId).includes(item?.product?.store?.businessTypeId));
          }
          if (offers?.length === 0) {
            offers = businessOffers?.filter((offer) => offer?.discounts_and_offer_relations?.map(oof => oof.businessTypeId).includes(item?.product?.store?.businessTypeId))
          }
          item.offers = offers;
          return item;
        });
        setLocalStorage(LOCAL_STORAGE_KEYS.WISHLIST, JSON.stringify(products))
        dispatch(changeWishlist());
      } else {
        dispatch(changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: msg,
          state: "error",
        }));
      }
    }
  }

  const addToWishlist = async (product_id) => {
    dispatch(changeLoader(true));
    const { ack, msg } = await getRequest(API_ROUTES.WISHLIST.ADD_WISHLIST_DATA(product_id, storeDetails.id));
    dispatch(changeLoader(false));
    if (ack === 1) {
      dispatch(changeSnackbar({
        ...snackbar,
        isOpen: true,
        message: msg,
        state: "success",
      }));
      getWishDate();
    } else {
      dispatch(changeSnackbar({
        ...snackbar,
        isOpen: true,
        message: msg,
        state: "error",
      }));
    }
  }

  const removeFromWishlist = async (product_id) => {
    dispatch(changeLoader(true));
    let wishdata = wishlist.find((item) => item.product.id === product_id);
    const { ack, msg } = await deleteRequest(API_ROUTES.WISHLIST.DELETE_WISHLIST_DATA(product_id, storeDetails.id));
    if (ack === 1) {
      dispatch(changeSnackbar({
        ...snackbar,
        isOpen: true,
        message: msg,
        state: "success",
      }));
      dispatch(changeLoader(false));
      getWishDate();
    } else {
      dispatch(changeSnackbar({
        ...snackbar,
        isOpen: true,
        message: msg,
        state: "error",
      }));
      dispatch(changeLoader(false));
    }
  }

  const selectUom = (uomId) => {
    let selectedUom = uoms.find(uom => uom.id === uomId);
    setSelectedUom(selectedUom);
    images = [];
    let imageSorted = selectedUom?.uom_images ? sortImagesBasedOnType(selectedUom?.uom_images) : []
    if (imageSorted?.length > 0) {
      imageSorted.forEach(element => {
        images.push({
          image: axiosConstant.IMAGE_BASE_URL(element?.image),
          text: axiosConstant.IMAGE_BASE_URL(element?.image),
        });
      });
    }
    setImages(images);
  }

  const getProductDetails = async () => {
    dispatch(changeLoader(true));
    const response = await getRequest(API_ROUTES.STOREFRONT.GET_PRODUCT_DETAILS(productslug, storeslug));
    const status = response.ack;
    const data = response.product;
    const store_data = response.store;
    const categories = response.categories;
    productOffers = data?.discounts_and_offer_relations?.map((item) => item?.discounts_and_offer);
    setProductOffers(productOffers)
    setActiveLink(store_data?.business_type?.slug);
    images = [];
    if (status === 1) {
      setProductDetails(data);
      if (data?.availability_start_time && data?.availability_end_time && data?.availability_start_time !== "" && data?.availability_end_time !== "") {
        // get curren time
        let today = new Date();
        const hours = today.getHours() < 10 ? `0${today.getHours()}` : today.getHours();
        const minutes = today.getMinutes() < 10 ? `0${today.getMinutes()}` : today.getMinutes();
        let time = hours + ":" + minutes;
        setProductTimeAvailability(
          parseInt(data?.availability_start_time.split(':').join('')) <= parseInt(time.split(':').join('')) &&
          parseInt(data?.availability_end_time.split(':').join('')) >= parseInt(time.split(':').join(''))
        );
      }
      uoms = data?.uoms.filter((item) => {
        if (item.manage_store_products.filter((item) => item.storeId === store_data.id).length === 0) {
          if (store_data.id === 1) {
            if (item.relationWithBase <= data?.stockQuantity) {
              return item;
            }
          } else {
            if (item.stock === 1) {
              return item;
            }
          }
        }
      });
      setUoms(uoms);
      if (cartProductIds?.includes(data?.id)) {
        selectedUom = uoms.find((item) => cartUomIds?.includes(item.id));
        setSelectedUom(selectedUom);
      } else {
        selectedUom = uoms.find((item) => item.isDefault === 1);
        if (!selectedUom) { selectedUom = uoms[0] }
        setSelectedUom(selectedUom);
      }
      if (!selectedUom) {
        selectedUom = uoms[0];
      }
      let imageSorted = selectedUom?.uom_images ? sortImagesBasedOnType(selectedUom?.uom_images) : []
      if (imageSorted?.length > 0) {
        imageSorted.forEach(element => {
          images.push({
            image: axiosConstant.IMAGE_BASE_URL(element?.image),
            text: axiosConstant.IMAGE_BASE_URL(element?.image),
          });
        });
      }
      let categoryOffers = response?.discountDataOfAll.filter((offer) => offer?.apply_type === 'category');
      let storeOffers = response?.discountDataOfAll.filter((offer) => offer?.apply_type === 'store');
      let businessoffers = response?.discountDataOfAll.filter((offer) => offer?.apply_type === 'business_type');
      let offers = []
      if (categoryOffers?.length > 0) {
        let categoryOffers2 = [];
        categories.forEach((category) => {
          categoryOffers.filter((offer) => offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(oof => oof.categoryId).includes(category?.id)).length > 0 ?
            categoryOffers2.push(categoryOffers.filter((offer) => offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(oof => oof.categoryId).includes(category?.id))) :
            [];
        });
        if (categoryOffers2?.length > 0) {
          offers = categoryOffers2;
        } else {
          offers = categoryOffers.filter((offer) => offer?.apply_on === 'all');
        }
      }
      if (offers?.length === 0 && storeOffers?.length > 0) {
        const storeOffer = storeOffers.filter((offer) => offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(oof => oof.storeId).includes(store_data?.id)).length > 0 ?
          storeOffers.filter((offer) => offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(oof => oof.storeId).includes(store_data?.id)) :
          storeOffers.filter((offer) => offer?.apply_on === 'all');
        offers = storeOffer;
      }
      if (offers?.length === 0 && businessoffers.length > 0) {
        const storeOffer = businessoffers.filter((offer) => offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(oof => oof.businessTypeId).includes(store_data?.business_type.id)).length > 0 ?
          businessoffers.filter((offer) => offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(oof => oof.businessTypeId).includes(store_data?.business_type.id)) :
          businessoffers.filter((offer) => offer?.apply_on === 'all');
        offers = storeOffer;
      }
      store_data.offers = offers;
      storeDetails = store_data;
      setStoreDetails(storeDetails)
      setImages(images);
      getWishData();
      dispatch(changeLoader(false));
      let userID = '';
      if (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) {
        const token = getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
        let decoded = jwt_decode(token);
        userID = decoded?.id;
      }
      ampli.track({
        event_type: 'ProductPageViewed',
        event_properties: {
          category: 'Docs',
          name: 'SDK Library',
          description: 'SDK Library Description',
          user_id: userID ? userID : 0,
          product_id: data.id,
        },
      })
      
    } else {
      dispatch(changeLoader(false));
      setProductDetails(null)
      dispatch(
        changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: response.msg,
          state: "error",
        })
      );
      navigate("/404");

    }
  }

  const getCartData = async () => {
    let userID = '';
    if (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) {
      const token = getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
      let decoded = jwt_decode(token);
      userID = decoded?.id;
    }
    const deviceId = getLocalStorage(LOCAL_STORAGE_KEYS.MACHINEID);
    let url = API_ROUTES.CART.GET_CART_DATA;
    url = url + `?latitude=${userAddress?.latitude}&longitude=${userAddress?.longitude}`;
    const cartResponse = await postRequest(url, {
      userId: userID,
      deviceId: deviceId
    });
    setLocalStorage(LOCAL_STORAGE_KEYS.CART, JSON.stringify(cartResponse))
    dispatch(changeCart());
    setDisableAddToCart(false)
  }

  const checkAndAddtocart = async (product_id) => {
    setDisableAddToCart(true)
    if (getLocalStorage(LOCAL_STORAGE_KEYS.CART) && cartData?.cart_items?.length > 0 && cartData.storeId !== storeDetails?.id) {
      setIsVendor(true)
      cartId = product_id;
      setCartId(cartId)
      setDisableAddToCart(false)
    } else {
      if (selectedUom?.uom_addon_relations?.length > 0) {
        setAddToCardModifier(true)
        setDisableAddToCart(false)
      } else {
        const cartitem = cartData ? cartData?.cart_items?.find(item => item.productId === product_id) : null
        if (cartitem && productDetails?.brandId === baladiData.BALADI_BRAND_Id) {
          if (cartitem.remainingQuantity < 1) {
            dispatch(changeSnackbar({
              ...snackbar,
              isOpen: true,
              message: t("cart_productMaxQtyReached"),
              state: "error",
            }));
            setDisableAddToCart(false)
            return;
          }
          if (cartitem.remainingQuantity < selectedUom.relationWithBase) {
            dispatch(changeSnackbar({
              ...snackbar,
              isOpen: true,
              message: t("cart_productMaxQtyReached"),
              state: "error",
            }));
            setDisableAddToCart(false)
            return;
          }
        }
        addtocart(product_id);
      }
    }
  }

  const emptyCart = async () => {
    let userID = '';
    if (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) {
      const token = getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
      let decoded = jwt_decode(token);
      userID = decoded?.id;
    }
    const deviceId = getLocalStorage(LOCAL_STORAGE_KEYS.MACHINEID);
    const data = {
      userId: userID,
      deviceId: (userID === '') ? deviceId : ''
    }
    const cartResponse = await postRequest(API_ROUTES.CART.EMPTY_CART, data);
    let status = cartResponse.ack;
    if (status === 1) {
      localStorage.removeItem(LOCAL_STORAGE_KEYS.CART);
      dispatch(changeCart());
      checkAndAddtocart(cartId);
    } else {
      dispatch(changeSnackbar({
        ...snackbar,
        isOpen: true,
        message: cartResponse.msg,
        state: "error",
      }));
    }
  }

  const addtocart = async (product_id, addons = '') => {
    dispatch(changeLoader(true));
    let userID = '';
    if (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) {
      const token = getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
      let decoded = jwt_decode(token);
      userID = decoded?.id;
    }
    const deviceId = getLocalStorage(LOCAL_STORAGE_KEYS.MACHINEID);
    let categoryId = 0;
    if (productDetails.product_category_relations.length > 0) {
      categoryId = productDetails.product_category_relations[0].categoryId;
    } else {
      dispatch(changeSnackbar({
        ...snackbar,
        isOpen: true,
        message: t("somethingWentWrong"),
        state: "error",
      }));
      setDisableAddToCart(false)
      dispatch(changeLoader(false));
    }
    const data = {
      productId: product_id,
      categoryId: categoryId,
      storeId: storeDetails?.id,
      uomId: selectedUom?.id,
      userId: userID,
      deviceId: (userID === '') ? deviceId : '',
      quantity: 1,
      addonIds: addons,
      specialRequest: addonsNotes
    }
    let addToCartUrl = API_ROUTES.CART.ADD_CART_DATA;
    addToCartUrl += `?latitude=${userAddress?.latitude}&longitude=${userAddress?.longitude}`;
    const cartResponse = await postRequest(addToCartUrl, data);
    const status = cartResponse.ack;
    dispatch(changeLoader(false));
    if (status === 1) {
      dispatch(changeSnackbar({
        ...snackbar,
        isOpen: true,
        message: cartResponse.msg,
        state: "success",
      }));
      getCartData();
    } else {
      dispatch(changeSnackbar({
        ...snackbar,
        isOpen: true,
        message: cartResponse.msg,
        state: "error",
      }));
      setDisableAddToCart(false)
    }
  }

  const subQuantity = async (product_id) => {
    const cartitem = cartData?.cart_items.find(item => item.productId === product_id && item.uomId === selectedUom?.id)
    if (cartitem.quantity > 1) {
      let updateCartUrl = API_ROUTES.CART.UPDATE_CART_DATA(cartitem.id);
      updateCartUrl += `?latitude=${userAddress?.latitude}&longitude=${userAddress?.longitude}`

      const response = await putRequest(updateCartUrl, {
        quantity: 1,
        action: 'minus'
      })
      let status = response.ack;
      if (status === 1) {
        getCartData();
      } else {
        dispatch(changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: response.msg,
          state: "error",
        }));
      }
    } else {
      const response = await deleteRequest(API_ROUTES.CART.DELETE_CART_DATA(cartitem.id))
      let status = response.ack;
      if (status === 1) {
        dispatch(changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: response.msg,
          state: "success",
        }));
        getCartData();
      } else {
        dispatch(changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: response.msg,
          state: "error",
        }));
      }
    }
  }

  const updateQuantityCart = async (cartItem) => {
    let updateCartUrl = API_ROUTES.CART.UPDATE_CART_DATA(cartItem);
    updateCartUrl += `?latitude=${userAddress?.latitude}&longitude=${userAddress?.longitude}`

    const response = await putRequest(updateCartUrl, {
      quantity: 1,
      action: 'add'
    })
    let status = response.ack;
    if (status === 1) {
      getCartData();
    } else {
      dispatch(changeSnackbar({
        ...snackbar,
        isOpen: true,
        message: response.msg,
        state: "error",
      }));
    }
  }

  const addQuantity = async (product_id) => {
    const cartitem = cartData?.cart_items.find(item => item.productId === product_id && item.uomId === selectedUom?.id)
    if (productDetails?.brandId === baladiData.BALADI_BRAND_Id) {
      if (cartitem.remainingQuantity < 1) {
        dispatch(changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: t("cart_productMaxQtyReached"),
          state: "error",
        }));
        return;
      }
      if (cartitem.remainingQuantity < cartitem.relationWithBase) {
        dispatch(changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: t("cart_productMaxQtyReached"),
          state: "error",
        }));
        return;
      }
    }
    if (selectedUom?.uom_addon_relations?.length > 0) {
      selectCartItem = cartitem.id;
      setSelectCartItem(selectCartItem);
      setReapetLastUseCostumise(true)
    } else {
      updateQuantityCart(cartitem.id)
    }
  }

  useEffect(() => {
    setTimeout(() => { window.scrollTo(0, 0); }, 500);
    getProductDetails();
    getWishDate();
    getUserToken();
  }, [productslug])

  const getWishData = async () => {
    if (storeDetails) {
      wishlistData = wishProductIds.filter((item) => item?.storeId === storeDetails?.id).map((item) => item?.productId);
      setWishlistData(wishlistData)
    }
  }

  useEffect(() => {
    getWishData();
  }, [wishProductIds])

  return (
    <div>
      <div className="business-category-wrapper">
        <div className="container-body">
          <Breadcrumb
            breadcrums={[
              {
                title: t("storefront_homepage"),
                haveLink: true,
                link: "/",
              },
              {
                title: storeDetails?.id !== baladiData.BALADI_STORE_Id ?
                  storeDetails?.business_type?.business_type_locales[0].name :
                  t('storefront_AllCategories'),
                haveLink: true,
                link: storeDetails?.id !== baladiData.BALADI_STORE_Id ? `/storefront/${storeDetails?.business_type?.slug}` : `/category/${storeDetails?.business_type?.slug}`,
              },
              {
                title: storeDetails?.stores_locales[0].business_name,
                haveLink: true,
                link: `/store/${storeDetails?.slug && storeDetails?.slug !== '' ? storeDetails?.slug : storeDetails?.id}`,
              },
              {
                title: `${productDetails?.products_locales[0].title} ${selectedUom?.uomName}`,
                haveLink: false,
              },
            ]}
          />
          <StoreTopBox storeDetails={storeDetails} />
          <div className="detail-wrapper">
            <Grid container columnSpacing={{ xs: 0, sm: "30px" }}>
              <Grid item xs={12} sm={5}>
                <div className="product-slider">
                  {userToken ? wishlistData?.includes(productDetails?.id) ? 
                  <img
                    src={heart}
                    alt=".."
                    className="heart-icon"
                    onClick={() => removeFromWishlist(productDetails?.id)}
                  /> : <img
                    src={heartBorder}
                    alt=".."
                    className="heart-icon"
                    onClick={() => addToWishlist(productDetails?.id)}
                  /> : null}
                  {images?.length > 0 ?
                  <SliderImage
                    data={images}
                    showDescription={false}
                    direction={"left"} />: null}
                </div>
              </Grid>
              <Grid item xs={12} sm={7}>
                <div className="">
                  <h2 className="h2">
                    {productDetails?.products_locales[0].title}{" "}{selectedUom?.uomName}
                  </h2>
                  <h3 className="h3 latter-spacing1">
                    {t("QAR")}{" "}
                    {selectedUom?.salePrice !== 0 && selectedUom?.salePrice !== selectedUom?.price
                      ? displayPrice(selectedUom?.salePrice)
                      : displayPrice(selectedUom?.price)}
                    {selectedUom?.salePrice !== 0 && selectedUom?.salePrice !== selectedUom?.price ? (
                      <span className="small-gray latter-spacing1">
                        {t("QAR")} {displayPrice(selectedUom?.price)}
                      </span>
                    ) : null}
                  </h3>
                  <div style={{ position: 'relative' }}>
                    <div className="chips-row">
                      {productOffers?.map((offer, index) =>
                        <span className="chips" key={`offerstoreDetails-${index}`} >{offer.name}</span>)}
                    </div>
                  </div>
                  {/* <span className="p3 green">{t("exclusiveofalltaxes")}</span> */}

                  {productTimeAvailability ? <>
                    {!cartUomIds?.includes(selectedUom?.id) || cartData.storeId !== storeDetails.id? (
                      productDetails?.brandId !== baladiData.BALADI_BRAND_Id ?
                        // ${storeDetails?.online_status === 1 ? "" : "disable"}
                        selectedUom?.stock === 1 ? (
                          <Button
                            size="large"
                            variant="contained"
                            className={`${disableAddToCart ? "disable" : ""}`}
                            sx={{ width: "210px", margin: "24px 0px 32px 0px", display: "block" }}
                            onClick={() => {

                              checkAndAddtocart(productDetails?.id)
                            }}
                          >{t("AddtoCart")}</Button>
                        ) : (
                          <Button
                            size="large"
                            variant="contained"
                            className={storeDetails?.online_status === 1 ? "" : "disable"}
                            sx={{ width: "210px", margin: "24px 0px 32px 0px", display: "block" }}
                          >{" "}{t("outOfStock")}</Button>
                        ) : productDetails?.stockQuantity > 0 ? (

                          cartProductIds?.includes(productDetails?.id) ?
                            <Button
                              size="large"
                              variant="contained"
                              className={storeDetails?.online_status === 1 ? "" : "disable"}
                              sx={{ width: "210px", margin: "24px 0px 32px 0px", display: "block" }}
                            >{t('cart_productAlreadyInCart')}</Button> :
                            <Button
                              size="large"
                              variant="contained"
                              className={` ${disableAddToCart ? "disable" : ""}`}
                              sx={{ width: "210px", margin: "24px 0px 32px 0px", display: "block" }}
                              onClick={() => checkAndAddtocart(productDetails?.id)}
                            >{t("AddtoCart")}</Button>


                        ) : (
                          <Button
                            size="large"
                            variant="contained"
                            className={storeDetails?.online_status === 1 ? "" : "disable"}
                            sx={{ width: "210px", margin: "24px 0px 32px 0px", display: "block" }}
                          >{" "}{t("outOfStock")}</Button>
                        )
                    ) : (
                      (productDetails?.brandId !== baladiData.BALADI_BRAND_Id && selectedUom?.stock === 0) ||
                        (productDetails?.brandId === baladiData.BALADI_BRAND_Id && productDetails?.stockQuantity === 0)
                        ?
                        <Button
                          size="large"
                          variant="contained"
                          className={storeDetails?.online_status === 1 ? "" : "disable"}
                          sx={{ width: "210px", margin: "24px 0px 32px 0px", display: "block" }}
                        >{" "}{t("outOfStock")}</Button> :
                        <Boxed display={"flex"} className={"my-32"}>
                          <div className="increase-decrease-box">
                            <Button type="button" onClick={() => subQuantity(productDetails?.id)}>
                              <img src={minusIcon} alt="..." />
                            </Button>
                            <input
                              value={
                                cartData?.cart_items.find(
                                  (item) => item.productId === productDetails?.id && item.uomId === selectedUom?.id
                                )?.totalQuantity
                              }
                              type="text"
                              readOnly={true}
                              className="product-qty"
                            />
                            <Button type="button" onClick={() => addQuantity(productDetails?.id)}>
                              <img src={plusIcon} alt="..." />
                            </Button>
                          </div>
                        </Boxed>
                    )}
                  </> : <Button
                    size="large"
                    variant="contained"
                    className={storeDetails?.online_status === 1 ? "" : "disable"}
                    sx={{ width: "210px", margin: "24px 0px 32px 0px", display: "block" }}
                  >{t("Unavailable")}</Button>}
                  {/* new add on btn start */}
                  {/* <Button
                    size="large"
                    variant="contained"
                    sx={{ width: "210px", margin: "24px 0px 32px 0px", display: "block" }}
                    onClick={() => setReapetLastUseCostumise(true)}
                  >{t("AddtoCart")}</Button> */}
                  {/* new add on btn end */}

                  {uoms.map((uom, index) => (
                    <Boxed
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      key={`uom-${index}`}
                      className={`uom-block ${selectedUom?.id === uom?.id ? "active" : ""}`}
                      onClick={() => selectUom(uom?.id)}
                    >
                      <p className="p3 latter-spacing1">
                        <span>
                          {`${t("storefront_QAR")} ${uom?.salePrice !== 0 && uom?.salePrice !== uom?.price
                            ? displayPrice(uom?.salePrice)
                            : displayPrice(uom?.price)
                            }`}
                        </span>
                        {uom?.salePrice !== 0 && uom?.salePrice !== uom?.price ? (
                          <span className="down-price">{`${t("storefront_QAR")} ${displayPrice(uom?.price)}`}</span>
                        ) : null}
                      </p>
                      <p className="p3 latter-spacing1">
                        {/* <span className="mx-24">{`${uom?.unit?.unit_locales[0].name}`} </span> */}
                        <span className="mx-24">{`${uom?.uomName}`} </span>
                        <img src={checkIcon} alt="..." className="check-icon" />
                      </p>
                    </Boxed>
                  ))}
                  <Divider />
                  <h3 className="h3 mt-32">{t("ProductDescription")}</h3>
                  <div
                    className="content-inner-html"
                    dangerouslySetInnerHTML={{ __html: productDetails?.products_locales[0].description, }}
                  ></div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <Footer />
      {isVendor ? (
        <VendorMisMatch
          CloseDialog={() => setIsVendor(false)}
          handleChange={() => {
            setIsVendor(false);
            emptyCart();
          }}
        />
      ) : null}
      {isAddToCardModifier ? (
        <AddToCardModifier
          productName={`${productDetails?.products_locales[0].title} ${selectedUom?.uomName}`}
          productPrice={selectedUom?.salePrice !== 0 && selectedUom?.salePrice !== selectedUom?.price
            ? displayPrice(selectedUom?.salePrice)
            : displayPrice(selectedUom?.price)}
          productImage={getFrontTypeImageUrl(selectedUom?.uom_images)}
          prioductId={productDetails?.id}
          uonId={selectedUom?.id}
          handleClose={() => setAddToCardModifier(false)}
          storeId={storeDetails?.id}
          addCart={(cartAddons, notes = '') => {
            setAddToCardModifier(false)
            addonsNotes = notes;
            setAddonsNotes(addonsNotes);
            addtocart(productDetails?.id, cartAddons.join(','));
          }}
          updateQuantity={() => {
            setAddToCardModifier(false)
            updateQuantityCart(selectCartItem)
          }}
          uoms={uoms}
          handleUomChange={(uomId) => {
            selectUom(uomId)
          }}
        />
      ) : null}
      {isReapetLastUseCostumise ? (
        <ReapetLastUseCostumise
          cartId={selectCartItem}
          productName={`${productDetails?.products_locales[0].title} ${selectedUom?.uomName}`}
          handleClose={() => setReapetLastUseCostumise(false)}
          repeatLast={() => {
            setReapetLastUseCostumise(false)
            updateQuantityCart(selectCartItem)
          }}
          openCustomization={() => {
            setReapetLastUseCostumise(false)
            setAddToCardModifier(true);
          }}
        />
      ) : null}
    </div>
  );
};

export default ProductDetailGrocery;
