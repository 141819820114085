import "./Products.scss"
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, MenuItem, FormControl, Select } from "@mui/material";
import { useTranslation } from "react-i18next";
import { axiosConstant } from '../../Helpers/Axios/Constants';
import { changeCart, currentCartProductIds, currentCartUomIds, currentCart } from '../../redux/reducers/cart';
import { changeWishlist, currentWishlist, currentWishProductIds } from '../../redux/reducers/wishlist';
import { changeSnackbar, currentSnackbar } from "../../redux/reducers/snackbar";
import { changeLoader } from "../../redux/reducers/loader";
import heartBorder from "../../assets/images/structure/wishlist-black.svg";
import heart from "../../assets/images/structure/wishlist-red.svg";
import minusIcon from "../../assets/images/structure/minus-icon.svg"
import plusIcon from "../../assets/images/structure/plus-icon.svg"
import { getRequest, postRequest, putRequest, deleteRequest } from '../../Helpers/Axios/Services';
import { API_ROUTES } from '../../Helpers/Axios/ApiRoutes';
import { displayPrice, getFrontTypeImageUrl, sortImagesBasedOnType } from '../../Helpers/HelperFunctions';
import { getLocalStorage, setLocalStorage, LOCAL_STORAGE_KEYS } from '../../Helpers/crypto/LocalStorage';
import jwt_decode from "jwt-decode";
import VendorMisMatch from '../../DialogBox/VendorMisMatch';
import AddToCardModifier from '../../DialogBox/AddToCardModifier';
import ReapetLastUseCostumise from '../../DialogBox/ReapetLastUseCostumise';
import Image from "../Image/Image";
import { currentAddress } from '../../redux/reducers/address';
import { ampli } from "../../ampli";
import { currentBaladiData } from '../../redux/reducers/baladiData';

const ProductBox = ({
  storeId,
  storeSlug,
  online,
  product,
  index2,
  selectedCategories = [],
  CloseDialog = () => { },
}) => {

  const userAddress = useSelector(currentAddress)
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const snackbar = useSelector(currentSnackbar);
  const baladiData = useSelector(currentBaladiData);
  let cartProductIds = useSelector(currentCartProductIds);
  let cartUomIds = useSelector(currentCartUomIds);
  let cartData = useSelector(currentCart);
  let wishlist = useSelector(currentWishlist)
  let wishProductIds = useSelector(currentWishProductIds);
  let [wishlistData, setWishlistData] = useState([]);
  let [category, setCategory] = useState(null);
  let [cartId, setCartId] = useState(0);
  let [isVendor, setIsVendor] = useState(false);
  let [userToken, setUserToken] = useState(null);
  let [uoms, setUoms] = useState(product?.uoms || []);
  let [selectedUom, setSelectedUom] = useState(null);
  let [frontImage, setFrontImage] = useState('');
  let [currentUomId, setCurrentUomId] = useState(null);
  const [isAddToCardModifier, setAddToCardModifier] = useState(false);
  const [isReapetLastUseCostumise, setReapetLastUseCostumise] = useState(false);
  let [selectCartItem, setSelectCartItem] = useState(null);
  let [disableAddToCart, setDisableAddToCart] = useState(false);
  let [addonsNotes, setAddonsNotes] = useState('');
  let [productTimeAvailability, setProductTimeAvailability] = useState(true);

  const getUserToken = () => {
    const UserToken = getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
    if (UserToken) {
      userToken = UserToken;
      setUserToken(userToken);
    } else {
      userToken = null;
      setUserToken(userToken);
    }
  }

  const getWishDate = async () => {
    if (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) {
      const { ack, data, discountDataOfAll } = await getRequest(API_ROUTES.WISHLIST.GET_WISHLIST_DATA);
      if (ack === 1) {
        const productOffers = discountDataOfAll?.filter((item) => item?.apply_type === 'product');
        const categoryOffers = discountDataOfAll?.filter((item) => item?.apply_type === 'category');
        const storeOffers = discountDataOfAll?.filter((item) => item?.apply_type === 'store');
        const businessOffers = discountDataOfAll?.filter((item) => item?.apply_type === "business_type");
        const products = data.map((item) => {
          let offers = productOffers?.filter((offer) =>
            offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(oof => oof.productId).includes(item?.product?.id).length > 0) ?
            productOffers?.filter((offer) => offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(oof => oof.productId).includes(item?.product?.id)) :
            productOffers?.filter((offer) => offer?.apply_on === 'all' && offer?.discounts_and_offer_relations?.map(oof => oof.categoryId).includes(item?.product?.categoryId));
          if (offers?.length === 0) {
            offers = categoryOffers?.filter((offer) =>
              offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(oof => oof.categoryId).includes(item?.product?.categoryId).length > 0) ?
              categoryOffers?.filter((offer) => offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(oof => oof.categoryId).includes(item?.product?.categoryId)) :
              categoryOffers?.filter((offer) => offer?.apply_on === 'all' && offer?.discounts_and_offer_relations?.map(oof => oof.storeId).includes(item?.product?.store?.id));
          }

          if (offers?.length === 0) {
            offers = storeOffers?.filter((offer) =>
              offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(oof => oof.storeId).includes(item?.product?.store?.id).length > 0) ?
              storeOffers?.filter((offer) => offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(oof => oof.storeId).includes(item?.product?.store?.id)) :
              storeOffers?.filter((offer) => offer?.apply_on === 'all' && offer?.discounts_and_offer_relations?.map(oof => oof.businessTypeId).includes(item?.product?.store?.businessTypeId));
          }
          if (offers?.length === 0) {
            offers = businessOffers?.filter((offer) => offer?.discounts_and_offer_relations?.map(oof => oof.businessTypeId).includes(item?.product?.store?.businessTypeId))
          }
          item.offers = offers;
          return item;
        });
        setLocalStorage(LOCAL_STORAGE_KEYS.WISHLIST, JSON.stringify(products))
        dispatch(changeWishlist());
      } else {
        dispatch(changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: msg,
          state: "error",
        }));
      }
    }
  }

  const addToWishlist = async (product_id) => {
    dispatch(changeLoader(true));
    const { ack, msg } = await getRequest(API_ROUTES.WISHLIST.ADD_WISHLIST_DATA(product_id, storeId));
    dispatch(changeLoader(false));
    if (ack === 1) {
      dispatch(changeSnackbar({
        ...snackbar,
        isOpen: true,
        message: msg,
        state: "success",
      }));
      getWishDate();
    } else {
      dispatch(changeSnackbar({
        ...snackbar,
        isOpen: true,
        message: msg,
        state: "error",
      }));
    }
  }

  const removeFromWishlist = async (product_id) => {
    dispatch(changeLoader(true));
    let wishdata = wishlist.find((item) => item.product.id === product_id);
    const { ack, msg } = await deleteRequest(API_ROUTES.WISHLIST.DELETE_WISHLIST_DATA(product_id, storeId));
    if (ack === 1) {
      dispatch(changeSnackbar({
        ...snackbar,
        isOpen: true,
        message: msg,
        state: "success",
      }));
      dispatch(changeLoader(false));
      getWishDate();
    } else {
      dispatch(changeSnackbar({
        ...snackbar,
        isOpen: true,
        message: msg,
        state: "error",
      }));
      dispatch(changeLoader(false));
    }
  }

  const checkAndAddtocart = async (product_id, store_id, uomId) => {

    setDisableAddToCart(true)
    if (getLocalStorage(LOCAL_STORAGE_KEYS.CART) && cartData?.cart_items?.length > 0 && cartData.storeId !== store_id) {
      setIsVendor(true)
      cartId = product_id;
      setCartId(cartId)
      setDisableAddToCart(false)
      //ampli==
      let userID = '';
      if (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) {
        const token = getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
        let decoded = jwt_decode(token);
        userID = decoded?.id;
      }
      ampli.track({
        event_type: 'CartCreated',
        event_properties: {
          category: 'Docs',
          name: 'SDK Library',
          description: 'SDK Library Description',
          user_id: userID ? userID : 0,
          product_id: product_id,
          store_id: store_id,
          uom_name: selectedUom?.uomName,
        },
      })
    } else {
      setAddToCardModifier(true)
      setDisableAddToCart(false)
    }
  }

  const getCartData = async () => {
    let userID = '';
    if (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) {
      const token = getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
      let decoded = jwt_decode(token);
      userID = decoded?.id;
    }
    const deviceId = getLocalStorage(LOCAL_STORAGE_KEYS.MACHINEID);
    let url = API_ROUTES.CART.GET_CART_DATA;
    url += `?latitude=${userAddress?.latitude}&longitude=${userAddress?.longitude}`
    const cartResponse = await postRequest(url, {
      userId: userID,
      deviceId: deviceId
    });
    setLocalStorage(LOCAL_STORAGE_KEYS.CART, JSON.stringify(cartResponse))
    dispatch(changeCart());
    setDisableAddToCart(false)
  }

  const emptyCart = async () => {
    let userID = '';
    if (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) {
      const token = getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
      let decoded = jwt_decode(token);
      userID = decoded?.id;
    }
    const deviceId = getLocalStorage(LOCAL_STORAGE_KEYS.MACHINEID);
    const data = {
      userId: userID,
      deviceId: (userID === '') ? deviceId : ''
    }
    const cartResponse = await postRequest(API_ROUTES.CART.EMPTY_CART, data);
    let status = cartResponse.ack;
    if (status === 1) {
      localStorage.removeItem(LOCAL_STORAGE_KEYS.CART);
      dispatch(changeCart());

      checkAndAddtocart(cartId, storeId, selectedUom?.id);
    } else {
      dispatch(changeSnackbar({
        ...snackbar,
        isOpen: true,
        message: cartResponse.msg,
        state: "error",
      }));
    }
  }

  const addtocart = async (product_id, store_id, uomId, addons = '') => {
    dispatch(changeLoader(true));
    let userID = '';
    if (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) {
      const token = getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
      let decoded = jwt_decode(token);
      userID = decoded?.id;
    }
    const deviceId = getLocalStorage(LOCAL_STORAGE_KEYS.MACHINEID);
    let cartProductIds = product?.product_category_relations?.length > 0 ?
      product?.product_category_relations[0]?.categoryId : 0;
    if (selectedCategories.length > 0) {
      const cartProduct = product?.product_category_relations.find((item) => selectedCategories.includes(item.categoryId));
      cartProductIds = cartProduct?.categoryId
    }
    const data = {
      productId: product_id,
      categoryId: cartProductIds,
      storeId: store_id,
      uomId: uomId,
      userId: userID,
      deviceId: (userID === '') ? deviceId : '',
      quantity: 1,
      addonIds: addons,
      specialRequest: addonsNotes
    }
    let addToCartUrl = API_ROUTES.CART.ADD_CART_DATA;
    addToCartUrl += `?latitude=${userAddress?.latitude}&longitude=${userAddress?.longitude}`
    const cartResponse = await postRequest(addToCartUrl, data);
    const status = cartResponse.ack;
    dispatch(changeLoader(false));
    if (status === 1) {
      dispatch(changeSnackbar({
        ...snackbar,
        isOpen: true,
        message: cartResponse.msg,
        state: "success",
      }));
      getCartData();
    } else {
      dispatch(changeSnackbar({
        ...snackbar,
        isOpen: true,
        message: cartResponse.msg,
        state: "error",
      }));
      setDisableAddToCart(false)
    }
  }

  const subQuantity = async (product_id) => {
    const cartitem = cartData?.cart_items.find(item => item.productId === product_id && item.uomId === selectedUom?.id)
    if (cartitem.quantity > 1) {
      let updateCartUrl = API_ROUTES.CART.UPDATE_CART_DATA(cartitem.id);
      updateCartUrl += `?latitude=${userAddress?.latitude}&longitude=${userAddress?.longitude}`

      const response = await putRequest(updateCartUrl, {
        quantity: 1,
        action: 'minus'
      })
      let status = response.ack;
      if (status === 1) {
        getCartData();
      } else {
        dispatch(changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: response.msg,
          state: "error",
        }));
      }
    } else {
      const response = await deleteRequest(API_ROUTES.CART.DELETE_CART_DATA(cartitem.id))
      let status = response.ack;
      if (status === 1) {
        dispatch(changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: response.msg,
          state: "success",
        }));
        getCartData();
      } else {
        dispatch(changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: response.msg,
          state: "error",
        }));
      }
    }
  }

  const updateQuantityCart = async (cartItemId) => {
    let updateCartUrl = API_ROUTES.CART.UPDATE_CART_DATA(cartItemId);
    updateCartUrl += `?latitude=${userAddress?.latitude}&longitude=${userAddress?.longitude}`

    const response = await putRequest(updateCartUrl, {
      quantity: 1,
      action: 'add'
    })
    let status = response.ack;
    if (status === 1) {
      getCartData();
    } else {
      dispatch(changeSnackbar({
        ...snackbar,
        isOpen: true,
        message: response.msg,
        state: "error",
      }));
    }
  }

  const addQuantity = async (product_id) => {
    const cartitem = cartData?.cart_items.find(item => item.productId === product_id && item.uomId === selectedUom?.id)
    if (product?.brandId === baladiData.BALADI_BRAND_Id) {
      if (cartitem.remainingQuantity < 1) {
        dispatch(changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: t("cart_productMaxQtyReached"),
          state: "error",
        }));
        return;
      }
      if (cartitem.remainingQuantity < cartitem.relationWithBase) {
        dispatch(changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: t("cart_productMaxQtyReached"),
          state: "error",
        }));
        return;
      }
    }
    if (selectedUom?.uom_addon_relations?.length > 0) {
      selectCartItem = cartitem.id;
      setSelectCartItem(selectCartItem);
      setReapetLastUseCostumise(true)
    } else {
      updateQuantityCart(cartitem.id)
    }
  }

  const selectUom = (uomId) => {
    let selectedUom = uoms.find(uom => uom.id === uomId);
    setSelectedUom(selectedUom);
    currentUomId = uomId;
    setCurrentUomId(currentUomId)
    if (selectedUom?.image) {
      frontImage = selectedUom?.image;
    } else if (selectedUom?.uom_images || selectedUom?.uomImages) {
      frontImage = getFrontTypeImageUrl(
        selectedUom?.uom_images
          ? selectedUom?.uom_images
          : selectedUom?.uomImages
            ? selectedUom?.uomImages
            : []
      );
    }
    setFrontImage(frontImage);
  }

  const getUom = async (product) => {
    uoms = product?.uoms.filter((item) => {
      if (item.manage_store_products.filter((item) => item.storeId === storeId).length === 0) {
        if (product?.brandId === baladiData.BALADI_BRAND_Id) {
          if (item.relationWithBase <= product?.stockQuantity) {
            return item;
          }
        } else {
          if (item.stock === 1) {
            return item;
          }
        }
      }
    });
    setUoms(uoms);
    selectedUom = uoms.find(uom => cartProductIds?.includes(product?.id) && cartUomIds?.includes(uom?.id));
    if (!selectedUom) { selectedUom = uoms.find(uom => uom.isDefault === 1); }
    if (!selectedUom) { selectedUom = uoms[0]; }
    if (selectedUom?.image) {
      frontImage = selectedUom?.image;
    } else if (selectedUom?.uom_images || selectedUom?.uomImages) {
      frontImage = getFrontTypeImageUrl(
        selectedUom?.uom_images
          ? selectedUom?.uom_images
          : selectedUom?.uomImages
            ? selectedUom?.uomImages
            : []
      );
    }
    setFrontImage(frontImage);
    selectUom(selectedUom?.id)
  }

  
  const getWishData = async () => {
    wishlistData = wishProductIds.filter((item) => item?.storeId === storeId).map((item) => item?.productId);
    setWishlistData(wishlistData)
  }

  useEffect(() => {
    getUserToken();
  }, []);

  useEffect(() => {
    getUom(product);
    if (product?.availability_start_time && product?.availability_end_time && product?.availability_start_time !== "" && product?.availability_end_time !== "") {
      let today = new Date();
      const hours = today.getHours() < 10 ? `0${today.getHours()}` : today.getHours();
      const minutes = today.getMinutes() < 10 ? `0${today.getMinutes()}` : today.getMinutes();
      let time = hours + ":" + minutes;
      setProductTimeAvailability(
        parseInt(product?.availability_start_time.split(':').join('')) <= parseInt(time.split(':').join('')) &&
        parseInt(product?.availability_end_time.split(':').join('')) >= parseInt(time.split(':').join(''))
      );
    }
  }, [storeId, online, product, index2]);

  useEffect(() => {
    let cartProductIds = product?.product_category_relations?.length > 0 ?
      product?.product_category_relations[0]?.categoryId : 0;
    if (selectedCategories.length > 0) {
      const cartProduct = product?.product_category_relations.find((item) => selectedCategories.includes(item.categoryId));
      cartProductIds = cartProduct?.categoryId
    }
    category = cartProductIds || 0;
    setCategory(category)

    getWishData();
  }, [product, selectedCategories]);

  useEffect(() => {
    getWishData();
  }, [wishProductIds]);

  return (
    <>
      <div className="product-box product228 product162" key={`product-${storeId}-${index2}`}>
        {userToken ?
          product && wishlistData?.includes(product?.id) ?
            <span className="wishlist" onClick={() => removeFromWishlist(product?.id)} style={{
              zIndex: "1"
            }}>
              <img src={heart} alt=".." className="" />
            </span> : <span className="wishlist" onClick={() => addToWishlist(product?.id)} style={{
              zIndex: "1"
            }}>
              <img src={heartBorder} alt=".." className="" />
            </span> : null}

        <Link to={`/product-detail/${storeSlug}/${product?.slug && product?.slug !== "" ? product?.slug : product?.id}`}
          onClick={CloseDialog}
        >
          <div className="text-center relative">
            <Image
              src={axiosConstant.IMAGE_BASE_URL(frontImage)}
              className="product-image"
              alt="product"
            />
            <div className="chips-row">
              {product?.offers?.filter((offerF, indexF) => indexF < 3).map((offer, index) =>
                <span className="chips" key={`offerstoreDetails-${index}`} >{offer.name}</span>)}
              {product?.offers?.length > 3 ? <span className="chips">+{product?.offers?.length - 3} {t("More")}</span> : null}
            </div>
            {/* <span className="discount-chips">10 {t("storefront_mins")}</span> */}
          </div>
          <p className="p1">
            {" "}
            {product?.products_locales
              ? product?.products_locales[0]?.title
              : product?.products_locale[0]?.title}{" "}
            {selectedUom?.uomName}
          </p>
          <div className="truncate" dangerouslySetInnerHTML={{
            __html: product?.products_locales
              ? product?.products_locales[0]?.description
              : product?.products_locale[0]?.description
          }}></div>
          <div className="product-price">
            <p className="p1">
              QAR {selectedUom?.salePrice !== 0 ? displayPrice(selectedUom?.salePrice) : displayPrice(selectedUom?.price)}

              {selectedUom?.salePrice !== 0 && selectedUom?.price !== selectedUom?.salePrice ?
                <del className="text-12 ">QAR {displayPrice(selectedUom?.price)}</del> : null}

            </p>
          </div>
        </Link>

        {/* // ${!online ? "disable" : ""}  */}
        {productTimeAvailability ? <div className={`product-bottom`}>
          {!cartUomIds?.includes(selectedUom?.id) || cartData.storeId !== storeId? (
            product?.brandId !== baladiData.BALADI_BRAND_Id ?
              selectedUom?.stock === 1 ?
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => checkAndAddtocart(product?.id, storeId, selectedUom?.id)}
                  className={`${disableAddToCart ? "disable" : ""}`}
                  fullWidth
                >
                  {t("addToCart")}
                </Button>


                : <Button variant="outlined" size="small" className={`disable`} fullWidth>{t("outOfStock")}</Button>
              : product?.stockQuantity > 0 ?

                cartProductIds?.includes(product?.id) ?
                  <Button variant="outlined" size="small" className={`disable`} fullWidth>{t("cart_productAlreadyInCart")}</Button> :
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => checkAndAddtocart(product?.id, storeId, selectedUom?.id)}
                    className={`${disableAddToCart ? "disable" : ""}`}
                    fullWidth
                  >{t("addToCart")}</Button> :
                <Button variant="outlined" size="small" className={`disable`} fullWidth>{t("outOfStock")}</Button>

          ) : (
            (product?.brandId === baladiData.BALADI_BRAND_Id && selectedUom?.stock === 0) ||
              (product?.brandId === baladiData.BALADI_BRAND_Id && product?.stockQuantity === 0) ?
              <Button variant="outlined" size="small" className={`disable`} fullWidth>{t("outOfStock")}</Button> :
              <div className="increase-decrease-box height32 w-100">
                <Button type="button" onClick={() => subQuantity(product?.id)}>
                  <img src={minusIcon} alt="..." />
                </Button>
                <input
                  value={
                    cartData?.cart_items.find(
                      (item) => item?.productId === product?.id && item?.uomId === selectedUom?.id
                    )?.totalQuantity
                  }
                  readOnly={true}
                  className="product-qty"
                />
                <Button type="button" onClick={() => addQuantity(product?.id)}>
                  <img src={plusIcon} alt="..." />
                </Button>
              </div>
          )}
        </div> : <Button variant="outlined" size="small" className={`disable`} fullWidth>{t("Unavailable")}</Button>}
      </div>
      {isVendor ? (
        <VendorMisMatch
          CloseDialog={() => setIsVendor(false)}
          handleChange={() => {
            setIsVendor(false);
            emptyCart();
          }}
        />
      ) : null}

      {isAddToCardModifier ? (
        <AddToCardModifier
          productName={`${product?.products_locales ? product?.products_locales[0]?.title : product?.products_locale[0]?.title}  ${selectedUom?.uomName}`}
          productPrice={selectedUom?.salePrice !== 0 ? displayPrice(selectedUom?.salePrice) : displayPrice(selectedUom?.price)}
          productImage={frontImage}
          uonId={selectedUom?.id}
          prioductId={product?.id}
          handleClose={() => setAddToCardModifier(false)}
          uoms={uoms}
          storeId={storeId}
          addCart={(cartAddons, notes = '') => {
            setAddToCardModifier(false)
            addonsNotes = notes;
            setAddonsNotes(addonsNotes);
            addtocart(product?.id, storeId, selectedUom?.id, cartAddons.join(','));
          }}
          updateQuantity={() => {
            setAddToCardModifier(false)
            updateQuantityCart(selectCartItem)
          }}
          handleUomChange={(uomId) => {
            selectUom(uomId)
          }}
        />
      ) : null}
      {isReapetLastUseCostumise ? (
        <ReapetLastUseCostumise
          cartId={selectCartItem}
          productName={`${product?.products_locales ? product?.products_locales[0]?.title : product?.products_locale[0]?.title}  ${selectedUom?.uomName}`}
          handleClose={() => setReapetLastUseCostumise(false)}
          repeatLast={() => {
            setReapetLastUseCostumise(false)
            updateQuantityCart(selectCartItem)
          }}
          openCustomization={() => {
            setReapetLastUseCostumise(false)
            setAddToCardModifier(true);
          }}
        />
      ) : null}

    </>
  );

}

export default ProductBox;