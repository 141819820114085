/**
 * @file StatusBadge.js
 * @description This component is used to show status badge.
 * @exports StatusBadge component
 * @param {string} status - status of order
 */

import { useTranslation } from "react-i18next";

const WalletStatus = ({ status }) => {

  const { t } = useTranslation();

  return (
    <>
      {status === 'pending' ? <span className=''>{t('wallet_pending')}</span> : null}
      {status === 'failed' ? <span className=''>{t('wallet_failed')}</span> : null}
    </>
  );
}

export default WalletStatus;