import "./CardCheckout.scss";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import minusIcon from "../../assets/images/structure/minus-icon.svg";
import plusIcon from "../../assets/images/structure/plus-icon.svg";
import deleteIcon from "../../assets/images/structure/delete-black.svg";
import { Boxed } from "../../Styles-Elements/Box";
import { useTranslation } from "react-i18next";
import RemoveItem from "../../DialogBox/RemoveItem";
import jwt_decode from "jwt-decode";
import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from "../../Helpers/Axios/Services";
import { API_ROUTES } from "../../Helpers/Axios/ApiRoutes";
import { changeSnackbar, currentSnackbar } from "../../redux/reducers/snackbar";
import { currentAddress } from '../../redux/reducers/address';
import { changeCart } from "../../redux/reducers/cart";
import { changeLoader } from "../../redux/reducers/loader";
import { axiosConstant } from "../../Helpers/Axios/Constants";
import {
  getLocalStorage,
  setLocalStorage,
  LOCAL_STORAGE_KEYS,
} from "../../Helpers/crypto/LocalStorage";
import {
  displayPrice,
  getFrontTypeImageUrl,
} from "../../Helpers/HelperFunctions";
import Image from "../../global-modules/Image/Image";
import AddToCardModifier from "../../DialogBox/AddToCardModifier";
import ReapetLastUseCostumise from '../../DialogBox/ReapetLastUseCostumise';
import { ampli } from "../../ampli";
import { currentBaladiData } from "../../redux/reducers/baladiData";

const CartItem = ({ cartitem, index, userData, addSpecialRequest }) => {

  const userAddress = useSelector(currentAddress)
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const snackbar = useSelector(currentSnackbar);
  const baladiData = useSelector(currentBaladiData);
  const uoms = cartitem?.product?.uoms;
  let [uom, setUom] = useState(null);
  let [cartRemoveId, setCartRemoveId] = useState(null);
  let [userToken, setUserToken] = useState(null);
  const [openRemoveItem, setOpenRemoveItem] = useState(false);
  let [isLoggedIn, setIsLoggedIn] = useState(false);
  let [isOutOfStock, setIsOutOfStock] = useState(false);
  const [isAddToCardModifier, setAddToCardModifier] = useState(false);
  const [isUpdateCardModifier, setUpdateCardModifier] = useState(false);
  const [isReapetLastUseCostumise, setReapetLastUseCostumise] = useState(false);
  let [selectCartItem, setSelectCartItem] = useState(null);
  let [addonsNotes, setAddonsNotes] = useState('');
  let [addonNamse, setAddonNames] = useState('');
  let [itemFinalPrice, setItemFinalPrice] = useState(0);
  let [itemPrice, setItemPrice] = useState(0);
  let [itemStrikeOffPrice, setItemStrikeOffPrice] = useState(0);
  let [changesAddons, setChangesAddons] = useState(0);
  let [unAvailableAddonsId, setUnAvailableAddonsId] = useState([]);


  const getCartData = async () => {
    let userID = "";
    if (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) {
      const token = getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
      let decoded = jwt_decode(token);
      userID = decoded?.id;
    }
    const deviceId = getLocalStorage(LOCAL_STORAGE_KEYS.MACHINEID);
    let url = API_ROUTES.CART.GET_CART_DATA;
    url += `?latitude=${userAddress?.latitude}&longitude=${userAddress?.longitude}`
    const cartResponse = await postRequest(url, {
      userId: userID,
      deviceId: deviceId,
    });
    const removeitem = cartResponse.data.cart_items.filter((item) => item.quantity === 0);
    removeitem.forEach(element => {
      removeFromCart(element.id)
    });
    setLocalStorage(LOCAL_STORAGE_KEYS.CART, JSON.stringify(cartResponse));
    dispatch(changeCart());
    checkAddonAvailability();
  };

  const checkAddonAvailability = () => {
    let unAvailableAddons = [];
    let changedAddons = [];
    let minimumQuantity = [];
    let maximumQuantity = [];

    let selectedAddons = [];
    if (cartitem?.addonIds && cartitem?.addonIds !== "") {
      if (uom?.addons?.length > 0) {
        selectedAddons = cartitem?.addonIds?.split(',');
        uom?.addons.forEach(addon => {
          if (addon?.addon_items?.length > 0) {
            addon?.addon_items?.forEach(addon_item => {
              if (selectedAddons?.includes(addon_item?.id.toString())) {
                const removeIndex = selectedAddons.indexOf(addon_item?.id.toString());
                selectedAddons.splice(removeIndex, 1);
              }
            });
          }
        });
        unAvailableAddons = selectedAddons;
      } else {
        unAvailableAddons = cartitem?.addonIds?.split(',');
      }
    }
    unAvailableAddonsId = unAvailableAddons;
    setUnAvailableAddonsId(unAvailableAddonsId)
    let addOns = cartitem?.addonitems;
    if (addOns?.length > 0) {
      addOns?.map(addon => {
        const oldAddon = cartitem?.itemLogs?.addonsItemData.find(addonItem => addonItem.id === addon.id);
        if (oldAddon?.price !== addon.price) {
          changedAddons.push(addon.id)
        }
        return addon;
      });
    }
    changedAddons.push(...unAvailableAddons);

    let getUniqueAddonId = cartitem?.addonitems?.map(addon => addon.addonId).filter((value, index, self) => self.indexOf(value) === index);
    uom?.addons?.map(addon => {
      if (getUniqueAddonId?.includes(addon.id)) {
        const selectedAddons = cartitem?.addonitems?.filter(addonItem => addonItem.addonId === addon.id);
        if (addon?.minimum_item > selectedAddons?.length) {
          minimumQuantity.push(addon?.addons_locales[0]?.name)
        }
        if (addon?.maximum_item !== 0 && addon?.maximum_item < selectedAddons?.length) {
          maximumQuantity.push(addon?.addons_locales[0]?.name)
        }
      }
    });
    changedAddons.push(...minimumQuantity, ...maximumQuantity);
    changesAddons = changedAddons?.length;
    setChangesAddons(changesAddons);
  };

  const getUserToken = async () => {
    const UserToken = getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
    if (UserToken) {
      userToken = UserToken;
      setUserToken(userToken);
      isLoggedIn = true;
      setIsLoggedIn(isLoggedIn);
    } else {
      userToken = null;
      setUserToken(userToken);
    }
  };

  const handleRemoveItemOnly = async () => {
    removeFromCart(cartRemoveId);
  };

  const handleRemoveItem = async () => {
    dispatch(changeLoader(true));
    const product_id = cartitem?.productId;
    const { ack, msg } = await getRequest(
      API_ROUTES.WISHLIST.ADD_WISHLIST_DATA(product_id, cartitem?.storeId)
    );
    if (ack === 1) {
      const response = await deleteRequest(
        API_ROUTES.CART.DELETE_CART_DATA(cartRemoveId)
      );
      const ack2 = response?.ack;
      const msg2 = response?.msg;
      dispatch(changeLoader(false));
      if (ack2 === 1) {
        getCartData();
        dispatch(
          changeSnackbar({
            ...snackbar,
            isOpen: true,
            message: msg2,
            state: "success",
          })
        );
      } else {
        getCartData();
        dispatch(
          changeSnackbar({
            ...snackbar,
            isOpen: true,
            message: msg2,
            state: "error",
          })
        );
      }
    } else {
      getCartData();
      dispatch(
        changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: msg,
          state: "error",
        })
      );
    }
  };

  const removeFromCart = async (cartitem_id) => {
    const response = await deleteRequest(
      API_ROUTES.CART.DELETE_CART_DATA(cartitem_id)
    );
    let status = response.ack;
    if (status === 1) {
      dispatch(
        changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: response.msg,
          state: "success",
        })
      );
      getCartData();
    } else {
      dispatch(
        changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: response.msg,
          state: "error",
        })
      );
    }
  };

  const subQuantity = async (product_id) => {
    if (cartitem?.quantity > 1) {
      let updateCartUrl = API_ROUTES.CART.UPDATE_CART_DATA(cartitem?.id);
      updateCartUrl += `?latitude=${userAddress?.latitude}&longitude=${userAddress?.longitude}`

      const response = await putRequest(
        updateCartUrl,
        {
          quantity: 1,
          action: "minus",
        }
      );
      let status = response.ack;
      if (status === 1) {
        getCartData();
        //ampli
        let userID = '';
        if (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) {
          const token = getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
          let decoded = jwt_decode(token);
          userID = decoded?.id;
        }
        ampli.track({
          event_type: 'ProductUpdate',
          event_properties: {
            category: 'Docs',
            name: 'SDK Library',
            description: 'SDK Library Description',
            user_id: userID,
            cart_id: cartitem.cartId,
            action: "minus",
          },
        });
      } else {
        dispatch(
          changeSnackbar({
            ...snackbar,
            isOpen: true,
            message: response.msg,
            state: "error",
          })
        );
      }
    } else {
      removeFromCart(cartitem?.id);
    }
  };

  const updateQuantityCart = async (cartItemId) => {
    let updateCartUrl = API_ROUTES.CART.UPDATE_CART_DATA(cartItemId);
    updateCartUrl += `?latitude=${userAddress?.latitude}&longitude=${userAddress?.longitude}`

    const response = await putRequest(
      updateCartUrl,
      { quantity: 1, action: "add", }
    );
    let status = response.ack;
    if (status === 1) {
      getCartData();
      //ampli----
      let userID = '';
      if (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) {
        const token = getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
        let decoded = jwt_decode(token);
        userID = decoded?.id;
      }
      ampli.track({
        event_type: 'ProductUpdate',
        event_properties: {
          category: 'Docs',
          name: 'SDK Library',
          description: 'SDK Library Description',
          user_id: userID,
          cart_id: cartitem.cartId,
          action: "add",
        },
      });
    } else {
      dispatch(
        changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: response.msg,
          state: "error",
        })
      );
    }
  }

  const addQuantity = async () => {
    uom = uoms?.find((uom) => uom.id === cartitem?.uomId);
    if (cartitem?.product?.brandId === baladiData.BALADI_BRAND_Id) {
      if (cartitem.remainingQuantity < 1) {
        dispatch(changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: t("cart_productMaxQtyReached"),
          state: "error",
        }));
        return;
      }
      if (cartitem.remainingQuantity < cartitem.relationWithBase) {
        dispatch(changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: t("cart_productMaxQtyReached"),
          state: "error",
        }));
        return;
      }
    }
    updateQuantityCart(cartitem?.id)
  };

  const updateCartAddons = async (cartitem, addons = '') => {
    dispatch(changeLoader(true));
    const data = {
      addonIds: addons,
      specialRequest: addonsNotes
    };
    const response = await putRequest(API_ROUTES.CART.UPDATE_ADDON_IN_CART(cartitem?.id), data);
    dispatch(changeLoader(false));
    if (response?.ack === 1) {
      const response = await postRequest(API_ROUTES.CART.CART_REFRESH, {
        userId: userData?.id,
        deviceId: getLocalStorage(LOCAL_STORAGE_KEYS.MACHINEID)
      });

      dispatch(changeSnackbar({
        ...snackbar,
        isOpen: true,
        message: response?.msg,
        state: "success",
      }));

      getCartData();
    } else {
      if (response?.msg !== '') {
        dispatch(changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: response?.msg,
          state: "error",
        }));
      } else if (response?.errMsg?.length > 0) {
        response?.errMsg.forEach(element => {
          for (const [key, value] of Object.entries(element)) {
            dispatch(changeSnackbar({
              ...snackbar,
              isOpen: true,
              message: value,
              state: "error",
            }));
          }
        });
      }
    }
  }


  const addtocart = async (product_id, store_id, uomId, addons = '') => {

    dispatch(changeLoader(true));
    let userID = '';
    if (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) {
      const token = getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
      let decoded = jwt_decode(token);
      userID = decoded?.id;
    }
    const deviceId = getLocalStorage(LOCAL_STORAGE_KEYS.MACHINEID);
    const data = {
      productId: product_id,
      storeId: store_id,
      uomId: uomId,
      userId: userID,
      deviceId: (userID === '') ? deviceId : '',
      quantity: 1,
      addonIds: addons,
      specialRequest: addonsNotes
    }
    let addToCartUrl = API_ROUTES.CART.ADD_CART_DATA;
    addToCartUrl += `?latitude=${userAddress?.latitude}&longitude=${userAddress?.longitude}`
    const cartResponse = await postRequest(addToCartUrl, data);
    const status = cartResponse.ack;
    dispatch(changeLoader(false));
    if (status === 1) {
      dispatch(changeSnackbar({
        ...snackbar,
        isOpen: true,
        message: cartResponse.msg,
        state: "success",
      }));
      getCartData();
    } else {
      dispatch(changeSnackbar({
        ...snackbar,
        isOpen: true,
        message: cartResponse.msg,
        state: "error",
      }));
    }
  }

  useEffect(() => {
    if (uoms) {

      uom = uoms?.find((uom) => uom.id === cartitem?.uomId);
      const thisUom = cartitem?.product?.uoms?.find(
        (upm) => upm.id === cartitem?.uomId
      );
      if (!thisUom) { setIsOutOfStock(true); }
      if (cartitem?.product?.brandId === baladiData.BALADI_BRAND_Id) {
        if (cartitem?.product?.stockQuantity === 0) {
          setIsOutOfStock(true);
        } else {
          setIsOutOfStock(false);
        }
      } else {
        if (thisUom?.stock === 0) {
          setIsOutOfStock(true);
        } else {
          setIsOutOfStock(false);
        }
      }
      if (cartitem?.isProductInactive) { setIsOutOfStock(true); }
      // if (cartitem?.categoryStatus === 'inactive') { setIsOutOfStock(true); }
      // uom = cartitem?.itemLogs?.uomdata
      setUom(uom);
      let addOnTotal = 0;
      if (cartitem?.addonitems?.length > 0) {
        const addonsItems = cartitem?.addonitems;
        // const addonsItems = cartitem?.itemLogs?.addonsItemData
        addonNamse = addonsItems?.map((addon) => addon?.addon_items_locales[0]?.name).join(", ");
        setAddonNames(addonNamse)
        addonsItems?.forEach((addon) => {
          addOnTotal += addon?.price;
        });
      }

      itemPrice = uom?.salePrice !== 0 && uom?.salePrice !== uom?.price ?
        (uom?.salePrice + addOnTotal) :
        (uom?.price + addOnTotal);
      setItemPrice(itemPrice)
      itemFinalPrice = itemPrice * cartitem?.quantity;
      setItemFinalPrice(itemFinalPrice)
      itemStrikeOffPrice = uom?.salePrice !== 0 && uom?.salePrice !== uom?.price ?
        uom?.price + addOnTotal : 0;
      setItemStrikeOffPrice(itemStrikeOffPrice)
      checkAddonAvailability();
    }
  }, [cartitem, uoms]);

  useEffect(() => {
    getUserToken();
  }, []);

  return (
    <>
      <div
        className={`product-card-box ${!isOutOfStock ? "" : "our-of-stock"}`}
        key={`cart-item-${index}`}
      >
        <div className={`d-flex ${!isOutOfStock ? "" : "disable"}`}>
          <div className="d-flex relative non-disable">

            {isOutOfStock ? <h6 className="h6 latter-spacing1 normal"
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                color: "#ff0000",
                fontWeight: "normal",
                fontSize: "12px",
                margin: "0",
                padding: "10px 5px",
              }}
            >{t("outOfStock")}</h6> : null}
            <Image
              src={axiosConstant.IMAGE_BASE_URL(
                getFrontTypeImageUrl(uom?.uom_images)
              )}
              alt=".."
              className="img48"
            />
          </div>

          <div className="relative">
            <h6 className="h6 width237 latter-spacing1">
              {cartitem?.product?.products_locales ? cartitem?.product?.products_locales[0]?.title : ''} {uom?.uomName}{" "}

            </h6>
            <h6 className="price-h6">
              {t("QAR")}{" "}{displayPrice(itemPrice)}

              {itemStrikeOffPrice > 0 ? (
                <span className="small-red">
                  {" "}
                  <del>
                    {t("QAR")} {displayPrice(itemStrikeOffPrice)}
                  </del>{" "}
                </span>
              ) : null}
            </h6>
            {addonNamse !== '' ? <p className="addon-text">{addonNamse}</p> : null}
            {uom?.addons?.length > 0 ? <p
              className="customise-text"
              onClick={() => setUpdateCardModifier(true)}
            >
              {t('cart_Customize')}
            </p> : null}
            {cartitem?.specialRequest === '' ?
              <p className="customise-text" style={{ textDecoration: 'none' }}>{t('cart_anySpecialRequest')} <span className="redspan" onClick={() => {
                addSpecialRequest(cartitem?.id, 'item')
              }} >{t('cart_addHere')}</span></p> :
              <p className="customise-text" style={{ textDecoration: 'none' }}>{cartitem?.specialRequest} <span className="redspan" onClick={() => {
                addSpecialRequest(cartitem?.id, 'item')
              }} >{t('cart_edit')}</span></p>}
            {cartitem?.offers?.length > 0 ? <div className='chips-row' style={{
              position: "relative",
              justifyContent: "flex-start",
              padding: "0px",
            }}>
              <span className="discount-chips" >{cartitem?.offers[0].name}</span>
            </div> : null}
            {cartitem?.product?.brandId === baladiData.BALADI_BRAND_Id && cartitem?.remainingQuantity < 0 ?
              <p className="redspan">
                {t("cart_stockChanged", {
                  quantity: Math.floor(cartitem?.totalBaseQuantity / cartitem?.relationWithBase),
                  remove_item: Math.ceil(Math.abs(cartitem?.remainingQuantity) / cartitem?.relationWithBase)
                })}
              </p> : null}
            {changesAddons > 0 ?
              <p className="redspan">
                {t("cart_addonsChanged")}
              </p> : null}



          </div>
        </div>

        <Boxed display={`flex ${!isOutOfStock ? "" : "disable"}`}>
          <div className={`increase-decrease-box height32 ${!isOutOfStock ? "" : "disable"}`}>
            <Button
              size="small"
              type="button"
              onClick={() => subQuantity(cartitem?.productId)}
              className={cartitem?.quantity === 1 ? "no-event-disabled" : ""}
            >
              <img src={minusIcon} alt="minusIcon" />
            </Button>
            <input
              value={cartitem?.quantity}
              readOnly={true}
              className="product-qty"
            />
            <Button size="small" type="button" onClick={() => addQuantity()}>
              <img src={plusIcon} alt="plusIcon" />
            </Button>
          </div>
        </Boxed>

        <div className="d-flex">
          {
            (cartitem?.product?.brandId === baladiData.BALADI_BRAND_Id && cartitem?.product?.stockQuantity > 0) ||
              (cartitem?.product?.brandId !== baladiData.BALADI_BRAND_Id && uom?.stock === 1)
              ? (
                <h6 className="h6 latter-spacing1 normal">
                  {t("QAR")}{" "} {displayPrice(itemFinalPrice)}
                </h6>
              ) : null}
          {/* this is my offer code please don't remove code  */}
          {/* <h6 className="h6 latter-spacing1 normal text-secondary">Free</h6> */}
          <img
            src={deleteIcon}
            alt="deleteIcon"
            className="delete-icon"
            onClick={() => {
              if (userToken) {
                cartRemoveId = cartitem?.id;
                setCartRemoveId(cartRemoveId);
                removeFromCart(cartitem?.id);
                // setOpenRemoveItem(true);
              } else {
                removeFromCart(cartitem?.id);
              }
            }}
          />
        </div>
        {openRemoveItem ? (
          <RemoveItem
            CloseDialog={() => {
              setOpenRemoveItem(false);
            }}
            RemoveItem={() => {
              setOpenRemoveItem(false);
              handleRemoveItem();
            }}
            RemoveItemOnly={() => {
              setOpenRemoveItem(false);
              handleRemoveItemOnly();
            }}
          />
        ) : null}
      </div>

      {isUpdateCardModifier ? (
        <AddToCardModifier
          unAvailableAddonsId={unAvailableAddonsId.map(id => parseInt(id))}
          productName={`${cartitem?.product?.products_locales[0]?.title} ${uom?.uomName}`}
          productPrice={uom?.salePrice !== 0 && uom?.salePrice !== uom?.price
            ? displayPrice(uom?.salePrice)
            : displayPrice(uom?.price)}
          prioductId={cartitem?.product?.id}
          productImage={getFrontTypeImageUrl(uom?.uom_images)}
          uonId={uom?.id}
          cartItemId={cartitem?.id}
          handleClose={() => setUpdateCardModifier(false)}
          storeId={cartitem?.product?.storeId}
          addCart={(cartAddons, notes = '') => {
            setUpdateCardModifier(false)
            addonsNotes = notes;
            setAddonsNotes(addonsNotes);
            updateCartAddons(cartitem, cartAddons.join(','));
          }}
          updateQuantity={() => {
          }}
        />
      ) : null}

      {isAddToCardModifier ? (
        <AddToCardModifier
          productName={`${cartitem?.product?.products_locales[0]?.title} ${uom?.uomName}`}
          productPrice={uom?.salePrice !== 0 && uom?.salePrice !== uom?.price
            ? displayPrice(uom?.salePrice)
            : displayPrice(uom?.price)}
          prioductId={cartitem?.product?.id}
          productImage={getFrontTypeImageUrl(uom?.uom_images)}
          uonId={uom?.id}
          storeId={cartitem?.product?.storeId}
          handleClose={() => setAddToCardModifier(false)}
          addCart={(cartAddons, notes = '') => {
            setAddToCardModifier(false)
            addonsNotes = notes;
            setAddonsNotes(addonsNotes);
            addtocart(cartitem?.product?.id, cartitem?.product?.storeId, uom?.id, cartAddons.join(','));
          }}
          updateQuantity={() => {
            setAddToCardModifier(false)
            updateQuantityCart(selectCartItem)
          }}
        />
      ) : null}
      {isReapetLastUseCostumise ? (
        <ReapetLastUseCostumise
          cartId={selectCartItem}
          productName={`${cartitem?.product?.products_locales[0]?.title} ${uom?.uomName}`}
          handleClose={() => setReapetLastUseCostumise(false)}
          repeatLast={() => {
            setReapetLastUseCostumise(false)
            updateQuantityCart(selectCartItem)
          }}
          openCustomization={() => {
            setReapetLastUseCostumise(false)
            setAddToCardModifier(true);
          }}
        />
      ) : null}
    </>
  );
};

export default CartItem;
