/**
 * @file Logout.js
 * @description Logout Dialog Box
 * @exports Logout - Dialog Box
 * @exports CloseDialog - Function to close the dialog box
 * @imports logOutSuccess - Function if user is logged out successfully
 */

import { Grid, Dialog, DialogContent, Button } from '@mui/material';
import modalClose from "../assets/images/structure/close-black.svg";
import { LOCAL_STORAGE_KEYS, getLocalStorage } from '../Helpers/crypto/LocalStorage';
import { useDispatch, useSelector } from "react-redux";
import { changeSnackbar, currentSnackbar } from "../redux/reducers/snackbar";
import { changeAddress } from '../redux/reducers/address';
import { changeWishlist } from '../redux/reducers/wishlist';
import { changeCart } from '../redux/reducers/cart';
import { useTranslation } from "react-i18next";
import { ampli } from "../ampli";
import jwt_decode from "jwt-decode";
import moengage from '@moengage/web-sdk';

const Logout = ({ CloseDialog, logOutSuccess }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const snackbar = useSelector(currentSnackbar);


  const handleLogout = () => {

    //ampli===
    const token = getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
    let decoded = jwt_decode(token);
    ampli.track({
      event_type: 'LoggedOut',
      event_properties: {
        category: 'Docs',
        name: 'SDK Library',
        description: 'SDK Library Description',
        user_id: decoded?.id,
        contact_number: decoded?.contact_number,
      },
    })
    const lang = localStorage.getItem('i18nextLng')
    localStorage.removeItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.ACTIVE_LINK);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.DEFAULT_ADDRESS);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.TOTAL_ADDRESS);
    localStorage.setItem('i18nextLng', lang)
    moengage.destroy_session();
    dispatch(changeAddress());
    dispatch(changeWishlist());
    dispatch(changeCart());
    dispatch(
      changeSnackbar({
        ...snackbar,
        isOpen: true,
        message: t('userLoggedOut'),
        state: "success",
      })
    );
    logOutSuccess()
  }

  return (
    <Dialog open={true} onClose={CloseDialog} className="main-modal theme-dialog">
      <img src={modalClose} alt="close" onClick={CloseDialog} className='modal-close' />
      <DialogContent>
        <h2 className='h3' style={{ marginBottom: '8px' }}>{t('logout')}</h2>
        <p className='p3' style={{ marginBottom: '12px', fontSize: '14px' }}>{t('SureToLogout')}</p>
        <Grid item xs={12} textAlign="center" display={'flex'} justifyContent={'flex-end'}>
          <Button type='button' size="medium" variant="text" color="primary" onClick={handleLogout}>{t('Yes')}</Button>
          <Button type='button' size="medium" variant="text" color="error" onClick={CloseDialog}>{t('No')}</Button>
        </Grid>
      </DialogContent>
    </Dialog>
  )
};

export default Logout;