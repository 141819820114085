import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import modalClose from "../../assets/images/structure/close-black.svg"

import { useTranslation } from "react-i18next";

const RemoveAddress = ({ handleClose, removeAddress }) => {
  const { t } = useTranslation();
  return (
    <Dialog open={true} onClose={handleClose} className="main-modal theme-dialog">
      <img src={modalClose} alt="close" onClick={handleClose} className='modal-close' />
      <DialogContent>
        <h2 className='h3' style={{ marginBottom: '10px' }}>{t('Address_RemoveAddress')}</h2>
        <p className='p3' style={{ marginBottom: '12px', fontSize: '14px' }}>{t('Address_Areyousureyouwanttoremovethisaddress')}</p>
        <Grid item xs={12} textAlign="center" display={'flex'} justifyContent={'flex-end'}>
          <Button type='button' size="medium" variant="text" color="primary" onClick={removeAddress}>{t('Address_Yes')}</Button>
          <Button type='button' size="medium" variant="text" color="error" onClick={handleClose}>{t('Address_No')}</Button>
        </Grid>
      </DialogContent>
    </Dialog>
  )
};

export default RemoveAddress;