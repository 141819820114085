/**
 * @file ComingSoon.js
 * @description This component is used to show no data found page.
 * @exports ComingSoon component
 * @imports text - text to be shown on the page
 */

import "./ComingSoon.scss"
import nodata from "../../assets/images/structure/coming-soon.svg"
import { useTranslation } from "react-i18next";

const ComingSoon = () => {

  const { t } = useTranslation();

  return (
    <div className='empty-wishlist-block'>
      <img src={nodata} alt={t('ComingSoon')} />
      <p className='p2' style={{
        fontSize: '18px',color: '#FF7815',fontWeight: '500', marginTop: '16px'
      }}>{t('ComingSoon')}</p>
    </div>
  );
}

export default ComingSoon;