/**
 * @file SearchCategoryModal.js
 * @description Search Category Dialog Box
 * @exports SearchCategoryModal - Dialog Box
 * @exports CloseDialog - Function to close the dialog box=
 */

import React, {useState, useEffect} from "react";
import {
  Grid,
  Dialog,
  DialogContent,
  Button,
  List,
  ListItemButton,
  TextField,
  InputAdornment,
} from "@mui/material";
import { Search as SearchIcon, } from "@mui/icons-material";
import modalClose from "../../assets/images/structure/close-black.svg";
import distanceIcon from "../../assets/images/structure/distance.svg";
import heartBorder from "../../assets/images/structure/wishlist-black.svg";
import product1 from "../../assets/images/products/product1.jpg";
import { Boxed } from "../../Styles-Elements/Box";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Ratings from "../../global-modules/Rating/Ratings";
import Image from "../../global-modules/Image/Image";
import Slide from '@mui/material/Slide';
import { axiosConstant } from '../../Helpers/Axios/Constants';
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "redux/reducers/loader";
import { getRequest } from '../../Helpers/Axios/Services';
import { API_ROUTES } from '../../Helpers/Axios/ApiRoutes';
import { currentAddress } from '../../redux/reducers/address';
import { getRating, getDistanceFromLatLonInKm } from "../../Helpers/HelperFunctions";
import { currentBaladiData } from "redux/reducers/baladiData";
import ProductBox from '../../global-modules/Product/ProductBox';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});


const SearchCategoryModal = ({ CloseDialog, businessTypes }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userAddress = useSelector(currentAddress)
  const baladiData = useSelector(currentBaladiData)
  const [activeOption, setActiveOption] = useState('grocery');
  const [search, setSearch] = useState('');
  let [storeProducts, setStoreProducts] = useState([]);
  let [stores, setStores] = useState([]);
  let [discountDataOfAll, setDiscountDataOfAll] = useState([]);
  let [categoryOffers, setCategoryOffers] = useState([])

  const getStoresFromSlug = async () => {
    dispatch(changeLoader(true));
    let url = API_ROUTES.STOREFRONT.GET_STORES(activeOption);
    url += `?limit=50&page=1`
    url += search.trim() !== '' ? `&search=${search.trim()}` : '';
    url += `&latitude=${userAddress?.latitude}&longitude=${userAddress?.longitude}`
    const response = await getRequest(url)
    const allStores = response.stores.map((store) => {
      let item = store;
      let offers = store?.discounts_and_offer_relations?.map((offer) => offer.discounts_and_offer);
      discountDataOfAll = response?.discountDataOfAll;
      setDiscountDataOfAll(discountDataOfAll);
      if (offers?.length === 0) {
        let storeOffers = discountDataOfAll?.filter(offer => offer?.apply_type === 'store').length > 0 ?
          discountDataOfAll?.filter(
            offer => offer?.apply_type === 'store' && offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(offerRelation => offerRelation?.storeId).includes(store?.id)
          ).length > 0 ? discountDataOfAll?.filter(
            offer => offer?.apply_type === 'store' && offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(offerRelation => offerRelation?.storeId).includes(store?.id)
          ) : discountDataOfAll?.filter(offer => offer?.apply_type === 'store' && offer?.apply_on === 'all') : [];
        if (storeOffers.length === 0) {
          storeOffers = discountDataOfAll?.filter(offer => offer?.apply_type === 'business_type').length > 0 ?
            discountDataOfAll?.filter(
              offer => offer?.apply_type === 'business_type' && offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(offerRelation => offerRelation?.businessTypeId).includes(store.businessTypeId)
            ).length > 0 ? discountDataOfAll?.filter(
              offer => offer?.apply_type === 'business_type' && offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(offerRelation => offerRelation?.businessTypeId).includes(store.businessTypeId)
            ) : discountDataOfAll?.filter(offer => offer?.apply_type === 'business_type' && offer?.apply_on === 'all') : [];
        }
        if (offers?.length > 0) { offers = [] }
        if (storeOffers?.length > 0) { storeOffers = [] }
        offers = [...offers, ...storeOffers]
      }
      offers = [...new Map(offers?.map(item => [item["id"], item])).values()]
      item.offers = offers;
      return item;
    });
    if (response.ack === 1) {
      stores = [];
      stores = allStores;
      setStores(allStores)
      dispatch(changeLoader(false));
    } else {
      setStores([])
      dispatch(
        changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: response.msg,
          state: "error",
        })
      );
      dispatch(changeLoader(false));
    }
  }

  const getProductsByBusinessType = async () => {
    dispatch(changeLoader(true));
    let url = API_ROUTES.STOREFRONT.GET_PRODUCTS_BY_STORE_CATEGORY(baladiData?.BALADI_STORE_SLUG);
    url += `?limit=16&page=1`
    url += search.trim() !== '' ? `&search=${search.trim()}` : '';
    url += `&latitude=${userAddress?.latitude}&longitude=${userAddress?.longitude}`
    const response = await getRequest(url)
    if (response.ack === 1) {
      categoryOffers = response.discountDataOfAll?.filter((item) => item?.apply_type === 'category');
      setCategoryOffers(categoryOffers)
      const products = response?.products?.length > 0 ?
        response?.products.map((product) => {
          let offers = product?.discounts_and_offer_relations?.map((offer) => offer?.discounts_and_offer);
          discountDataOfAll = response.discountDataOfAll;
          setDiscountDataOfAll(discountDataOfAll)
          if (offers?.length === 0) {
            let productOffers = discountDataOfAll?.filter(offer => offer?.apply_type === 'product').length > 0 ?
              discountDataOfAll?.filter(
                offer => offer?.apply_type === 'product' && offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(offerRelation => offerRelation?.product_id).includes(product?.id)
              ).length > 0 ? discountDataOfAll?.filter(
                offer => offer?.apply_type === 'product' && offer?.apply_on === 'specific' && offer?.discounts_and_offer_relations?.map(offerRelation => offerRelation?.product_id).includes(product?.id)
              ) : discountDataOfAll?.filter(offer => offer?.apply_type === 'product' && offer?.apply_on === 'all') : [];
            offers = [...productOffers];
          }
          product.offers = offers;
          product.uoms = product?.uoms;
          return product;
        }) : [];
        storeProducts = products;
        setStoreProducts(storeProducts);
      dispatch(changeLoader(false));
    } else {
      dispatch(
        changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: response.msg,
          state: "error",
        })
      );
      dispatch(changeLoader(false));
    } 
  }

  const getListData = async () => {

    if (activeOption === 'grocery') {
      getProductsByBusinessType ()
    } else {
      getStoresFromSlug()
    }
  }

  useEffect(() => {
    const getData = setTimeout(() => {
      getListData()
    }, 500);
    return () => clearTimeout(getData);
  }, [activeOption, search]);

  return (
    <Dialog TransitionComponent={Transition} open={true} onClose={CloseDialog} className="search-category-modal">
      {/* <img
        src={modalClose}
        alt="close"
        onClick={CloseDialog}
        className="modal-close"
      /> */}
      <DialogContent>
        <div className="category-search">
          <TextField
            className=""
            placeholder="Search for Products or Vendors"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            type="search"
            InputProps={{
              endAdornment: (<InputAdornment position="start"><SearchIcon /></InputAdornment>),
            }}
          />
          <span className="cursor-pointer" onClick={CloseDialog}>Clear</span>
        </div>
        <div className="d-flex">
          <div className="left-search-category">
          <List>
            {businessTypes?.map ((businessType, index) => (
              <ListItemButton
                onClick={() => setActiveOption(businessType.slug)}
                className={activeOption === businessType.slug ? 'option-li active' : 'option-li'}
                key={`option-${index}`}
              >
                <img
                  src={axiosConstant.IMAGE_BASE_URL(businessType.image)}
                  alt={businessType.business_type_locales[0].name}
                  className="icon30"
                  onError={(e) => {
                    e.target.src = 'https://stagapi.baladiexpress.com/images/downloads/foods.png'
                    e.target.onerror = null
                  }}
                />
                <span>{businessType.business_type_locales[0].name}</span>
              </ListItemButton>
            ))}
            </List>
          </div>
          <div className="right-search-category ">
            
            {activeOption === 'grocery'?
              <>
              <h5 className="h5">Product</h5>
              <Grid container rowSpacing={2} pb={3} columnSpacing={{ xs: 1, sm: 2 }}>
              <div className="categories-right-main-wrap">
                <div className="products-block ">
                  {storeProducts.map((product, index) => (
                    product?.uoms?.length > 0 ? <ProductBox
                    key={`product-${index}`}
                    storeId={baladiData?.BALADI_STORE_Id}
                    storeSlug={baladiData?.BALADI_STORE_SLUG}
                    online={true}
                    product={product}
                    index2={index}
                    category_level={3}
                    selectedCategories={[]}
                    CloseDialog={CloseDialog}
                  /> : null
                  ))}
                  </div>
                </div>
              </Grid>
            </>:<>
              <h5 className="h5">Store</h5>
              <Grid
                container
                pb={3}
                rowSpacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 2 }}
              >
                {stores?.map((store, index) => (
                  <Grid item xs={6} key={`store-${index}`}>
                  <div className="">
                    <Link
                      to={`/store/${store?.slug && store?.slug !== "" ? store?.slug : store?.id}`}
                      onClick={CloseDialog}
                      className={`store-box no-decoration`}>
                      <div className="store-content-block">
                        <h6 className="h6"> {store.stores_locales[0].business_name}</h6>
                        <p className="p2">
                        {store?.business_address && store?.business_address !== ""
                          ? store?.business_address
                          : `${store?.zone_number}, ${store?.street_number}, ${store?.building_number}, ${store?.country}`}
                        </p>
                        <Boxed
                          display={"flex"}
                          alignItems={"center"}
                          mb={"4px"}
                          className="always-ltr"
                        >
                          <img src={distanceIcon} alt="distanceIcon" />
                          <span className="p2 p2-mins">
                            {getDistanceFromLatLonInKm(
                              userAddress?.latitude,
                              userAddress?.longitude,
                              store?.latitude,
                              store?.longitude
                            )}{" "}
                            {t("kmAway")} |
                            <span className="p2 p2-mins">30 ~ 40 {t("storefront_mins")}</span>
                          </span>
                        </Boxed>
                        {store?.avgRating ? (
                          <Boxed
                            display={"flex"}
                            alignItems={"center"}
                            className="rating-show-block"
                          >
                            <Ratings rating={getRating(store?.avgRating)} />
                          </Boxed>
                        ) : null}
                      </div>
                      <div className="store-img-block">
                        <Image
                          src={axiosConstant.IMAGE_BASE_URL(store?.business_logo)}
                          className="store-image"
                          alt="store-logo"
                        />
                        <div className="chips-row">
                          {store?.online_status === 1 ? null : (
                            <span className="closed-chips">{t("storefront_closed")}</span>
                          )}
                          {store?.offers?.filter((offerF, indexF) => indexF < 1).map((offer, index) =>
                            <span className="discount-chips"
                              key={`offersstore-${index}`}
                            >{offer.name}</span>
                          )}
                          {store?.offers?.length > 1 ? <span className="discount-chips">+{store?.offers?.length - 1} {t("More")}</span> : null}
                        </div>
                      </div>
                    </Link>
                  </div>
                </Grid>
                ))}
              </Grid>
            </>}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SearchCategoryModal;
