/**
 * @file CheckCart.js
 * @description Check Cart Dialog Box
 * @exports CheckCart - Dialog Box
 * @exports DiscardGuest - Function to discard guest cart
 * @exports DiscardUser - Function to discard user cart
 */

import { Grid, Dialog, DialogContent, Button } from '@mui/material';
import modalClose from "../assets/images/structure/close-black.svg";
import { useTranslation } from "react-i18next";

const CheckCart = ({ DiscardGuest, DiscardUser }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={true} onClose={DiscardGuest} className="main-modal theme-dialog">
      <img src={modalClose} alt="close" onClick={DiscardGuest} className='modal-close' />
      <DialogContent>
        <h2 className='h3 text-center' style={{ marginBottom: '8px' }}>{t('discard_card_title')}</h2>
        <p className='p3 text-center' style={{ marginBottom: '12px', fontSize: '14px' }}>{t('cart_discard_title')}</p>
        <Grid item xs={12} textAlign="center" display={'flex'} justifyContent={'flex-end'}>
          <Button type='button' size="medium" variant="text" color="primary" onClick={DiscardGuest}>{t('cart_discard_guest')}</Button>
          <Button type='button' size="medium" variant="text" color="error" onClick={DiscardUser}>{t('cart_discard_user')}</Button>
        </Grid>
      </DialogContent>
    </Dialog>
  )
};

export default CheckCart;