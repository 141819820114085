
import { Outlet, useNavigate } from "react-router-dom";
import { getLocalStorage, LOCAL_STORAGE_KEYS } from "../Helpers/crypto/LocalStorage";

const redirect = () => {
  const navigate = useNavigate();
    navigate("/");
    return false
}

export default redirect;