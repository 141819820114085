import * as React from 'react';
import Radio from '@mui/material/Radio';
import { useState, useEffect } from 'react';
import {
  Dialog, DialogContent, Button, TextField, Alert
} from "@mui/material";
import modalClose from "../assets/images/structure/close-black.svg";
import { useTranslation } from "react-i18next";
import { getRequest } from '../Helpers/Axios/Services';
import { API_ROUTES } from '../Helpers/Axios/ApiRoutes';
import { axiosConstant } from '../Helpers/Axios/Constants';
import RadioComponent from './ModifierComponents/RadioComponent';
import CheckboxComponent from './ModifierComponents/CheckboxComponent';
import { displayPrice, getNameBasedOnLanguage } from '../Helpers/HelperFunctions';
import { useSelector } from "react-redux";
import { currentCart, currentCartProductIds, currentCartUomIds } from '../redux/reducers/cart';
import defaultImage from "../assets/images/structure/default.jpeg";
import { currentBaladiData } from '../redux/reducers/baladiData';

const AddToCardModifier = ({
  handleClose,
  uonId,
  productName,
  productPrice,
  productImage,
  addCart,
  cartItemId,
  updateQuantity,
  unAvailableAddonsId,
  uoms,
  storeId,
  prioductId,
  handleUomChange
}) => {

  const language = localStorage.getItem('i18nextLng') || 'en';

  const { t } = useTranslation();
  let cartData = useSelector(currentCart);
  const baladiData = useSelector(currentBaladiData);
  let cartProductIds = useSelector(currentCartProductIds);
  let cartUomIds = useSelector(currentCartUomIds);
  const [isVisible, setIsVisible] = useState(false);
  let [addons, setAddons] = useState([]);
  let [selectedAddons, setSelectedAddons] = useState([]);
  let [cartTotal, setCartTotal] = useState(0);
  let [alertResponse, setAlertResponse] = useState({ type: 'error', message: '', open: false });
  let [notes, setNotes] = useState('');
  const [selectedValue, setSelectedValue] = React.useState('a');

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleClick = () => {
    setIsVisible(!isVisible);
  };

  const handleAddToCart = () => {
    let isValid = true;
    let addonIds = selectedAddons.map(obj => parseInt(obj?.addonItemId));
    let checkAddons = addons?.map(obj => {
      let addonObject = obj
      let addonObjectAddon = addonObject.addon
      let selectedIds = 0;
      addonObjectAddon.addon_items.forEach(element => {
        if (addonIds.includes(element.id)) { selectedIds += 1; }
      });
      addonObjectAddon.selectedIds = selectedIds;
      if (addonObjectAddon.minimum_item > selectedIds) {
        isValid = false;
      }
      addonObject.addon = addonObjectAddon
      return addonObject;
    });
    if (!isValid) {
      alertResponse = {
        type: 'error',
        message: t('storefront_minimumItemCheck'),
        open: true
      }
      setAlertResponse(alertResponse);
      setTimeout(() => {
        setAlertResponse({ type: '', message: '', open: false });
      }, 6000);
      return;
    }
    // check for repeatation
    let repeatation = false;
    const cartItems = cartData?.cart_items;
    if (cartItems?.length > 0) {
      repeatation = cartItems.some(obj => {
        if (obj?.uomId === uonId && obj?.addonitems?.length === selectedAddons.length) {
          const addonIds = obj?.addonitems?.map(item => item?.id);
          const selectedAddonIds = selectedAddons?.map(item => item?.addonItemId);
          return addonIds?.sort().join(',') === selectedAddonIds?.sort().join(',');
        }
        return false;
      });
    }
    if (repeatation && !cartItemId) {
      updateQuantity();
      return;
    }
    addCart(selectedAddons.map(obj => obj?.addonItemId), notes);
  };

  const getAllAddons = async () => {
    
    const response = await getRequest(API_ROUTES.ADDONS.GET_ALL_ADDONS(uonId, storeId));
    addons = response?.addon?.rows;
    setAddons(addons);
    if (addons?.length === 0 && uoms?.length === 1) {
      addCart([], notes)
      return false;
    }
    if (cartItemId) {
      let currentUom = cartData?.cart_items?.find(obj => obj?.uomId == uonId && obj?.id == cartItemId);
      if (currentUom) {
        if (currentUom?.specialRequest !== '') {
          setIsVisible(true);
          setNotes(currentUom?.specialRequest);
        }
        selectedAddons = currentUom.addonitems
          .filter((obj1) => !unAvailableAddonsId.includes(obj1?.id))
          .map(obj => {
            return {
              addonId: obj?.addonId,
              addonItemId: obj?.id
            }
          })
        setSelectedAddons(selectedAddons)
      }
    }
  };

  const handleRemoveRadioValue = (value, addonId) => {
    let temp = [...selectedAddons];
    let index = temp.findIndex(obj => obj?.addonId === addonId);
    if (index > -1) {
      temp.splice(index, 1);
    }
    setSelectedAddons(temp);
  };

  const handleRadioChange = (value, addonId) => {
    let temp = [...selectedAddons];
    let index = temp.findIndex(obj => obj?.addonId === addonId);
    if (index > -1) {
      temp[index].addonItemId = value;
    } else {
      temp.push({ addonId, addonItemId: value });
    }
    setSelectedAddons(temp);
  };

  const handleCheckboxChange = (value, addonId, addonItemId) => {
    let temp = [...selectedAddons];
    let index = temp.findIndex(obj => obj?.addonId === addonId && obj?.addonItemId === addonItemId);
    if (index > -1) {
      temp.splice(index, 1);
    } else {
      temp.push({ addonId, addonItemId });
    }
    setSelectedAddons(temp);
  }

  const updateTotalPrice = () => {
    let total = parseFloat(productPrice);
    if (selectedAddons.length > 0) {
      selectedAddons?.map(obj => {
        let addon = addons?.find(addon => addon?.addonId == obj?.addonId);
        let addonItem = addon?.addon?.addon_items?.find(addonItem => addonItem?.id == obj?.addonItemId);
        total += parseFloat(addonItem?.price);
      });
    }
    cartTotal = total;
    setCartTotal(cartTotal)
  };

  useEffect(() => { updateTotalPrice(); }, [selectedAddons]);

  useEffect(() => {
    getAllAddons()
  }, [isVisible, uonId]);

  return (
    <Dialog open={addons?.length > 0 || uoms?.length > 1} onClose={handleClose} className="main-modal maxwidth-500" >
      <DialogContent>
        <div className="top-header-modal">
          <img src={modalClose} alt="close" onClick={handleClose} className="modal-close" />
          <img
            src={axiosConstant.IMAGE_BASE_URL(productImage)}
            onError={
              (e) => {
                e.target.onerror = null;
                e.target.src = defaultImage
              }
            }
            alt="product" className="img48" loading='lazy' />
          <div className="relative">
            <p className="p2">{productName}</p>
            <h4 className="h4">{t('storefront_QAR')} {productPrice}</h4>
          </div>
        </div>
        <div className="conent-scroll-modal">
          <div className='uom-bg-box'>

            {uoms?.map((uom, index) =>
              <div className={`uom-selection-box ${uonId === uom.id ? 'active' : ''}`} key={`uon-${index}`}>
                <span className='p2' style={{ display: 'flex', alignItems: 'center' }}>
                  <span>{language === 'en' ?
                    uom?.uomName :
                    uom?.uomNameEr} </span>

                  {/* <span>{" "} - {getNameBasedOnLanguage(uom?.unit?.unit_locales)} </span> */}
                </span>
                <span className='p2' style={{ display: 'flex', alignItems: 'center' }}>
                  +QAR {
                    uom?.salePrice !== 0
                      ? displayPrice(uom?.salePrice)
                      : displayPrice(uom?.price)
                  }
                  <Radio
                    checked={uonId === uom.id}
                    onChange={() => handleUomChange(uom.id)}
                    value="a"
                    name="radio-buttons"
                    inputProps={{ 'aria-label': 'A' }}
                  />
                </span>
              </div>)}

          </div>
          {addons && addons?.map((obj, index) => obj?.addon?.type === 'single' ?
            <RadioComponent
              key={`addon-${index}`}
              addon={obj.addon}
              selectedAddons={selectedAddons}
              changeValue={(value, addonId) => handleRadioChange(value, addonId)}
              removeValue={(value, addonId) => handleRemoveRadioValue(value, addonId)}
            /> : <CheckboxComponent
              key={`addon-${index}`}
              addon={obj.addon}
              selectedAddons={selectedAddons}
              changeValue={(value, addonId, addonItemId) => handleCheckboxChange(value, addonId, addonItemId)}
              showErrorMessage={(type, message) => {
                alertResponse = { type: type, message: message, open: true }
                setAlertResponse(alertResponse);
                setTimeout(() => {
                  setAlertResponse({ type: '', message: '', open: false });
                }, 6000);
              }}
            />
          )}
        </div>
        <div className="footer-modal">
          {alertResponse?.open ?
            <Alert
              severity={alertResponse?.type}
              onClose={() => setAlertResponse({ type: '', message: '', open: false })}
            >{alertResponse?.message}</Alert> : null}
          {cartProductIds?.includes(prioductId) && !cartUomIds?.includes(uonId) && storeId === baladiData.BALADI_STORE_Id ?
            <Button size="large" fullWidth variant="contained" disabled={true}>
              Product already in cart
            </Button> :
            <Button size="large" fullWidth variant="contained" onClick={handleAddToCart}>
              {cartItemId ?
                <>{t("storefront_UpdateCart")} ({t('storefront_QAR')} {displayPrice(cartTotal)} )</> :
                <>{t("storefront_addToCart")} ({t('storefront_QAR')} {displayPrice(cartTotal)} )</>}
            </Button>}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddToCardModifier;
