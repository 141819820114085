import "./OrderDetails.scss"
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from 'react';
import { Boxed } from "../../Styles-Elements/Box"
import { Grid, Divider } from '@mui/material';
import Sidebar from '../layout/sidebar/Sidebar'
import downloadBlack from "../../assets/images/structure/download-black.svg";
import scheduleIcon from "../../assets/images/structure/schedule.svg";
import locationBlack from "../../assets/images/structure/location-black.svg"
import userBlack from "../../assets/images/structure/user-black.svg"
import walletIcon from "../../assets/images/structure/wallet-icon.svg";
import visaIcon from "../../assets/images/structure/visa.webp";
import cashIcon from "../../assets/images/structure/cash.svg";
import callIcon from "../../assets/images/structure/call.svg";
import starRedBorder from "../../assets/images/structure/star-red-border.svg";
import youSavedIcon from "../../assets/images/structure/you-saved-icon.webp";
import RatingReview from "../../DialogBox/Review/RatingReview";
import { useTranslation } from "react-i18next";
import { getRequest } from '../../Helpers/Axios/Services';
import { API_ROUTES } from '../../Helpers/Axios/ApiRoutes';
import { changeSnackbar, currentSnackbar } from "../../redux/reducers/snackbar";
import { changeLoader } from "../../redux/reducers/loader";
import { axiosConstant } from '../../Helpers/Axios/Constants';
import OrderLogs from './OrderLogs';
import NeedHelp from '../../DialogBox/NeedHelp';
import { getLocalStorage, LOCAL_STORAGE_KEYS } from '../../Helpers/crypto/LocalStorage';
import {
  displayPrice, getDateWithMonthNameAndSpace,
  convertTimeTo12Hour, getLastFourDigitsOfCardNumber
} from '../../Helpers/HelperFunctions';
import Ratings from '../../global-modules/Rating/Ratings';
import jwt_decode from "jwt-decode";
import OrderItem from './OrderItem';
import OrderBox from './OrderBox';
// import socket from "../../socket";
import Image from "../../global-modules/Image/Image";
import { ampli } from "../../ampli";
import {currentBaladiData} from "../../redux/reducers/baladiData";

const OrderDetailsProgress = () => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { orderId } = useParams();
  const dispatch = useDispatch();
  const snackbar = useSelector(currentSnackbar);
  const baladiData = useSelector(currentBaladiData);
  let [orderDetails, setOrderDetails] = useState(null);
  let [orderLogs, setOrderLogs] = useState(null);
  let [openNeedHelp, setOpenNeedHelp] = useState(false);
  let [openRatingReview, setOpenRatingReview] = useState(false);
  let [openDriverRatingReview, setOpenDriverRatingReview] = useState(false);
  let [storeRatingReview, setStoreRatingReview] = useState(null);
  let [deliveryRatingReview, setDeliveryRatingReview] = useState(null);
  let [walletTransaction, setWalletTransaction] = useState(null);
  let [otherTransaction, setOtherTransaction] = useState(null);
  let [refundTransaction, setRefundTransaction] = useState(null);
  let [customerDetails, setCustomerDetails] = useState(null);
  const [decodedUser, setDecodedUser] = useState(null);


  const getWalletTransaction = (transaction) => {
    if (transaction === null) return;
    if (typeof transaction !== "array" && transaction.length > 0) {
      walletTransaction = transaction.find(tran => tran?.method === 'wallet' && tran?.type === 'purchase')
      setWalletTransaction(walletTransaction)

      otherTransaction = transaction.find(tran => tran?.method !== 'wallet' && tran?.type === 'purchase')
      setOtherTransaction(otherTransaction);

      refundTransaction = transaction.find(tran => tran?.method === 'wallet' && tran?.type === 'refund')
      setRefundTransaction(refundTransaction)
    } else {
      walletTransaction = (orderDetails?.transaction.method === 'wallet' && tran?.type === 'purchase') ?
        orderDetails?.transaction : null;
      setWalletTransaction(walletTransaction)
    }
  }

  const getOrderDetails = async () => {
    dispatch(changeLoader(true));
    const { ack, order, msg } = await getRequest(API_ROUTES.ORDER.GET_DETAILS(orderId));
    if (ack === 1) {
      orderDetails = order;
      const decoded = jwt_decode(getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN));
      if (orderDetails?.userId === decoded?.id) {
        setOrderDetails(orderDetails);
        getWalletTransaction(orderDetails?.transaction);
        orderLogs = orderDetails?.order_status_log.filter(log => log?.role === undefined);
        setOrderLogs(orderLogs)
        if (orderDetails?.review_ratings.length > 0) {
          storeRatingReview = orderDetails?.review_ratings.find(rev => rev.storeId === orderDetails?.store?.id)
          setStoreRatingReview(storeRatingReview)
          deliveryRatingReview = orderDetails?.review_ratings.find(rev => rev.storeId === 0)
          setDeliveryRatingReview(deliveryRatingReview)
        }
        customerDetails = orderDetails?.addressJson ? JSON.parse(orderDetails?.addressJson) : orderDetails?.address;
        setCustomerDetails(customerDetails)
        dispatch(changeLoader(false));
      } else {
        dispatch(changeLoader(false));
        navigate("/order-history");
      }
      //ampli====
      let userID = '';
      if (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) {
        const token = getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
        let decoded = jwt_decode(token);
        userID = decoded?.id;
      }
      ampli.track({
        event_type: 'OrderInfoPageViewed',
        event_properties: {
          category: 'Docs',
          name: 'SDK Library',
          description: 'SDK Library Description',
          user_id: userID ? userID : 0,
          order_id: order.order_id ? order.order_id : 0,
          total_amount: order.total_amount ? order.total_amount : 0,
        },
      });

    } else {
      dispatch(
        changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: msg,
          state: "error",
        })
      );
      dispatch(changeLoader(false));
      navigate("/order-history");
    }
  }
  const handleCancelOrder = async () => {
    dispatch(changeLoader(true));
    const { ack, msg } = await getRequest(API_ROUTES.ORDER.CANCEL_ORDER(orderId));
    if (ack === 1) {
      dispatch(changeLoader(false));
      getOrderDetails();
      //ampli======
      let userID = '';
      if (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) {
        const token = getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
        let decoded = jwt_decode(token);
        userID = decoded?.id;
      }
      ampli.track({
        event_type: 'OrderCancelled',
        event_properties: {
          category: 'Docs',
          name: 'SDK Library',
          description: 'SDK Library Description',
          user_id: userID,
          order_id: orderId,
          total_amount: orderDetails.total_amount ? orderDetails.total_amount : 0,
        },
      })
    } else {
      dispatch(
        changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: msg,
          state: "error",
        })
      );
      dispatch(changeLoader(false));
    }
  }

  const downloadInvoice = async () => {
    const response = await getRequest(API_ROUTES.ORDER.GET_ORDER_INVOICE(orderId));
    window.open(axiosConstant.IMAGE_BASE_URL(response?.invoice), '_blank')
  }

  useEffect(() => {
    getOrderDetails();
    if (getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN)) {
      const decoded = jwt_decode(getLocalStorage(LOCAL_STORAGE_KEYS.AUTH_TOKEN));
      setDecodedUser(decoded);
    }
  }, [orderId])

  return (
    <div>
      <div className="main-wrapper full-bg">
        <Sidebar />
        <div className="main-mid-wrapper">
          <div className="order-detail-wrapper">
            <div className="card-white top-card">
              <div className="order-detail-top-img">
                <OrderBox status={orderDetails?.status} tracking_link={orderDetails?.delivery_tracing_link} />
              </div>
              <div className="order-order-tracking-wrap">
                <h4 className="h4">
                  {t("order_title")} #{orderDetails?.order_id}
                </h4>
                {orderDetails?.deliver !== "now" ? (
                  <p className="p3 flex">
                    <img src={scheduleIcon} alt="scheduleIcon" />
                    {t("order_DeliverBy")}:{" "}
                    <span>
                      {getDateWithMonthNameAndSpace(orderDetails?.delivery_date)} -{" "}
                      {convertTimeTo12Hour(`${orderDetails?.delivery_time}`)}
                    </span>
                  </p>
                ) : null}
                {orderLogs && orderLogs.length > 0 ? (
                  <OrderLogs orderLogs={orderLogs} status={orderDetails?.status} />
                ) : null}
              </div>
            </div>
            <Grid container columnSpacing={"30px"}>
              <Grid item xs={12} sm={12} md={8}>
                <div className="card-white">
                  <div className="order-product-detail-wrap">
                    <p className="p3">{t("order_productDetails")}</p>
                    {orderDetails?.orders_details.map((order_item, index) => (
                      <OrderItem order_item={order_item} index={index} key={`OrderItem-${index}`} />
                    ))}
                  </div>
                </div>

                {orderDetails?.specialRequest !== '' ? <div className="card-white">
                  <div className="order-product-detail-wrap">
                    <p className="p3">{t("order_productSpecialRequest")}</p>
                    <p className="p6">{orderDetails?.specialRequest}</p>
                  </div>
                </div> : null}

                <div className="card-white">
                  <div className="order-shipping-detail-wrap">
                    <p className="p3">{t("order_customerDetails")}</p>
                    <div className="shipping-blocks">
                      <Boxed display={"flex"} alignItems={"center"} className="history-left-block">
                        <div className="history-logo-block">
                          <img src={userBlack} className="history-logo-img" alt="" />
                        </div>
                        <span className="history-content-block">
                          <h6 className="h6">
                            <span className="max-w-200-truncate">{customerDetails?.full_name}</span> |{" "}
                            {customerDetails?.country_code ? `+${customerDetails?.country_code} - ` : ''}
                            {customerDetails?.contact_number}
                          </h6>
                          <Boxed display={"flex"} alignItems={"center"} mb={"8px"}>
                            <img src={locationBlack} alt="" className="icon20" />
                            <p className="p3">
                              {customerDetails?.business_address &&
                                customerDetails?.business_address !== ""
                                ? `${customerDetails?.apartment_number} ${customerDetails?.building_number} ${customerDetails?.street_number} ${customerDetails?.zone_number} ${customerDetails?.business_address}`
                                : `${customerDetails?.street_number}, ${customerDetails?.building_number},${customerDetails?.zone_number} ${customerDetails?.country}`}
                            </p>
                          </Boxed>
                        </span>
                      </Boxed>
                    </div>
                  </div>
                </div>
                <div className="card-white">
                  <div className="order-shipping-detail-wrap">
                    <p className="p3">{t("order_StoreDetails")}</p>
                    <div className="shipping-blocks">
                      <Boxed display={"flex"} alignItems={"center"} className="history-left-block">
                        <div className="history-logo-block">
                          <Image
                            src={axiosConstant.IMAGE_BASE_URL(orderDetails?.store?.business_logo)}
                            className="history-logo-img"
                            alt=""
                          />
                        </div>
                        <span className="history-content-block">
                          <h6 className="h6">
                            {orderDetails?.store?.stores_locales[0].business_name}
                          </h6>
                          <Boxed display={"flex"} alignItems={"center"} mb={"8px"}>
                            <img src={locationBlack} alt="" className="icon20" />
                            {orderDetails?.storeId !== baladiData.BALADI_STORE_Id ? <p className="p3">
                              {`${orderDetails?.store?.street_number}, ${orderDetails?.store?.building_number} ${orderDetails?.store?.business_address}, ${orderDetails?.store?.country}`}
                            </p> : <p className="p3">{t('storefront_Open24X7')}</p>}

                          </Boxed>
                          {orderDetails?.store?.market_place == '1' ? <Boxed display={"flex"} alignItems={"center"} mb={"8px"}>
                            <img src={callIcon} alt="" className="icon20" />
                            <p className="p3">
                              +{orderDetails?.store?.country_code} {orderDetails?.store?.contact_number}
                            </p>
                          </Boxed> : null}

                        </span>

                        {orderDetails?.status === "completed" && !storeRatingReview ? (
                          <Boxed
                            display={"flex"}
                            alignItems={"center"}
                            className="rate-review-box"
                            onClick={() => setOpenRatingReview(true)}
                          >
                            <img src={starRedBorder} alt="" />
                            <p className="p2">{t("order_Rate_Review")}</p>
                          </Boxed>
                        ) : null}
                      </Boxed>
                      {storeRatingReview ? (
                        <>
                          <Divider />
                          <Ratings rating={storeRatingReview?.rating} />
                          <p className="p2">{storeRatingReview.review}</p>
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>

                {orderDetails?.driver ? (
                  <div className="card-white">
                    <div className="order-shipping-detail-wrap">
                      <p className="p3">{t("order_DriverDetails")}</p>
                      <div className="shipping-blocks">
                        <Boxed
                          display={"flex"}
                          alignItems={"center"}
                          className="history-left-block"
                        >
                          <div className="history-logo-block">
                            <img src={userBlack} className="history-logo-img" alt="" />
                          </div>
                          <span className="history-content-block">
                            <h6 className="h6">{orderDetails?.driver?.first_name} {orderDetails?.driver?.last_name}</h6>
                            <Boxed display={"flex"} alignItems={"center"} mb={"8px"}>
                              {/* <img src={userBlack} alt="" className="icon20" /> */}
                              <p className="p3">
                                +{`${axiosConstant.COUNTRY_CODE}-${orderDetails?.driver?.phone}`}
                              </p>
                            </Boxed>
                          </span>
                          {orderDetails?.status === "completed" && !deliveryRatingReview ? (
                            <Boxed
                              display={"flex"}
                              alignItems={"center"}
                              className="rate-review-box"
                              onClick={() => setOpenDriverRatingReview(true)}
                            >
                              <img src={starRedBorder} alt="" />
                              <p className="p2">{t("order_Rate_Review")}</p>
                            </Boxed>
                          ) : null}
                        </Boxed>
                        {deliveryRatingReview ? (
                          <>
                            <Divider />
                            <Ratings rating={deliveryRatingReview?.rating} />
                            <p className="p2">{deliveryRatingReview.review}</p>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                ) : null}
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                <div className="card-white">
                  <div className="order-payment-details-wrap">
                    <p className="p3">{t("paymentDetails")}</p>
                    <div className="payment-lists">
                      <span className="p2">{t("orderTotal")}</span>
                      <span className="p2-bold latter-spacing1">
                        {t("QAR")} {displayPrice(orderDetails?.sub_total)}
                      </span>
                    </div>
                    {orderDetails?.tax > 0 ? <div className="payment-lists">
                      <span className="p2">{t("tax")}</span>
                      <span className="p2-bold latter-spacing1">
                        {t("QAR")} {displayPrice(orderDetails?.tax)}
                      </span>
                    </div> : null}
                    {orderDetails?.delivery_charges > 0 ? <div className="payment-lists">
                      <span className="p2">{t("deliveryCharges")}</span>
                      <span className="p2-bold">
                        {t("QAR")} {displayPrice(orderDetails?.delivery_charges)}
                      </span>
                    </div> : null}

                    {orderDetails?.discount && orderDetails?.discount > 0 ? <div className="payment-lists">
                      <span className="p2">{t("order_CouponDiscount")}</span>
                      <span className="p2-bold">
                        - {t("QAR")} {displayPrice(orderDetails?.discount)}
                      </span>
                    </div> : null}
                    {orderDetails?.offer_amount && orderDetails?.offer_amount > 0 ? <div className="payment-lists">
                      <span className="p2">{t("cart_DiscountOffer")}</span>
                      <span className="p2-bold">
                        - {t("QAR")} {displayPrice(orderDetails?.offer_amount)}
                      </span>
                    </div> : null}





                    {/* {orderDetails?.addons_total > 0?<div className="payment-lists">
                      <span className="p2">{t("addons_total")}</span>
                      <span className="p2-bold">
                        {t("QAR")} {displayPrice(orderDetails?.addons_total)}
                      </span>
                    </div>: null} */}

                    <Divider />
                    <div className="payment-lists mb-16">
                      <span className="p2">{t("totalAmount")}</span>
                      <span className="p2 latter-spacing1">
                        {t("QAR")} {displayPrice(orderDetails?.total_amount)}
                      </span>
                    </div>
                    {walletTransaction ? (
                      <div className="payment-lists mb-16">
                        <span className="p2">{t("cart_wallet")}</span>
                        <span className="p2-bold">
                          -{t("QAR")} {displayPrice(walletTransaction?.amount)}
                        </span>
                      </div>
                    ) : null}
                    <div className="payment-lists">
                      <span className="p2 bold">{t("cart_NetPaid")}</span>
                      <span className="p2-bold bold">
                        {t("QAR")}{" "}
                        {walletTransaction?.amount
                          ? displayPrice(orderDetails?.total_amount - walletTransaction?.amount)
                          : displayPrice(orderDetails?.total_amount)}
                      </span>
                    </div>
                    {orderDetails?.offer_amount ? <div className="you-saved-box">
                      <img src={youSavedIcon} className="you-saved-icon" alt=".." />
                      <p className="p2">{t("order_YouSavedQAR")} {displayPrice(orderDetails?.offer_amount)}</p>
                    </div> : null}
                    {orderDetails?.payment_method === "cash_on_delivery" ? (
                      <div className="visa-box">
                        <img src={cashIcon} className="pay-icon" alt=".." />
                        <div className="visa-box-content">
                          <p className="p3" style={{ marginBottom: 0 }}>
                            {t("order_Pay_on_Delivery")}{" "}
                          </p>
                        </div>
                      </div>
                    ) : null}
                    {orderDetails?.payment_method !== "cash_on_delivery" ? (
                      orderDetails?.status !== "pending" ? (
                        otherTransaction ? (
                          <div className="visa-box">
                            <img src={visaIcon} className="visa-icon" alt=".." />
                            <div className="visa-box-content">
                              <p className="p3">
                                {otherTransaction?.status !== "failed"
                                  ? t("order_Paid_by_Card_ending_in")
                                  : t("order_transaction_failed")}{" "}
                                xxxx {getLastFourDigitsOfCardNumber(otherTransaction?.card_number)}
                              </p>
                              <p className="p3">
                                <span>{t("order_TransactionID")}</span> #
                                {otherTransaction?.transaction_id}
                              </p>
                            </div>
                          </div>
                        ) : null
                      ) : null
                    ) : null}
                  </div>
                </div>

                {orderDetails?.status === "cancelled" && orderDetails?.reason_of_reject !== "" ?
                  <div className="card-white">
                    <div className="order-payment-details-wrap">
                      <p className="p3">{t("order_CancelDetails")}</p>
                      <div className="visa-box">
                        {/* <img src={walletIcon} className="wallet-icon" alt=".." /> */}
                        <div className="visa-box-content">
                          Reason: {orderDetails?.reason_of_reject}
                        </div>
                      </div>
                    </div>
                  </div> : null}

                {refundTransaction ? (
                  <div className="card-white">
                    <div className="order-payment-details-wrap">
                      <p className="p3">{t("order_RefundDetails")}</p>
                      <div className="visa-box">
                        <img src={walletIcon} className="wallet-icon" alt=".." />
                        <div className="visa-box-content">
                          <p className="p3">
                            {t("order_Refund_of")}{" "}
                            <span>
                              {t("order_QAR")} {displayPrice(refundTransaction?.amount)}
                            </span>{" "}
                            {t("order_sent_to_wallet")}
                          </p>
                          <p className="p3">
                            <span>{t("order_TransactionID")}</span> #
                            {refundTransaction?.transaction_id}
                          </p>
                        </div>
                      </div>
                      {orderDetails?.reason_of_reject !== '' ? <p className="p">
                        <strong>{t('order_note')}</strong>: {orderDetails?.reason_of_reject}
                      </p> : null}
                    </div>
                  </div>
                ) : null}

                {orderDetails?.status === "pending" ||
                  orderDetails?.status === "placed" ||
                  orderDetails?.status === "processing" ||
                  orderDetails?.status === "readytopick" ||
                  orderDetails?.status === "shipped" ? (
                  <Boxed
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    className="rate-review-box"
                  >
                    <p className="p2" onClick={() => setOpenNeedHelp(true)}>
                      {t("order_need_help")}
                    </p>
                  </Boxed>
                ) : (
                  <Boxed
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    className="download-box"
                    onClick={downloadInvoice}
                  >
                    <img src={downloadBlack} alt="downloadBlack" />
                    <p className="p2">{t("order_downloadInvoice")}</p>
                  </Boxed>
                )}
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      {openRatingReview ? (
        <RatingReview
          type={"store"}
          orderId={orderDetails?.id}
          storeDetails={orderDetails?.store}
          handleCloseRatingReview={() => setOpenRatingReview(false)}
          successReview={() => {
            setOpenRatingReview(false);
            getOrderDetails();
          }}
        />
      ) : null}
      {openDriverRatingReview ? (
        <RatingReview
          type={"driver"}
          orderId={orderDetails?.id}
          driverDetails={orderDetails?.driver}
          handleCloseRatingReview={() => setOpenDriverRatingReview(false)}
          successReview={() => {
            setOpenDriverRatingReview(false);
            getOrderDetails();
          }}
        />
      ) : null}
      {openNeedHelp ? (
        <NeedHelp
          status={orderDetails?.status}
          closeDialog={() => setOpenNeedHelp(false)}
          cancelOrder={() => {
            setOpenNeedHelp(false);
            handleCancelOrder();
          }}
        />
      ) : null}
    </div>
  );
}

export default OrderDetailsProgress;
