/**
 * @file ChangePassword.js
 * @description Change Password Dialog Box
 * @exports ChangePassword - Dialog Box
 * @exports handleClose - Function to close the dialog box
 * @exports successChange - Function to close the dialog box and show success message
 */

import { useState } from 'react';
import {
  Grid, Dialog, DialogContent, Button, InputLabel,
  InputAdornment, FormControl, IconButton, OutlinedInput
} from '@mui/material';
import modalClose from "../assets/images/structure/close-black.svg"
import {Visibility, VisibilityOff} from '@mui/icons-material';
import { postRequest } from '../Helpers/Axios/Services'
import { API_ROUTES } from '../Helpers/Axios/ApiRoutes'
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../redux/reducers/loader";
import { changeSnackbar, currentSnackbar } from "../redux/reducers/snackbar";
import { useTranslation } from "react-i18next";
import { validatePassword } from '../Helpers/HelperFunctions'

// Importing localised strings

const ChangePassword = ({ handleClose, successChange }) => {

  const { t } = useTranslation();
  let [oldPassword, setOldPassword] = useState('');
  let [oldPasswordError, setOldPasswordError] = useState('');
  let [oldShowPassword, setOldShowPassword] = useState(true);
  let [password, setPassword] = useState('');
  let [passwordError, setPasswordError] = useState('');
  let [showPassword, setShowPassword] = useState(true);
  let [passwordConfirm, setPasswordConfirm] = useState('');
  let [passwordConfirmError, setPasswordConfirmError] = useState('');
  let [showPasswordConfirm, setShowPasswordConfirm] = useState(true);
  const dispatch = useDispatch();
  const snackbar = useSelector(currentSnackbar);

  const handleSubmit = async (e) => {
    oldPasswordError = ''; setOldPasswordError(oldPasswordError);
    passwordError = ''; setPasswordError(passwordError);
    passwordConfirmError = ''; setPasswordConfirmError(passwordConfirmError);
    e.preventDefault();
    dispatch(changeLoader(true));
    let isValid = true;
    if (oldPassword === "") {
      setOldPasswordError(t('cperrorOldPassword')); isValid = false;
    } else if (oldPassword.length < 8) {
      setOldPasswordError(t('cperrorPasswordLength'));
      isValid = false;
    }
    if (password === "") {
      setPasswordError(t('cperrorPassword')); isValid = false;
    } else if (password.length < 8) {
      setPasswordError(t('cperrorPasswordLength'));
      isValid = false;
    } else if (!validatePassword(password)) {
      setPasswordError(t('cpPasswordShould'));
      isValid = false;
    }
    if (passwordConfirm === '') {
      setPasswordConfirmError(t('cprequirePasswordConfirm'));
      isValid = false;
    } else if (passwordConfirm !== password) {
      setPasswordConfirmError(t('cperrorPasswordConfirm'));
      isValid = false;
    }
    if (isValid) {
      const data = {
        old_password: oldPassword,
        password: password
      }
      const response = await postRequest(API_ROUTES.AUTHENTICATION.changePassword, data);
      if (response.ack === 1) {
        dispatch(changeSnackbar({
          ...snackbar,
          isOpen: true,
          message: response.msg,
          state: "success",
        }));
        successChange();
      } else {
        if (response?.msg && response?.msg !== '') {
          dispatch(changeSnackbar({
            ...snackbar,
            isOpen: true,
            message: response?.msg,
            state: "error",
          }));
        } else if (response?.errMsg) {
          response?.errMsg.forEach(element => {
            for (const [key, value] of Object.entries(element)) {
              if (key === 'old_password') { setOldPasswordError(value); }
              if (key === 'password') { setPasswordError(value); }
            }
          });
        }
      }
      dispatch(changeLoader(false));
    } else {
      dispatch(changeLoader(false));
    }
  }

  return (
    <>
      <Dialog open={true} onClose={handleClose} className="main-modal">
        <img src={modalClose} alt="close" onClick={handleClose} className='modal-close' />
        <DialogContent>
          <h2 className='h3 text-center'>{t('cpupdatePassword')}</h2>
          <p className='p3 text-center'>{t('cpmustIncludeAtLeast8CharactersShouldNotHaveAnyPartOfYourName')}</p>
          <form onSubmit={handleSubmit} action="#" method='post'>
            <Grid container spacing={2} >
              <Grid item xs={12}>
                <FormControl variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">{t('cpoldpassword') + '*'}</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={oldShowPassword ? 'password' : 'text'}
                    value={oldPassword}
                    onChange={(e) => {
                      setOldPasswordError('');
                      setOldPassword(e.target.value)
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setOldShowPassword(!oldShowPassword)}
                          edge="end"
                        >
                          {oldShowPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label={t('cpoldpassword') + '*'}
                  />
                  <span className="errorspan">{oldPasswordError}</span>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">{t('cppassword') + '*'}</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? 'password' : 'text'}
                    value={password}
                    onChange={(e) => { setPassword(e.target.value) }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => {
                            setPasswordError('');
                            setShowPassword(!showPassword)
                          }}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label={t('cppassword') + '*'}
                  />
                  <span className="errorspan">{passwordError}</span>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">{t('cpconfirmPassword') + '*'}</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPasswordConfirm ? 'password' : 'text'}
                    onChange={(e) => { setPasswordConfirm(e.target.value) }}
                    value={passwordConfirm}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => {
                            setPasswordConfirmError('');
                            setShowPasswordConfirm(!showPasswordConfirm)
                          }}
                          edge="end"
                        >
                          {showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label={t('cpconfirmPassword') + '*'}
                  />
                  <span className="errorspan">{passwordConfirmError}</span>
                </FormControl>
              </Grid>
              <Grid item xs={12} textAlign="center">
                <Button type='submit' variant="contained" size="large" sx={{ width: 1, marginTop: '16px' }}>{t('cpupdate')}</Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  )
};

export default ChangePassword;